<app-main-page-title [title]="'Trips'"></app-main-page-title>
<mat-card>
  <div>
    <div class="tab-emulation-ripple-container"
         matRipple [matRippleCentered]="true" (click)="currentPage = 0"
         [id]="'COMING_UP_TAB' | gtmTag"
         [ngClass]="{'selected-option': currentPage === 0, 'selected': currentPage === 0}">
      Coming Up
    </div>
    <div class="tab-emulation-ripple-container"
         matRipple [matRippleCentered]="true" (click)="currentPage = 1"  [id]="'LOGBOOK_TAB' | gtmTag"
         [ngClass]="{'selected-option': currentPage === 1, 'selected': currentPage === 1}">
      Logbook
    </div>
  </div>
  <mat-tab-group (selectedIndexChange)="selectPage($event)" [(selectedIndex)]="currentPage" class="runs-container custom-tab">
    <mat-tab label="" ></mat-tab>
    <mat-tab label=""></mat-tab>
  </mat-tab-group>
  <app-user-runs-coming-up *ngIf="currentPage == 0"
                           [user]="userPremInformation"
                           [isPremium]="isPremium"></app-user-runs-coming-up>
  <app-user-runs-logbook *ngIf="currentPage == 1"
                         [isPremium]="isPremium"></app-user-runs-logbook>
</mat-card>

