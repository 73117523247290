import { NgModule } from '@angular/core';
import { DialogMessageComponent } from './dialog-message.component';
import { MaterialModule } from '../../external-modules/material.module';
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    DialogMessageComponent
  ],
    imports: [
        MaterialModule,
        CommonModule
    ],
  providers: [],
  bootstrap: [DialogMessageComponent]
})
export class DialogMessageModule { }

