import { NgModule } from '@angular/core';
import { AppStorageService } from './services/app-storage.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import ApiService from './services/api.service';
import { MessageHandlerService } from './services/message-handler.service';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { KmDistancePipe } from './pipes/km-distance.pipe';
import { DirectionsMapDirective } from './directives/directions-map.directive';
import { AppService } from './services/app.service';
import { CommonService } from './services/common.service';
import { PackIdPipe } from './pipes/pack-id.pipe';
import { DisableControlDirective } from './directives/form-disable.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { RegexReplaceDirective } from './directives/regex-replace.directive';
import { TitleCaseDirective } from './directives/title-case.directive';
import { GtmTagPipe } from './pipes/gmt-tag.map';

@NgModule({
    declarations: [
        KmDistancePipe,
        DirectionsMapDirective,
        DisableControlDirective,
        NumberOnlyDirective,
        RegexReplaceDirective,
        TitleCaseDirective,
        PackIdPipe,
        GtmTagPipe
    ],
    imports: [],
    exports: [
        KmDistancePipe,
        DirectionsMapDirective,
        PackIdPipe,
        GtmTagPipe,
        DisableControlDirective,
        NumberOnlyDirective,
        RegexReplaceDirective,
        TitleCaseDirective
    ],
    providers: [
        [
            AppStorageService,
            ApiService,
            MessageHandlerService,
            AppService,
            AuthService,
            CommonService,
            { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ],
    ]
})
export class CoreModule { }
