import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { RsvpStatusEnum } from 'src/app/enums/rsvp-status.enum';
import {TableTypeEnum} from '../../../../enums/table-type.enum';

@Component({
  selector: 'app-user-rsvp-list',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})
export class RsvpComponent implements OnInit {

  public loading = false;
  public countOfFriends = 0;
  public status: number;
  public RsvpStatusEnum = RsvpStatusEnum;
  public currentItem;
  public responsesUsers;
  public type;
  public RunTypeEnum = TableTypeEnum;

  constructor(public dialogRef: MatDialogRef<RsvpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.currentItem = this.data.item;
    this.type = this.data.type;
    this.rsvpPreparations();
    if (this.type === TableTypeEnum.LOGBOOK) {
      this.checkTab(1);
    }
  }

  private rsvpPreparations() {
    this.countOfFriends = this.currentItem.guestsCount || 0;
    this.status = this.currentItem.response || 0;
  }

  public checkTab(index) {
    switch (index) {
      case 1: {
        this.findRSVP();
        break;
      }
    }
  }

  private findRSVP() {
    if (this.data.rsvpList && !this.responsesUsers) {
      this.loading = true;
      this.data.rsvpList(this.data.item.runId, (users) => {
        this.loading = false;
        this.responsesUsers = users || [];
      }, () => {
        this.loading = false;
      });
    }
  }

  public selectStatus(status) {
    this.status = status;
  }

  public minusFriend() {
    if (this.countOfFriends > 0) {
      this.countOfFriends--;
    }
  }

  public plusFriend() {
    this.countOfFriends++;
  }

  public saveRsvp() {
    if (!this.status || this.loading) {
      return;
    } else {
      if (this.data.saveRsvp) {
        this.loading = true;
        const data = {
          runId: this.currentItem.runId,
          guestsCount: this.countOfFriends,
          rsvpResponseId: this.status,
          userToRunId: this.currentItem.userToRunId
        };
        this.data.saveRsvp(data, () => {
          this.loading = false;
          this.dialogRef.close();
        }, () => {
          this.loading = false;
        });
      }
    }
  }

  public displayAnswer(item) {
    switch (+item.response) {
      case RsvpStatusEnum.YES: {
        return 'Yes';
      }
      case RsvpStatusEnum.NO: {
        return 'No';
      }
      case RsvpStatusEnum.MAYBE: {
        return 'Maybe';
      }
      default: {
        return '?';
      }
    }
  }

}
