<div class="button-actions">
  <button mat-raised-button class="red-primary"
     (click)="createRun()">
    <span [id]="'COMING_UP_RUN_CREATE_RUN' | gtmTag" class="btn-action-wrapper-general">
      <mat-icon class="btn-title-icon" [id]="'COMING_UP_RUN_CREATE_RUN' | gtmTag">add</mat-icon>
      <span class="btn-title-content" [id]="'COMING_UP_RUN_CREATE_RUN' | gtmTag">Create Trip</span>
    </span>
  </button>
</div>
<div>
  <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
</div>
<div class="table-description-item"></div>
<app-table #runTable
     (rsvp)="rsvp($event)"
     [dataSource]="comingUpRuns"
     [totalCount]="totalCount"
     [preferences]="preferences"
     [distance]="distance"
      [user]="user"
     (delete)="deleteRun($event)"
     (edit)="editRun($event)"
     (currentPage)="nextPageList($event)"
     (sortChange)="sortList($event)" [runType]="RunTypeEnum.COMING_UP"
     *ngIf="comingUpRuns.length > 0"></app-table>
