import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {AppService} from "../../../core/services/app.service";
import {PremiumAccountService} from "./premium-account.service";
import {AuthService} from "../../../core/services/auth.service";
@Component({
  selector: 'app-dialog-message',
  templateUrl: './premium-account.component.html',
  styleUrls: ['./premium-account.component.scss']
})
export class PremiumAccountComponent {

  public paymentType;
  public paymentsList = [];
  public loading = false;
  public constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                     private appService: AppService,
                     private premiumService: PremiumAccountService,
                     private authService: AuthService) {
    this.paymentsList = this.appService.paymentTypes || [];
    this.paymentType = this.paymentsList[0];
  }

  public initPayment() {
    if (this.loading) {
      return;
    }
    const data = {
      pscUser: {
        id: this.authService.getUser().id
      },
      subscriptionType: {
        id: this.paymentType.id
      }
    }
    this.loading = true;
    this.premiumService.paypalInit(data).subscribe(response => {
      this.loading = false;
      if (response.result && response.result.data) {
        window.location.href = response.result.data[0];
      }
    }, () => {
      this.loading = false;
    });
  }

}
