import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationRunsComponent } from './location-runs.component';
import { SharedModule } from '../../../../shared/shared.module';
import {CoreModule} from '../../../../core/core.module';
import {LocationRunsService} from './location-runs.service';

@NgModule({
  declarations: [
    LocationRunsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    CoreModule
  ],
  providers: [
    LocationRunsService
  ],
  exports: [
    LocationRunsComponent
  ],
  bootstrap: [LocationRunsComponent]
})
export class LocationRunsModule { }
