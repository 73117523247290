import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import { TableTypeEnum } from 'src/app/enums/table-type.enum';
import {AuthService} from '../../../../core/services/auth.service';
import {RunLibraryService} from './run-library.service';
import {BaseComponent} from '../../../../shared/classes/base.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MapEnum} from '../../../../enums/map.enum';
import {CommonService} from '../../../../core/services/common.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {StatusSnackComponent} from '../../../../shared/components/status-snack/status-snack.component';
import {DISTANCE_CONST, PUBLIC_RUN_MAP_ZOOM} from '../../../../consts/distances';
import {DialogConfirmationComponent} from '../../../../shared/components/dialog-confirmation/dialog-confirmation.component';
import TableOptionsModel, {TableOptionsDisableModel} from '../../../../models/tables/table-options.model';
import { runLibraryTableColumns } from 'src/app/shared/common-structures/run-library.list';
import {RunLibraryDetailsComponent} from './run-library-details/run-library-details.component';
import {DialogRunComponent} from '../dialog-run/dialog-run.component';
import {RunsService} from '../runs.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
declare const google: any;
@Component({
  selector: 'app-user-run-library',
  templateUrl: './run-library.component.html',
  styleUrls: ['./run-library.component.scss']
})
export class RunLibraryComponent extends BaseComponent implements OnInit {

  public userPremInformation;
  public isPremium = false;

  public countries = [];
  public states = [];

  public countriesObject = {};
  public stateObject = {};


  public displayedInfo = {
    all: true,
    lat: '',
    lng: ''
  };

  public runLibraryTableColumns = runLibraryTableColumns;

  public loading = {
    countries: false,
    states: false,
    runs: false,
    user: false,
    rsvp: false,
    myRuns: false
  };

  public filterList = {
    country: '',
    state: ''
  };

  public runLibraries = [];
  public totalCount = 0;

  public TableTypeEnum = TableTypeEnum;

  public parameterTable = {
    currentPage: 1,
    pageSize: 10,
    sorts: [{field: 'name', desc: true}]
  };

  public distance = '';
  public preferences = [];

  public map: google.maps.Map;
  public markers: google.maps.Marker[];
  public dialogDisplayRun = null;

  // @ts-ignore MarkerClusterer defined via script
  public clusterManager: MarkerClusterer;

  public options: TableOptionsModel;

  public parameterForSearch = {
    minDistance: '',
    maxDistance: ''
  };

  public libraryRunsCount = {};

  constructor(private authService: AuthService,
              private runLibrariesService: RunLibraryService,
              private runsService: RunsService,
              public dialog: MatDialog,
              private commonService: CommonService,
              public snackBar: MatSnackBar,
              private zone: NgZone,
              private router: Router) {
    super(dialog, snackBar);
  }

  @ViewChild('publicRunTable')
  public publicRunTable;

  public currentPage = 0;

  ngOnInit(): void {
    this.loading.user = true;
    this.createTableOptions();
    this.authService.isUserPremium().subscribe(t => {
      this.loading.user = false;
      if (t.result && t.result.data) {
        this.userPremInformation = t.result.data[0];
        const info = t.serverTime;
        this.isPremium = this.userPremInformation.paidPeriodEndDateBuffer &&
          this.userPremInformation.paidPeriodEndDateBuffer >= info;
        this.filterList.country = this.userPremInformation.locationDetails.countryCode;
        this.filterList.state = this.userPremInformation.locationDetails.stateCode;
        this.initInfoAboutCountries();
      }
    }, () => {
      this.loading.user = false;
    });
    this.userPreferences();
  }

  private createTableOptions() {
    this.options = new TableOptionsModel();
    this.options.disableOptions = new TableOptionsDisableModel();
    this.options.disableOptions.disableActivation = (t) => {
      return !this.isPremium &&
        t.waypoints && t.waypoints.length > 0;
    };
  }

  public resetDistanceParams() {
    this.parameterForSearch.maxDistance = '';
    this.parameterForSearch.minDistance = '';
  }

  private calculateDistance(value) {
    return this.distance === 'km' ? value : value * DISTANCE_CONST;
  }

  public displayList(init?, lat?, lng?) {
    if (this.filterList.country) {
      this.loading.runs = true;
      this.runLibrariesService
        .getRunLibraries(this.filterList.country,
          this.filterList.state, this.parameterTable.currentPage,
          this.parameterTable.pageSize, this.parameterTable.sorts,
          this.calculateDistance(this.parameterForSearch.minDistance),
          this.calculateDistance(this.parameterForSearch.maxDistance)).subscribe(t => {
            this.displayedInfo.all = true;
            if (t && t.result && t.result.data) {
              this.runLibraries = t.result.data || [];
              this.totalCount = t.result.totalCount || 0;
              this.libraryRunsCount = {};
              this.initMap(this.runLibraries,
                this.countriesObject[this.filterList.country], this.filterList.country,
                this.stateObject[this.filterList.state], this.filterList.state,
                init, lat, lng);
            }
            this.loading.runs = false;
      }, () => {
        this.loading.runs = false;
      });
    }

  }

  private prepareLatLonData(array) {
    return array.map(t => {
      return {
        lat: t.startingPointLatitude,
        lng: t.startingPointLongitude
      };
    });
  }

  private initInfoAboutCountries() {
    this.loading.countries = true;
    this.runLibrariesService.getCountries().subscribe(t => {
      this.loading.countries = false;
      if (t.result) {
        this.countries = t.result.data || [];
      }
      this.loading.states = true;
      this.runLibrariesService.getStates().subscribe( t => {
        this.loading.states = false;
        if (t.result) {
          this.states = t.result.data || [];
          this.mapperForCountries();
        }
        this.displayList(true,
          this.userPremInformation.locationDetails.registrationLatitude,
          this.userPremInformation.locationDetails.registrationLongitude);
      }, () => {
        this.loading.states = false;
      });
    }, () => {
      this.loading.countries = false;
    });
  }

  private mapperForCountries() {
    this.countries.forEach(t => {
      this.countriesObject[t.code] = t.name;
    });
    this.states.forEach(t => {
      this.stateObject[t.code] = t.name;
    });
  }

  public isLoaded() {
    return Object.keys(this.loading).some(y => this.loading[y]);
  }

  public updateStateInfo() {
    if (this.filterList.country !== 'US') {
      this.filterList.state = '';
    }
  }

  public nextPageList($event) {
    this.parameterTable.currentPage = $event.pageIndex;
    this.parameterTable.pageSize = $event.pageSize;
    if (this.displayedInfo.all) {
      this.displayList();
    } else {
      this.displayMultiplePointsForCluster(this.displayedInfo.lat, this.displayedInfo.lng);
    }
  }

  public initTable() {
    if (this.publicRunTable) {
      this.publicRunTable.resetPaginator();
    }
  }

  public sortList($event) {
    if ($event.direction) {
      const t: any =
        {field: $event.active, desc: $event.direction === 'desc'};
      this.parameterTable.sorts = [t];
      const columninfo = this.runLibraryTableColumns.find(t => t.field === $event.active);
      if (columninfo && columninfo.extraSort) {
        columninfo.extraSort.forEach(t => {
          t.desc = $event.direction === 'desc';
          this.parameterTable.sorts.push(t);
        });
      }
    } else {
      this.parameterTable.sorts = [{field: 'name', desc: true}];
    }
    if (!this.displayedInfo.all) {
      this.displayMultiplePointsForCluster(this.displayedInfo.lat, this.displayedInfo.lng);
    } else {
      this.displayList();
    }
  }

  public setValues(minValue, maxValue) {
    this.parameterForSearch.minDistance = minValue;
    this.parameterForSearch.maxDistance = maxValue;
  }

  private resetMarkers() {
    if (this.map && this.markers) {
      this.markers.forEach(t => {
        t.setMap(null);
      });
      this.markers = [];
      if (this.clusterManager) {
        this.clusterManager.clearMarkers();
      }
      // @ts-ignore MarkerClusterer defined via script
      new MarkerClusterer(this.map, this.markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        maxZoom: 7,
         zoomOnClick: false
      });
    }
  }

  private initMap(locations, country, countryCode, state, stateCode, init?, lat?, lng?) {

      this.resetMarkers();

      if (!this.map) {
        const opt = {
          zoom: 4.5,
          center: { lat: (country && country !== 'ALL') ? lat || 0 : 0,
            lng: (country && country !== 'ALL') ? lng || 0 : 0},
        //  maxZoom: 20
        };
        this.map = new google.maps.Map(
          document.getElementById('publicRunsId') as HTMLElement,
          opt
        );
      } else {

      }
      if (country && country !== 'ALL') {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode( {address : `${country}, ${countryCode}` + (state ? `${state}, ${stateCode}` : '')},
          (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            // this.map.setCenter(results[0].geometry.location);
            this.map.fitBounds(results[0].geometry.viewport);
          }
        });
      } else {
        this.map.setZoom(2);
        this.map.setCenter({lat: 0, lng: 0});
      }

      // Create an array of alphabetical characters used to label the markers.
      // Add some markers to the map.
      // Note: The code uses the JavaScript Array.prototype.map() method to
      // create an array of markers based on a given "locations" array.
      // The map() method here has nothing to do with the Google Maps API.
      this.markers = locations.map((location, i) => {

         const marker = new google.maps.Marker({
          position: {
            lat: location.startingPointLatitude,
            lng: location.startingPointLongitude
          },
          label: ''
        });

         if (!this.libraryRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude]) {
          this.libraryRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] = 0;
        }
         this.libraryRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] =
          this.libraryRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] + 1;

         marker.addListener('click', (e, y) => {
          if (e && e.vb) {
            e.vb.stopPropagation();
          }
          this.zone.run(() => {

            if (this.libraryRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] > 1) {

              this.displayDialog(DialogConfirmationComponent,
                {
                  message: 'There are multiple runs on this point, would you like to see them? ',
                  type: 'Confirmation'
                }, (result) => {
                  if (result) {
                    this.router.navigate(['user/runs/location/library'],
                      { queryParams: {lat: location.startingPointLatitude, lng: location.startingPointLongitude}
                      });
                  }
                }, () => {});
            }
            else {
              this.displayRunPopupFromMarker(location);
            }
          });
        }, {passive: true});
         return marker;
      });
      this.updateMarkerIcons();
      // Add a marker clusterer to manage the markers.
      // @ts-ignore MarkerClusterer defined via script
      this.clusterManager = new MarkerClusterer(this.map, this.markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        maxZoom: 7,
        zoomOnClick: false
      });
      google.maps.event.addListener(this.clusterManager,
        'clusterclick', (cluster) => {

          let zoom = this.map.getZoom();
          let maxZoom = this.clusterManager.getMaxZoom();
          this.map.setZoom(environment.maximumClusterZoom * 1.25);
          this.map.setCenter(cluster.center_);


          // if we have reached the maxZoom and there is more than 1 marker in this cluster
          // use our onClick method to popup a list of options

         // if (cluster.map_.zoom > PUBLIC_RUN_MAP_ZOOM) {
         //   this.zone.run(() => {
         //     this.displayedInfo.lat = cluster.center_.lat();
         //     this.displayedInfo.lng = cluster.center_.lng();
         //     // this.displayDialog(DialogConfirmationComponent,
         //     //   {
         //     //     message: 'There are multiple runs on this point, would you like to see them? ',
         //     //     type: 'Confirmation'
         //     //   }, (result) => {
         //     //     if (result) {
         //       this.parameterTable.currentPage = 1;
         //       this.displayMultiplePointsForCluster(this.displayedInfo.lat, this.displayedInfo.lng, true);
         //       //   }
         //       // }, () => {});
         //   })
         // }
      });
  }

  private updateMarkerIcons() {
    for (let i = 0; i < this.markers.length; i++) {
       this.markers[i].setIcon(this.displayLocationInfoIcon(this.markers[i].getPosition().lat() + '_' + this.markers[i].getPosition().lng()));
    }
  }

  private displayMultiplePointsForCluster(lat, lng, init?) {
    this.loading.runs = true;
    this.runLibrariesService.getPublicMultiRuns(
      null,
      null, lat, lng,
      this.parameterTable.currentPage,
      this.parameterTable.pageSize, this.parameterTable.sorts
    ).subscribe(t => {
      this.displayedInfo.all = false;
      if (init) {
        this.initTable();
      }
      if (t && t.result && t.result.data) {
        this.runLibraries = t.result.data || [];
        this.totalCount = t.result.totalCount || 0;
        this.initMap(this.runLibraries,
          this.countriesObject[this.filterList.country], this.filterList.country,
          this.stateObject[this.filterList.state], this.filterList.state,
          true, lat, lng);
      }
      this.loading.runs = false;
    }, () => {
      this.loading.runs = false;
    });
  }

  private displayRunPopupFromMarker(location) {
    this.displayRunInfo(location);
  }

  private userPreferences() {
    this.runLibrariesService.getUserPreferences()
      .subscribe((preferences) => {
      if (preferences) {
        this.preferences = preferences.result.data;
        const distance = this.preferences.find(t => {
          return t.keyword === MapEnum.DISTANCE_FORMAT;
        });
        if (distance) {
          this.distance = this.commonService.distanceMapper(distance.value);
        }
      }
    });
  }

  private getPackHandler(packs, callback, errorcallback) {
    this.runsService.getPacks()
      .subscribe(t => {
        if (callback) {
          callback(t);
        }
      }, () => {
        errorcallback([]);
      });
  }

  private displayLocationInfoIcon(location) {
    return this.libraryRunsCount[location] > 1 ?
      {
        url: environment.mapMultipleMarkerUrl,
        scaledSize: new google.maps.Size(30, 45), // scaled size
        // origin: new google.maps.Point(0,0), // origin
        // anchor: new google.maps.Point(0, 0) // anchor
      } : null;
  }

  private createRunHandler(run, callback, errorcallback) {
    this.runsService.addRun(run).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }

    }, () => {
      errorcallback();
    });
  }

  private getPointCategoryHandler(pointCategories, callback, errorcallback) {
    this.runsService.getPointCategories()
      .subscribe(t => {
        if (callback) {
          callback(t);
        }
      }, () => {
        errorcallback([]);
      });
  }

  public copyItem(item, callback?, ignoreLoading?) {
    this.displayDialog(DialogRunComponent, {
      run: item,
      isPremium: this.isPremium,
      preferences: this.preferences,
      isCopy: true,
      createRun: (run, callback, errorcallback) => {this.createRunHandler({
        ...run,
        copyRunId: item.runId,
        curationRunId: item.id,
      }, callback, errorcallback); },
      getPacks: (packs, callback, errorcallback) => {this.getPackHandler(packs, callback, errorcallback); },
      getPointCategories: (pointCategories, callback, errorcallback) => {this.getPointCategoryHandler(pointCategories, callback, errorcallback); }
    }, (run) => {
      // this.displayList();
      item.copyCount += 1;
      this.openNotification(StatusSnackComponent, {message: 'Run was created'}, true);
    }, () => {});
  }

  public displayRunInfo(run) {
    if (!this.dialogDisplayRun) {
      this.dialogDisplayRun = this.displayDialog(RunLibraryDetailsComponent, {
        run,
        isCopy: true,
        isRunCopied: !this.isPremium &&
          run.waypoints && run.waypoints.length > 0,
        distance: this.distance,
        joinRun: (run, callback) => {
          this.copyItem(run, callback, true);
        }
      }, (result) => {
        this.dialogDisplayRun = null;
        if (result && result.planRun) {
          this.copyItem(run);
        }
      }, () => {
        this.dialogDisplayRun = null;
      });
    }
  }

}
