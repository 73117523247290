import { Injectable } from '@angular/core';
import ApiService from '../../core/services/api.service';

@Injectable()
export class PaymentResultService {

  constructor(private apiService: ApiService) {}

  public accept(data) {
    return this.apiService.post('payment/paypal/accept', {
      data: data
    });
  }

}
