import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import { StorageEnum } from '../../enums/storage.enum';
import ApiService from "./api.service";
import {map} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class AuthService {

  private currentUser;

  constructor(private appStorageService: AppStorageService,
              private apiService: ApiService) { }

  public getStaticInfo() {
    return (this.appStorageService.getItemFromLocalStorage(StorageEnum.FIRST_NAME) || '') +
      ' ' +
    (this.appStorageService.getItemFromLocalStorage(StorageEnum.LAST_NAME) || '');
  }

  public logout(callback) {
    /*this.logoutFromSystem()
      .subscribe(() => {*/
        this.appStorageService.removeItemInStorage(StorageEnum.FIRST_NAME);
        this.appStorageService.removeItemInStorage(StorageEnum.LAST_NAME);
        this.appStorageService.removeItemInStorage(StorageEnum.TOKEN);
        this.appStorageService.removeItemInStorage(StorageEnum.LOGIN);
        if (callback) {
          callback();
        }
      /*});*/
  }

  public logoutFromSystem() {
    return this.apiService.post('user/logout', {});
  }

  public isUserPremium() {
    return this.apiService.post('user/auth/check', {
      data: {}
    }).pipe(
      map(res => {
        if (res.result && res.result.data) {
          this.storeUser(res.result.data[0]);
        }
        return res;
      })
    );
  }

  private storeUser(user) {
    this.currentUser = user;
  }

  public getUser() {
    return this.currentUser;
  }

  public isLogin() {
    return this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN);
  }

}
