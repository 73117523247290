<div class="payment-result-container">
  <div class="payment-result-logo">
    <img src="assets/images/verify-wolf.png" routerLink="/"/>
  </div>
  <div class="payment-result-description">
    <ng-container *ngIf="!loading">
      <div>
        <img [src]="success ?
          'assets/images/payment-success-paypal.png' :
          'assets/images/payment-failed-paypal.png'"/>
      </div>
      <ng-container *ngIf="success">
        <div class="payment-result-header">
          Your payment was <span class="success">successfull</span>
        </div>
        <div class="payment-result-description">
          Thank you for joining the growing list of Premium WolfPack riders!
          Enjoy the Road and ride safely!
        </div>
      </ng-container>
      <ng-container *ngIf="!success">
        <div class="payment-result-header">
          Your payment failed
        </div>
        <div class="payment-result-description">
          We are sorry, but, it seems that there is a problem with your subscription. Please try again.
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="loading">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </ng-container>
  </div>
</div>
