import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ResetComponent } from './reset.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {ResetService} from './reset.service';
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    ResetComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        CoreModule
    ],
  providers: [
    ResetService
  ],
  bootstrap: [ResetComponent]
})
export class ResetModule { }
