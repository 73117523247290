import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { VerifyComponent } from './verify.component';
import { ReactiveFormsModule } from '@angular/forms';
import {VerifyService} from './verify.service';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    VerifyComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    VerifyService
  ],
  bootstrap: [VerifyComponent]
})
export class VerifyModule { }
