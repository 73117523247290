<div class="verify-container">
  <div class="verify-container-item small">
    <img src="/assets/images/verify-wolf.png"/>
  </div>
  <div class="verify-container-item big">
    <div  *ngIf="!loading">
      <ng-container *ngIf="resultSuccess">
        <div class="verify-image-container">
          <img src="assets/images/verify-icon.png" class="verify-icon"/>
        </div>
        <div class="verify-header">
          Your email address is now <span class="text-success">verified</span>
        </div>
        <div class="verify-description">
          <b>Great</b>! You may now return to the Wolfpack app and login with your verified email address and the password you entered when creating your account.
        </div>
        <div class="verify-thanks">
          Thanks for joining Wolfpack! Safe riding,
        </div>
        <div class="verify-label">
          The Wolfpack Team
        </div>
      </ng-container>
      <ng-container *ngIf="!resultSuccess">
        <div class="verify-header verify-header-failed">
          Verification is <span class="text-failed">failed</span>
        </div>
      </ng-container>
    </div>
    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>

</div>
