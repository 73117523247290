import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SupportFacebookLoginComponent } from './support-facebook-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SupportFacebookLoginService} from './support-facebook-login.service';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
  declarations: [
    SupportFacebookLoginComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    SupportFacebookLoginService
  ],
  bootstrap: [SupportFacebookLoginComponent]
})
export class SupportFacebookLoginModule { }
