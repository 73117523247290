import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { InvitationComponent } from './invitation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InvitationService } from './invitation.service';
import { SharedModule } from '../../../shared/shared.module';
import { ImageCropperModule } from "ngx-image-cropper";
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    InvitationComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        CoreModule
    ],
  providers: [
    InvitationService
  ],
  bootstrap: [InvitationComponent]
})
export class InvitationModule { }
