<div class="pack-container">
  <div class="header">
    PACK:
  </div>
  <form [formGroup]="packForm" (ngSubmit)="savePack()" class="pack-form-group">
    <div class="pack-wrapper">
      <div class="pack-info-container">
        <div class="pack-info">
          <div>
            <ng-container *ngIf="isView">
              <div class="pack-name">
                <span>
                  {{data?.pack?.name}}
                </span>
                <span *ngIf="isEditor" class="editor-icon">
                  <button mat-icon-button (click)="changeView()" type="button">
                    <mat-icon>edit</mat-icon>
                  </button>
                </span>
              </div>
            </ng-container>
            <ng-container>
              <mat-form-field *ngIf="!isView" class="pack-name-input">
                <mat-label><span class="required">*</span> Pack Name </mat-label>
                <input matInput formControlName="name" [readonly]="isView"
                       [regexReplace]="'emoji'" titleCase>
              </mat-form-field>
            </ng-container>
          </div>
          <div class="pack-id" *ngIf="data.pack">
            {{data.pack.publicId | packID}}
          </div>
          <div>
            <ng-container *ngIf="isView" class="details-view">
              <div class="sub-header">Details</div>
              <div class="description-item">
                {{data?.pack?.description}}
              </div>
            </ng-container>
            <ng-container *ngIf="!isView">
              <mat-form-field class="details-item">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description"
                          #input maxlength="{{descriptionCount}}"
                          [regexReplace]="'emoji'"
                          titleCase
                          [readonly]="isView"></textarea>
                <mat-hint align="end">
                  {{input.value?.length || 0}} / {{descriptionCount}}</mat-hint>
              </mat-form-field>
            </ng-container>
          </div>
        </div>

        <div class="image-upload-container">
          <input type="file"
                 (change)="fileChangeEvent($event)"
                 #imageSelection
                 accept="image/x-png,image/jpeg" />
          <img [src]="currentUpdatedImage || data?.pack?.pictureUrl"
               onerror="this.src='assets/images/default-user.png';"
               />
          <div class="image-upload-box" (click)="isView ? false : imageSelection.click()" matTooltip="Upload image">
            <mat-icon class="upload-image" >add_photo_alternate</mat-icon>
          </div>
        </div>

      </div>
      <mat-card class="members-list-container">
        <mat-card-title>
          <div class="member-title">Members</div>
        </mat-card-title>
        <mat-card-content class="members-container">
          <div class="member-table">
            <div *ngIf="!activeUsers || activeUsers.length === 0">
              No members
            </div>
            <app-table
              #membersTable
               [expand]="false"
               [options]="optionsForTable"
               [columns]="packMemberColumns"
               [ignoreSpaceForAction]="isView"
               [dataSource]="activeUsers"
               [sorted]="false"
               [isStaticDataSource]="true"
               (delete)="removeUserFromRun($event)"
               [runType]="TableType.PACK_MEMBER"
               *ngIf="activeUsers && activeUsers.length > 0"></app-table>
          </div>
        </mat-card-content>
      </mat-card>
      <div class="add-member-container"  *ngIf="!isView">
        <button (click)="displayMembers()" [disabled]="loading"
                mat-raised-button color="accent" class="add-member" type="button" [id]="'PACK_ADD_MEMBER' | gtmTag">
          <mat-icon class="add-member-icon" [id]="'PACK_ADD_MEMBER' | gtmTag">person_add</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>
<div class="actions-table">
  <div class="actions-table-wrapper">
    <button mat-raised-button color="primary" type="submit"
            [disabled]="loading" (click)="savePack()" *ngIf="!isView" >
      <span class="btn-action-wrapper-general" [id]="'MYPACK_DIALOG_SAVE_PACK_BTN' | gtmTag">Save</span>
    </button>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <div class="error-display-information">
      {{errorNotification}}
    </div>
  </div>
  <div class="progress-bar-loading">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
</div>

