import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import TableColumnModel from '../../../models/tables/table-column.model';
import {TableTypeEnum} from '../../../enums/table-type.enum';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {runTableColumns} from '../../common-structures/run-table.list';
import {MatTableDataSource} from '@angular/material/table';
import {AppService} from '../../../core/services/app.service';
import RunTableOptionsModel from '../../../models/tables/table-options.model';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-table',
  templateUrl: './run-table.component.html',
  styleUrls: ['./run-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RunTableComponent implements OnInit, AfterViewInit {

  expandedElement: any;

  @Input()
  public runType: TableTypeEnum;

  public RunTypeEnum = TableTypeEnum;

  @Input()
  public options: RunTableOptionsModel;

  @Input()
  public waypointsName = 'waypoints';

  @Input()
  public expand = true;

  @Input()
  public isStaticDataSource = false;

  @Input()
  public user;

  @Input()
  public sorted = true;

  public tdataSource = [];

  @Input()
  public pageSize = 10;

  @Input()
  public ignoreSpaceForAction = false;

  @Input()
  public set dataSource(dataSource) {
    this.tdataSource = dataSource || [];
    this.refresh();
  }


  public data = new MatTableDataSource<any>();

  @Input()
  public totalCount = 0;

  @Input()
  public preferences = [];

  @Input()
  public distance = '';

  @Input()
  public onRowClickSupport = false;

  @Input()
  public columns: TableColumnModel[] = runTableColumns;

  @Output()
  public currentPage: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public sortChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public edit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public delete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public start: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public rsvp: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public view: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public details: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public join: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public duplication: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedColumns: string[] = [];

  public constructor(private changeDetectorRefs: ChangeDetectorRef,
                     private appService: AppService) {}

  @ViewChild('tableSource')
  public table;

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(y => y.label);
    this.refresh();

  }

  public ngAfterViewInit() {
    if (this.isStaticDataSource) {
      this.data.paginator = this.paginator;
    }
  }

  public changePage(page) {
    this.currentPage.emit({pageIndex: page.pageIndex + 1, pageSize: page.pageSize});
  }

  public sortData($event) {
    this.sortChange.emit($event);
  }

  public waypointsPreparation(e) {
    return (e.waypoints || []).map((e2) => {return {
        stopover: true,
        location: {lat: e2.lat, lng: e2.lon}
      };
    });
  }

  public displayMap(row) {

  }

  public editRun(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.edit.emit(item);
  }

  public deleteRun(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.delete.emit(item);
  }

  public rsvpRun(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.rsvp.emit(item);
  }

  public startItem(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.start.emit(item);
  }

  public joinItem(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.join.emit(item);
  }

  public refresh() {
    this.data = new MatTableDataSource<any>(this.tdataSource);
    if (this.isStaticDataSource) {
      this.data.paginator = this.paginator;
    }
    this.changeDetectorRefs.detectChanges();
  }

  public viewItem(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.view.emit(item);
  }

  public displayDetails(item, $event) {
    if (this.onRowClickSupport) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      this.details.emit(item);
    }
  }

  public copyItem(item, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.duplication.emit(item);
  }

  public resetPaginator() {
    this.paginator.pageIndex = 0;
  }

}
