import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FeedbackComponent } from './feedback.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FeedbackService } from './feedback.service';
import { SharedModule } from '../../shared/shared.module';
import {CoreModule} from '../../core/core.module';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    FeedbackComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    ScrollingModule,
    FormsModule
  ],
  providers: [
    FeedbackService
  ],
  bootstrap: [FeedbackComponent]
})
export class FeedbackModule { }
