import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';
import {shareReplay} from "rxjs/operators";

@Injectable()
export class PublicRunsService {

  constructor(private apiService: ApiService) {}

  public getCountries() {
    return this.apiService.post('util/get/all/countries', {
      data: {},
      pageSize: 1000,
      sort:[{field:"name", desc: false}],
    });
  }

  public getStates() {
    return this.apiService.post('util/get/all/us/states', {
      data: {},
      pageSize: 1000,
      sort:[{field:"name", desc: false}],
    });
  }

  public getPublicRuns(startCountry, startState, page?, pageSize?, sort?,
                       minDistance?, maxDistance?) {
    return this.apiService.post('run/get/public', {
      data: {
        maxDistance: maxDistance || null,
        minDistance: minDistance || null,
        startCountry: startCountry,
        startState: startState || null,
        includedCompleted: true
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sort || [{field:"name", desc: false}],
    });
  }

  public getPublicMultiRuns(startCountry, startState,
                       lat, lng, page?, pageSize?, sort?) {
    return this.apiService.post('run/get/public', {
      data: {
        radius: 1,
        startPointLatitude: lat,
        startPointLongitude: lng,
        startCountry: startCountry,
        startState: startState || null
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sort || [{field:"name", desc: false}],
    });
  }

  public getUserPreferences() {
    return this.apiService.post('user/preferences/get', {
      data: {},
    }).pipe(shareReplay(1));
  }

  public sendRsvpForPublicRun(rsvpResponseId, runId) {
    return this.apiService.post('run/public/rsvp', {
      data: {
        rsvpResponseId,
        runId
      }
    });
  }

  public getJoinedPublicRuns() {
    return this.apiService.post('run/search/own', {
      data: {
        hideDeniedPublicRuns: true,
        participated: null,
        runStatuses: [1, 4],
        isPublic: 1
      },
      page: 1,
      pageSize: 1000,
      sort: [{field: 'name', desc: true}]
    });
  }

}
