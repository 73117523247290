import { NgModule } from '@angular/core';
import { StatusSnackComponent } from './status-snack.component';
import { MaterialModule } from '../../external-modules/material.module';
import {CommonModule} from "@angular/common";
import {ImageCropperModule} from "ngx-image-cropper";

@NgModule({
  declarations: [
    StatusSnackComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    ImageCropperModule
  ],
  providers: [],
  bootstrap: [StatusSnackComponent]
})
export class StatusSnackModule { }

