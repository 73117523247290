export const genderList = [
  {
    name: 'Male',
    value: 1
  },
  {
    name: 'Female',
    value: 2
  }
]

