export default {
  COMING_UP_TAB: 'run_coming_up_tab',
  LOGBOOK_TAB: 'run_logbook_tab',
  COMING_UP_RUN_CREATE_RUN: 'coming_up_run_create_run_btn',
  RUN_DIALOG_SAVE_RUN: 'run_dialog_save_run',
  RUN_DIALOG_ADD_WAYPOINT: 'run_dialog_add_waypoint',
  COMING_UP_RUN_EDIT_RUN: 'coming_up_run_edit_run_btn',
  COMING_UP_RUN_DELETE_RUN: 'coming_up_run_delete_run_btn',
  RSVP_DIALOG_SAVE_RSVP: 'rsvp_dialog_save_rsvp_btn',
  LOGBOOK_START_RUN: 'logbook_run_start_btn',
  MYPACK_CREATE_PACK: 'mypack_create_pack_btn',
  MYPACK_DIALOG_SAVE_PACK_BTN: 'mypack_dialog_save_pack_btn',
  MYPACK_EDIT_PACK_BTN: 'mypack_edit_pack_btn',
  MYPACK_DELETE_PACK_BTN: 'mypack_delete_pack_btn',
  LIBRARY_PLAN_RUN: 'library_plan_run_btn',
  PUBLIC_RUN_JOIN_RUN: 'public_run_join_run_btn',
  LIBRARY_VIEW_RUN: 'library_view_run_btn',
  PUBLIC_RUN_RESEARCH: 'public_run_research',
  ACCOUNT_UPGRADE_TO_PREMIUM: 'account_upgrade_to_premium',
  PACK_ADD_MEMBER: 'pack_add_member',
  FAQ_CATEGORY: 'faq_category',
  FAQ_CATEGORY_QUESTION: 'faq_category_question',
  STAT_TROPHY: 'stat_trophy',
  PROFILE_GENERAL_SAVE: 'profile_general_save',
  PROFILE_UPDATE_PASSWORD: 'profile_update_password',
  LIBRARY_RUN_RESEARCH: 'library_run_research',
  WAYPOINT_DETAILS_DIALOG_SAVE_WAYPOINT: 'waypoint_details_save_btn'
};
