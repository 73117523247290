import TableColumnModel from '../../models/tables/table-column.model';
import {UserToRunStatusEnum} from "../../enums/user-to-run-status.enum";

export const publicRunTableColumns: TableColumnModel[] = [

  {
    name: 'Name',
    label: 'name',
    field: 'name',
    isSticky: true,
    initial: true
  },
  {
    name: 'Distance',
    label: 'distance',
    field: 'distance',
    pipe: 'kmDistance'
  },
  {
    name: 'Start Date',
    label: 'startDate',
    field: 'startDate',
    pipe: 'date'
  },
  {
    name: 'End Date',
    label: 'endDate',
    field: 'endDate',
    pipe: 'date'
  },
  {
    name: 'Number of Participants',
    label: 'totalUsers',
    field: 'totalUsers',
    ignoreSort: true,
    center: true
  },
  {
    name: 'Start Point Address',
    label: 'startingPointName',
    field: 'startingPointName',
    ignoreSort: true
  },
  {
    name: 'End Point Address',
    label: 'endingPointName',
    field: 'endingPointName',
    ignoreSort: true
  },
  {
    name: 'Trip organizer',
    label: 'alpha',
    field: '',
    formatter: (t) => {
      return t.alphaFirstName + ' ' + t.alphaLastName;
    },
    ignoreSort: true
  },
  {
    name: 'Status',
    label: 'runStatus',
    field: '',
    formatter: (t) => {
      switch (t.runStatus) {
        case UserToRunStatusEnum.ACTIVE: {
          return 'Active';
        }
        case UserToRunStatusEnum.NOT_YET_STARTED: {
          return 'Not yet started';
        }
        case UserToRunStatusEnum.DONE: {
          return 'Done';
        }
        default: {
          return '';
        }
      }
    },
    ignoreSort: true
  },
  {
    name: '',
    label: 'action',
    field: '',
    isAction: true
  }
];
