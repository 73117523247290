import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RsvpComponent } from './rsvp.component';
import { SharedModule } from '../../../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {CoreModule} from "../../../../core/core.module";

@NgModule({
  declarations: [
    RsvpComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        FormsModule,
        CoreModule,
    ],
  providers: [],
  exports: [
    RsvpComponent
  ],
  bootstrap: [RsvpComponent]
})
export class RsvpModule { }
