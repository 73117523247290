export const METERS_TO_MILES = 0.000621371192;
export const PUBLIC_RADIUS = '2000.0';
export const DISTANCE_CONST = 1.60934;
export const DISTANCES = [
  {
    name: 'Miles',
    id: 2
  },
  {
    name: 'Kilometers',
    id: 3
  }
];
export const PUBLIC_RUN_MAP_ZOOM = 20;
