import {AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LatLngModel} from '../../../models/maps/lat-lng.model';
import {DialogMessageComponent} from '../dialog-message/dialog-message.component';
import {MessageTypeEnum} from '../../../enums/message-type.enum';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit, OnDestroy, AfterViewInit {

  public zoom = 14;

  @Input()
  public origin: LatLngModel;

  @Input()
  public destination: LatLngModel;

  @Input()
  public waypoints: LatLngModel[] = [];

  @Input()
  public mode;

  @Input()
  public avoidFerries;

  @Input()
  public avoidHighways;

  @Input()
  public avoidTolls;

  @ViewChild('runMapId')
  public runMapId: ElementRef;

  public runMapParameters: any = {
    startMarker: undefined,
    startCoordinates: undefined,
    startCity: undefined,
    startCountry: undefined,
    startState: undefined,
    endMarker: undefined,
    endCoordinates: undefined,
    endCity: undefined,
    endCountry: undefined,
    endState: undefined,
    distance: 0,
    duration: 0
  };

  public constructor(
    private zone: NgZone,
  ) {}

  initMapDraw = 0;
  public map: google.maps.Map;
  public directionsService: google.maps.DirectionsService;
  public directionsDisplay;
  isPathValid;
  ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    this.initMap();
    setTimeout(y => {
        this.initMapDraw = 1;
        this.map.setZoom(1.9);
        this.resize();
        this.centerMap(() => {
          this.updateMapPoints();
        });
    }, 300);
  }

  private updateMapPoints() {
    this.runMapParameters.startMarker = new google.maps.Marker({
      position: {lat: this.origin.latitude,
        lng: this.origin.longitude},
      title: ''
    });
    this.runMapParameters.endMarker = new google.maps.Marker({
      position: {lat: this.destination.latitude,
        lng: this.destination.longitude},
      title: ''
    });
    this.runMapParameters.startCoordinates =
      new google.maps.LatLng(
        this.origin.latitude,
        this.origin.longitude
    );
    this.runMapParameters.startMarker.setMap(this.map);

    this.runMapParameters.endCoordinates =
      new google.maps.LatLng
      (
        this.origin.latitude,
        this.origin.longitude
      );
    this.runMapParameters.endMarker.setMap(this.map);
    this.displayRoute(this.origin,
        this.destination,
        this.directionsService, this.directionsDisplay, false);
  }

  private initMap() {
    this.map = new google.maps.Map(this.runMapId.nativeElement, {
      center: {lat: 0, lng: 0},
      zoom: 1.7
    });
    google.maps.event.trigger(this.map, 'resize');
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      draggable: false,
      map: this.map
    });
  }

  private displayRoute(origin, destination, service, display, isCenteredIgnored?) {
    if (!this.origin || !this.destination) {
      return;
    }
    if (this.runMapParameters.startMarker) {
      this.runMapParameters.startMarker.setMap(null);
    }
    if (this.runMapParameters.endMarker) {
      this.runMapParameters.endMarker.setMap(null);
    }
    setTimeout(t => {
      service.route({
        origin: { lat: origin.latitude, lng: origin.longitude }, // Haight.
        destination: { lat: destination.latitude, lng: destination.longitude }, // Ocean Beach.
        waypoints: this.waypoints,
        travelMode: this.mode || 'DRIVING',
        avoidFerries: !!this.avoidFerries,
        avoidHighways: !!this.avoidHighways,
        avoidTolls: !!this.avoidTolls,
      }, (response, status) => {
        this.zone.run(() => {
          if (status === 'OK') {
            this.isPathValid = true;
            display.setDirections(response);
            setTimeout(() => {
              this.centerMap();
              this.resize();
            }, 200);
          } else {
            this.isPathValid = false;
          }
        });
      });
    }, 50);
  }

  private centerMap(callback?) {
    if (!(this.origin && this.destination)) {
      return;
    }
    this.initMapDraw = 1;
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(new google.maps.LatLng(this.origin.latitude, this.origin.longitude));
    this.waypoints.forEach((x: any) => {
        bounds.extend(new google.maps.LatLng(x.location.lat, x.location.lng));
    });
    bounds.extend(new google.maps.LatLng(this.destination.latitude, this.destination.longitude));
    google.maps.event.trigger(this.map, 'resize');
    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);
    this.resize();
    if (callback) {
      callback();
    }
  }

  resize() {
    const currentCenter = this.map.getCenter();  // Get current center before resizing
    google.maps.event.trigger(this.map, 'resize');
    this.map.setCenter(currentCenter); // Re-set previous center
  }

  ngOnDestroy(): void {
    google.maps.event.clearListeners(this.map, 'bounds_changed');
    google.maps.event.clearListeners(this.map, 'resize');
    google.maps.event.clearListeners(this.directionsService, 'directions_changed');
    this.map = null;
  }

}
