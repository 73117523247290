import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RunsService} from '../runs.service';
import RunModel from '../../../../models/run.model';
import { runTableColumns } from 'src/app/shared/common-structures/run-table.list';
import { TableTypeEnum } from 'src/app/enums/table-type.enum';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {BaseComponent} from '../../../../shared/classes/base.component';
import {RsvpComponent} from '../rsvp-dialog/rsvp.component';
import {MapEnum} from "../../../../enums/map.enum";
import {CommonService} from "../../../../core/services/common.service";
import {DialogRunComponent} from "../dialog-run/dialog-run.component";
import {StatusSnackComponent} from "../../../../shared/components/status-snack/status-snack.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {MODE_LIST} from "../../../../consts/mode-type";

@Component({
  selector: 'app-user-runs-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.scss']
})
export class LogbookComponent extends BaseComponent implements OnInit, OnDestroy {

  public RunTypeEnum = TableTypeEnum;

  public parameterTable = {
    currentPage: 1,
    pageSize: 10,
    sorts: [{field: 'creationDate', desc: true}]
  };
  public preferences: any[] = [];
  public distance;
  public loading = false;
  public comingUpRuns: RunModel[] = [];
  public runTableColumns = runTableColumns;
  public totalCount = 0;

  logbookSubscribe;

  @ViewChild('runTable')
  public table;

  @Input()
  public isPremium: boolean = false;

  public filter = {
    name: '',
    capturedPointExisted: false,
    participated: true,
    mode: '',
    startDate: '',
    packId: ''
  };

  public modes = MODE_LIST;
  public packs = [];

  constructor(private runsService: RunsService,
              public dialog: MatDialog,
              private commonService: CommonService,
              public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  ngOnDestroy(): void {
    if (this.logbookSubscribe) {
      this.logbookSubscribe.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.displayList();
    this.userPreferences();
    this.getPackHandler(null,(packs) => {
      this.packs = packs.result.data.sort((a, b) => a.name - b.name);
    }, () => {})
  }

  private filterMapper(filter) {
    const filteredItems = {};
    if (filter.name) {
      filteredItems['name'] = filter.name;
    }
    if (filter.capturedPointExisted) {
      filteredItems['capturedPointExisted'] = filter.capturedPointExisted;
    }
    if (filter.participated === false) {
      filteredItems['participated'] = true
    }
    if (filter.mode) {
      filteredItems['mode'] = filter.mode;
    }
    if (filter.startDate) {
      filteredItems['startDate'] = +filter.startDate;
    }
    if (filter.packId) {
      filteredItems['packId'] = filter.packId;
    }
    return filteredItems;
  }

  public filterPage() {
    this.loading = true;
    if (this.logbookSubscribe) {
      this.logbookSubscribe.unsubscribe();
    }
    this.logbookSubscribe = this.runsService.getLogbookRuns(
      this.parameterTable.currentPage,
      this.parameterTable.sorts,
      this.parameterTable.pageSize,
      this.filterMapper(this.filter)
    ).subscribe(t => {
      if (t || t.result || t.result.data) {
        this.comingUpRuns = t.result.data || [];
        this.totalCount = t.result.totalCount;
        if (this.table) {
          this.table.refresh();
        }
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  private userPreferences() {
    this.runsService.getUserPreferences().subscribe((preferences) => {
      if (preferences) {
        this.preferences = preferences.result.data;
        const distance = this.preferences.find(t => {
          return t.keyword === MapEnum.DISTANCE_FORMAT;
        });
        if (distance) {
          this.distance = this.commonService.distanceMapper(distance.value);
        }
      }
    })
  }

  public nextPageList($event) {
    this.parameterTable.currentPage = $event.pageIndex;
    this.parameterTable.pageSize = $event.pageSize;
    this.displayList();
  }

  public sortList($event) {
    if ($event.direction) {
      const t: any =
        {field: $event.active, desc: $event.direction === 'desc'};
      this.parameterTable.sorts = [t];
      const columninfo = this.runTableColumns.find(t => t.field === $event.active);
      if (columninfo && columninfo.extraSort) {
        columninfo.extraSort.forEach(t => {
          t.desc = $event.direction === 'desc';
          this.parameterTable.sorts.push(t);
        });
      }
    } else {
      this.parameterTable.sorts = [{field: 'creationDate', desc: true}];
    }
    this.displayList();
  }

  public displayList() {
    this.loading = true;
    this.runsService.getLogbookRuns(this.parameterTable.currentPage,
      this.parameterTable.sorts, this.parameterTable.pageSize,
      this.filterMapper(this.filter)).subscribe(t => {
      if (t || t.result || t.result.data) {
        this.comingUpRuns = t.result.data || [];
        this.totalCount = t.result.totalCount;
        if (this.table) {
          this.table.refresh();
        }
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }


  public start(item) {
    this.displayDialog(DialogRunComponent, {
      run: item,
      isPremium: this.isPremium,
      preferences: this.preferences,
      isCopy: true,
      createRun: (run, callback, errorcallback) => {this.createRunHandler(run, callback, errorcallback);},
      getPacks: (packs, callback, errorcallback) => {this.getPackHandler(packs, callback, errorcallback);},
      getPointCategories: (pointCategories, callback, errorcallback) => {this.getPointCategoryHandler(pointCategories, callback, errorcallback);}
    }, (run) => {
      // this.displayList();
      this.openNotification(StatusSnackComponent, {message: 'Run was created'}, true);
    }, () => {});
  }


  private getPointCategoryHandler(pointCategories, callback, errorcallback) {
    this.runsService.getPointCategories()
      .subscribe(t => {
        if (callback) {
          callback(t);
        }
      }, () => {
        errorcallback([]);
      });
  }

  private createRunHandler(run, callback, errorcallback) {
    this.runsService.addRun(run).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }
    }, () => {
      errorcallback();
    });
  }

  public rsvp($event) {
    this.displayDialog(RsvpComponent, {
      item: $event,
      type: TableTypeEnum.LOGBOOK,
      saveRsvp: (item, callback, errorcallback) => {
        this.saveRsvp(item, callback, errorcallback);
      },
      rsvpList: (runId, callback, errorcallback) => {
        this.rsvpList(runId, callback, errorcallback);
      }
    }, () => {}, () => {});
  }

  private getPackHandler(packs, callback, errorcallback) {
    this.runsService.getPacks()
      .subscribe(t => {
        if (callback) {
          callback(t);
        }
      }, () => {
        errorcallback([]);
      });
  }

  private saveRsvp(item, callback, errorcallback) {
    this.runsService.rsvp(item.guestsCount,
      item.rsvpResponseId,
      item.runId).subscribe(t => {
      if (t && t.result && t.result.data && t.result.data[0]) {
        const itemResponse = t.result.data[0];
        const index = this.comingUpRuns.findIndex(it => +it.id === +itemResponse.runId);
        if (this.comingUpRuns[index]) {
          this.comingUpRuns[index].response = itemResponse.response;
          this.comingUpRuns[index].guestsCount = itemResponse.guestsCount;
        }
        this.comingUpRuns[index] = itemResponse;
        if (callback) {
          callback();
        }
      } else {
        if (errorcallback) {
          errorcallback();
        }
      }
    }, () => {
      if (errorcallback) {
        errorcallback();
      }
    });
  }

  private rsvpList(runId, callback, errorcallback) {
    this.runsService.rsvpList(runId).subscribe(t => {
      if (t && t.result && t.result.data) {
        if (callback) {
          callback(t.result.data);
        }
      } else {
        if (errorcallback) {
          errorcallback();
        }
      }
    }, () => {
      if (errorcallback) {
        errorcallback();
      }
    });
  }

}
