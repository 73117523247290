import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {AuthService} from "../../../../../core/services/auth.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {RunLibraryService} from "../run-library.service";

declare const google: any;

@Component({
  selector: 'app-user-run-library-details',
  templateUrl: './run-library-details.component.html',
  styleUrls: ['./run-library-details.component.scss']
})
export class RunLibraryDetailsComponent implements OnInit, AfterViewInit {

  public isPremium = false;
  public displayedMap = false;

  public countriesObject = {};

  public loading = false;

  public run;
  public errorNotification = '';
  public distance: string = '';
  public isRunCopied = false;

  constructor(private authService: AuthService,
              public dialogRef: MatDialogRef<RunLibraryDetailsComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: any,) {
  }

  ngOnInit(): void {
    this.run = this.data.run;
    this.distance = this.data.distance;
    this.isRunCopied = !!this.data.isRunCopied;
  }

  public waypointsPreparation(e) {
    return (e.waypoints || []).map((e2) => {return {
      stopover: true,
      location: {lat: e2.lat, lng: e2.lon}
    };
    });
  }

  public planRun() {
    this.dialogRef.close({planRun: true});
  }

  public cancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    setTimeout((t) => {
      this.displayedMap = true;
    }, 200);
  }

}
