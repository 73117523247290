import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {FormArray, UntypedFormBuilder, FormControl, Validators} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {BaseComponent} from "../../../../shared/classes/base.component";
import {DOCUMENT} from "@angular/common";
import {PacksService} from "../packs.service";
import {environment} from "../../../../../environments/environment";
import {DialogMessageComponent} from "../../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../../enums/message-type.enum";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-user-dialog-pack',
  templateUrl: './dialog-pack-members.component.html',
  styleUrls: ['./dialog-pack-members.component.scss']
})
export class DialogPackMembersComponent extends BaseComponent implements OnInit, AfterViewInit {

  public loading = false;
  public friends: any[] = [];
  public toBeAdded: any[] = [];
  public maxLengthForWolfpackId = environment.uniqueId;
  public wolfpackId = '';

  public searchWolfpackMember = '';

  public friendsAll = false;
  public toBeAddedAll = false;
  public activeUsers: any[] = [];
  public user;

  public constructor(private builder: UntypedFormBuilder,
                     public dialogRef: MatDialogRef<DialogPackMembersComponent>,
                     public dialog: MatDialog,
                     @Inject(MAT_DIALOG_DATA)
                     public data: any,
                     @Inject(DOCUMENT) document,
                     private packsService: PacksService,
                     public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.user = this.data.user;
    this.getFriends();
  }

  private checkActiveFriends() {
    this.activeUsers = this.data.activeUsers || [];
    const activeUsersObj = {};
    this.activeUsers.forEach(y => {
      activeUsersObj[y.id] = true;
    });
    activeUsersObj[this.user.id] = true;
    this.friends = this.friends.filter(y => {
      return !activeUsersObj[y.id]
    });
  }

  private getFriends() {
    this.loading = true;
    this.packsService.getFriends()
      .subscribe((t) => {
      if (t && t.result && t.result.data) {
        this.friends = t.result.data;
        this.selectAlreadySelectedMembers();
        this.checkActiveFriends();
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  private selectAlreadySelectedMembers() {

  }

  public ngAfterViewInit(): void {

  }

  public saveMembers() {
    this.dialogRef.close({
      uniqueIds: this.toBeAdded.filter(t => t.isUniqueId).map(t => t.uniqueId),
      emails: this.toBeAdded.filter(t => !t.isUniqueId).map(t => t.username),
      newActiveUsers: this.toBeAdded.map(t => {
        t.isNewAddedItem = true;
        return t;
      })
    });
  }


  private filterAndMovingArray(from, to) {
    const selectedFriends = from.filter(t => t.selected);
    from = from.filter(t => !t.selected);
    to = to.concat(selectedFriends.map(t => {
      t.selected = false;
      return t;
    }));
    return {
      from,
      to
    }
  }

  public removeFromMembers() {
    const response = this.filterAndMovingArray(this.toBeAdded, this.friends);
    this.toBeAdded = response.from;
    this.friends = response.to;
  }

  public moveToMembers() {
    const response = this.filterAndMovingArray(this.friends, this.toBeAdded);
    this.friends = response.from;
    this.toBeAdded = response.to;
    this.checkSelection();
  }

  public cancel() {
    this.dialogRef.close();
  }

  public sendInvitation() {
    if (this.searchWolfpackMember && isNaN(+this.searchWolfpackMember)) {
      this.inviteByEmail();
    } else {
      this.loading = true;
      this.packsService
        .getUserByUniqueId(this.searchWolfpackMember)
        .subscribe(t => {
          if (t && t.result && t.result.data) {

            this.loading = false;

            const user = t.result.data[0];

            if (!user) {
              this.displayDialog(DialogMessageComponent, {
                type: MessageTypeEnum.WARNING,
                explanation: 'User not found',
                message: ''
              }, () => {}, () => {});
              return;
            }

            if (user.id === this.user.id) {
              this.displayDialog(DialogMessageComponent, {
                type: MessageTypeEnum.WARNING,
                explanation: 'This user is owner of pack',
                message: ''
              }, () => {}, () => {});
              return;
            }

            if (this.data.activeUsers.findIndex(t => t.id === user.id) !== -1) {
              this.displayDialog(DialogMessageComponent, {
                type: MessageTypeEnum.WARNING,
                explanation: 'This user is member of pack',
                message: ''
              }, () => {}, () => {});
              return;
            }

            user.isUniqueId = true;
            this.wolfpackId = '';
            const alreadyExistInFriends =
              this.friends.find(t => t.id === user.id);
            if (alreadyExistInFriends) {
              this.friends
                .splice(this.friends.indexOf(alreadyExistInFriends), 1);
            }
            const alreadyAdded = this.toBeAdded
              .find(t => t.id === user.id);
            if (!alreadyAdded) {
              this.toBeAdded.push(user);
            }
            this.checkSelection();
          }

        }, () => {
          this.loading = false;
        });
    }
  }

  private filteringUsersByStaticData(potentialFriend, alreadyAddedPotentialFriend, searchWolfpackMember) {
    if (!potentialFriend && !alreadyAddedPotentialFriend) {

      if (searchWolfpackMember === this.user.username) {
        this.displayDialog(DialogMessageComponent, {
          type: MessageTypeEnum.WARNING,
          explanation: 'This user is owner of pack',
          message: ''
        }, () => {}, () => {});
        return;
      }

      if (this.data.activeUsers.findIndex(t => t.username === searchWolfpackMember) !== -1) {
        this.displayDialog(DialogMessageComponent, {
          type: MessageTypeEnum.WARNING,
          explanation: 'This user is member of pack',
          message: ''
        }, () => {}, () => {});
        return;
      }

      this.toBeAdded.push({
        username: searchWolfpackMember,
        pictureUrl: environment.emailImage
      });

    } else if (!alreadyAddedPotentialFriend) {
      this.toBeAdded.push(potentialFriend);
      this.friends.splice(this.friends.findIndex(y => +y.id === +potentialFriend.id), 1);
    }
  }

  private inviteByEmail() {
    const potentialFriend =
      this.friends.filter(t => t.username ===
        this.searchWolfpackMember.trim());
    const alreadyAddedPotentialFriend = this.toBeAdded
      .filter(t => t.username === this.searchWolfpackMember.trim());
    this.filteringUsersByStaticData(potentialFriend[0], alreadyAddedPotentialFriend[0], this.searchWolfpackMember.trim());
    this.checkSelection();
    this.searchWolfpackMember = '';
  }

  private checkSelection() {
    this.toBeAddedAll = this.toBeAdded.length > 0 &&
      this.toBeAdded.every(t => t.selected && !!t.id);
    this.friendsAll = this.friends.length > 0 &&
      this.friends.every(t => t.selected && !!t.id);
  }

  public selectAll(value, array, action) {
    if (action) {
      array.forEach(t => {
        t.selected = value;
      });
    }
  }

  public isValidSearchText() {
    return this.idWPId() ||
      this.isValidEmail();
  }

  public idWPId() {
    return !!this.searchWolfpackMember &&
        (this.searchWolfpackMember.length === 8 && /^\d+$/.test(this.searchWolfpackMember));
  }

  public isValidEmail() {
    return !!this.searchWolfpackMember &&
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.searchWolfpackMember);
  }

}
