import { NgModule } from '@angular/core';
import { ImageDisplayComponent } from './image-display.component';
import { MaterialModule } from '../../external-modules/material.module';
import {CommonModule} from "@angular/common";
import {ImageCropperModule} from "ngx-image-cropper";

@NgModule({
  declarations: [
    ImageDisplayComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    ImageCropperModule
  ],
  providers: [],
  bootstrap: [ImageDisplayComponent]
})
export class ImageDisplayModule { }

