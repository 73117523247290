import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from './invitation.service';
import {BaseComponent} from '../../../shared/classes/base.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent extends BaseComponent implements OnInit {

  public loading = false;
  public userRegistrationInfo = {
    exists: true,
    registrationUrl: '',
    organizationName: '',
    organizationLogo: '',
    organizationEmail: ''
  };
  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private invitationService: InvitationService,
                     public dialog: MatDialog,
                     public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loading = true;
      const travellerId = params.traveller_token;
      this.invitationService.getRegistrationDetails(travellerId).subscribe((response) => {
        this.loading = false;
        this.userRegistrationInfo = response.result.data[0];
      }, () => {
        this.loading = false;
      });
    });
  }

}
