import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class ResetService {

  constructor(private apiService: ApiService) {}

  public restore(data) {
    return this.apiService.post('user/password/change/with/token/process', {
      data: {
        token: data.token,
        password: data.password
      }
    });
  }

}
