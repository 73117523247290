<div>
  <mat-progress-bar *ngIf="isLoaded()" mode="query"></mat-progress-bar>
</div>
<app-main-page-title [title]="'Public runs'"></app-main-page-title>
<div class="countries-form">
  <div class="form-header">
    Change Location
  </div>
  <div>
    <div class="filter-settings">
      <div class="filter-settings-container">
        <div>
          <mat-form-field class="point-item">
            <mat-label>Country</mat-label>
            <mat-select [(ngModel)]="filterList.country"
                        (ngModelChange)="parameterTable.currentPage = 1; resetDistanceParams(minDistance, maxDistance); initTable(); updateStateInfo(); displayList()"
                        [disabled]="isLoaded()" disableOptionCentering>
              <mat-option [value]="'ALL'">All</mat-option>
              <mat-option *ngFor="let country of countries"
                          [value]="country.code">
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="point-item">
            <mat-label>State</mat-label>
            <mat-select [(ngModel)]="filterList.state"
                        [disabled]="filterList.country !== 'US' || isLoaded()"
                        (ngModelChange)="parameterTable.currentPage = 1;  resetDistanceParams(minDistance, maxDistance); initTable();  displayList()" disableOptionCentering>
              <mat-option></mat-option>
              <mat-option *ngFor="let state of states"
                          [value]="state.code">
                {{state.name}} {{state.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="distances">
          <mat-form-field class="point-item">
            <mat-label>Min distance</mat-label>
            <input  matInput #minDistance appOnlyNumber [regexReplace]="'number'">
          </mat-form-field>
          <mat-form-field class="point-item">
            <mat-label>Max distance</mat-label>
            <input  matInput #maxDistance appOnlyNumber [regexReplace]="'number'">
          </mat-form-field>
          <button mat-raised-button
                (click)="setValues(minDistance.value, maxDistance.value);refresh();"
                [disabled]="isLoaded()">
            <span class="btn-action-wrapper-general" [id]="'PUBLIC_RUN_RESEARCH' | gtmTag">
              Search
            </span>
          </button>
        </div>

      </div>
    </div>
    <div id="publicRunsId"></div>
    <div class="display-all-container" *ngIf="!displayedInfo.all &&
        publicRuns && publicRuns.length > 0">
      <span>
        <button mat-raised-button color="primary" (click)="parameterTable.currentPage = 1; initTable(); displayList(true)">Display all</button>
      </span>
      <span class="point-description">
        Multiple runs for one point
      </span>
    </div>
    <div>
        <app-table #publicRunTable
           [dataSource]="publicRuns"
           [expand]="false"
           [onRowClickSupport]="true"
           [columns]="publicRunTableColumns"
           [totalCount]="totalCount"
           [sorted]="true"
           [pageSize]="parameterTable.pageSize"
           [options]="options"
           [preferences]="preferences"
           [distance]="distance"
           [user]="userPremInformation"
           (join)="joinRun($event)"
           (currentPage)="nextPageList($event)"
           (sortChange)="sortList($event)"
           [runType]="TableTypeEnum.PUBLIC_RUN"
           (view)="displayRunInfo($event)"
           (details)="displayRunInfo($event)"
           *ngIf="publicRuns && publicRuns.length > 0"
        ></app-table>
    </div>
  </div>
</div>
