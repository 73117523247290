<div class="table-container">
  <table mat-table [dataSource]="data" class="run-table-list" matSort (matSortChange)="sortData($event)" #tableSource multiTemplateDataRows>
    <ng-container matColumnDef="{{column.label}}" *ngFor="let column of columns; let i = index" [sticky]="column.isSticky === true">
      <ng-container *ngIf="!column.isAction">
        <th mat-sort-header="{{column.field}}"
            [disabled]="!sorted || column.ignoreSort"
            class="header-name"  mat-header-cell *matHeaderCellDef>

          <span>
            <span class="img-small-circle-container" *ngIf="column.displayImage"></span>
            <span>{{column.name}}</span>
          </span>

        </th>
      </ng-container>
      <ng-container *ngIf="column.isAction">
        <th class="header-name"  mat-header-cell *matHeaderCellDef> {{column.name}} </th>
      </ng-container>
      <th mat-sort-header="{{column.field}}"
          [disabled]="!sorted || column.ignoreSort"
          class="header-name"
          [ngClass]="{'min-width-static': column.initial, 'hide-border': expand }"
          mat-header-cell *matHeaderCellDef>
          <span>
            <span class="img-small-circle-container" *ngIf="column.displayImage"></span>
            <span>{{column.name}}</span>
          </span>
       </th>
      <ng-container *ngIf="!column.isAction">
        <ng-container [ngSwitch]="column.pipe">
          <ng-container *ngSwitchCase="'date'">
            <td mat-cell *matCellDef="let element" [ngClass]="{'min-width-static': column.initial, 'hide-border': expand, 'center': column.center}"> {{element[column.field] | date}}</td>
          </ng-container>
          <ng-container *ngSwitchCase="'kmDistance'">
            <td mat-cell *matCellDef="let element" [ngClass]="{'min-width-static': column.initial, 'hide-border': expand, 'center': column.center}"> {{element[column.field] | kmDistance : distance || 'km'}}</td>
          </ng-container>
          <ng-container *ngSwitchCase="'packID'">
            <td mat-cell *matCellDef="let element" [ngClass]="{'min-width-static': column.initial, 'hide-border': expand, 'center': column.center}"> {{element[column.field] | packID}}</td>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <td mat-cell *matCellDef="let element"
                [ngClass]="{'min-width-static': column.initial, 'hide-border': expand, 'clm-image': column.type === 'image', 'min-width-static-item-content': column.displayImage, 'center': column.center}">
              <ng-container *ngIf="column.type === 'image'">
                <img [src]="element[column.field]" class="img-small-circle" *ngIf="element[column.field]"/>
              </ng-container>
              <ng-container *ngIf="column.type !== 'image'">
                <div class="min-width-static-item-content">
                  <span class="item-content">
                     <span  class="img-small-circle-container" *ngIf="column.displayImage">
                      <img [src]="column.displayedImgLogo(element)"
                           class="img-small-circle" *ngIf="column.displayImage && column.displayedImgLogo(element)"/>
                    </span>
                    <span>
                      {{column.formatter ? column.formatter(element) : element[column.field]}}
                    </span>
                  </span>

                </div>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="column.isAction && !column.isExpand">
        <td mat-cell *matCellDef="let element"
            class="actions-btn-container" [ngClass]="{'ignore-space': ignoreSpaceForAction}">
          <ng-container *ngIf="RunTypeEnum.COMING_UP === runType">
            <button mat-icon-button class="edit-btn"
                    [disabled]="user && element.alphaUserId !== user.id"
                    (click)="editRun(element, $event)"
                     matTooltip="Edit" [id]="'COMING_UP_RUN_EDIT_RUN' | gtmTag">
              <mat-icon [id]="'COMING_UP_RUN_EDIT_RUN' | gtmTag">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteRun(element, $event)"
                    [disabled]="user && element.alphaUserId !== user.id"  matTooltip="Delete"
                    [id]="'COMING_UP_RUN_DELETE_RUN' | gtmTag">
              <mat-icon [id]="'COMING_UP_RUN_DELETE_RUN' | gtmTag">delete</mat-icon>
            </button>
            <button mat-icon-button (click)="rsvpRun(element, $event)" [disabled]="element.isSolo == 1">
              RSVP
            </button>
          </ng-container>
          <ng-container *ngIf="RunTypeEnum.LOGBOOK === runType">
            <button mat-icon-button (click)="startItem(element, $event)" [id]="'LOGBOOK_START_RUN' | gtmTag" matTooltip="Trip again">
              <mat-icon [id]="'LOGBOOK_START_RUN' | gtmTag">directions_run</mat-icon>
            </button>
            <button mat-icon-button (click)="rsvpRun(element, $event)" [disabled]="element.isSolo == 1" >
              RSVP
            </button>
          </ng-container>
          <ng-container *ngIf="RunTypeEnum.PACK === runType">
            <button mat-icon-button class="edit-btn"
                    [disabled]="user && element.alpha && element.alpha.id !== user.id"
                    (click)="editRun(element, $event)"  matTooltip="Edit" [id]="'MYPACK_EDIT_PACK_BTN' | gtmTag">
              <mat-icon [id]="'MYPACK_EDIT_PACK_BTN' | gtmTag">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteRun(element, $event)"
                    [disabled]="user &&
              ((element.alpha && element.alpha.id !== user.id) ||
              (element.organization && !user.isAdmin))"  matTooltip="Delete" [id]="'MYPACK_DELETE_PACK_BTN' | gtmTag">
              <mat-icon [id]="'MYPACK_DELETE_PACK_BTN' | gtmTag">delete</mat-icon>
            </button>
<!--            <button mat-icon-button (click)="viewItem(element, $event)" matTooltip="View">-->
<!--              <mat-icon>visibility</mat-icon>-->
<!--            </button>-->
          </ng-container>
          <ng-container *ngIf="RunTypeEnum.PACK_MEMBER === runType">
            <button mat-icon-button *ngIf="!(options && options.hideOptions &&
                options.hideOptions.hideDeletion &&
                options.hideOptions.hideDeletion(element))"
                    [disabled]="options && options.disableOptions &&
                options.disableOptions.disableDeletion &&
                options.disableOptions.disableDeletion(element)"
                    (click)="deleteRun(element, $event)" matTooltip="Delete">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="RunTypeEnum.PUBLIC_RUN === runType">
<!--            <button mat-icon-button-->
<!--                    (click)="viewItem(element, $event)" matTooltip="View">-->
<!--              <mat-icon>visibility</mat-icon>-->
<!--            </button>-->
            <span [matTooltip]="options.tooltip && options.tooltip(element) || 'Join trip'">
              <button mat-icon-button
                      (click)="joinItem(element, $event)" [disabled]="options && options.disableOptions &&
                    options.disableOptions.disableActivation &&
                    options.disableOptions.disableActivation(element)"
                       [id]="'PUBLIC_RUN_JOIN_RUN' | gtmTag">
              <mat-icon [id]="'PUBLIC_RUN_JOIN_RUN' | gtmTag" >emoji_people</mat-icon>
            </button>
            </span>

          </ng-container>
          <ng-container *ngIf="RunTypeEnum.RUN_LIBRARY === runType">
<!--            <button mat-icon-button-->
<!--                    (click)="viewItem(element, $event)"-->
<!--                    [id]="'LIBRARY_VIEW_RUN' | gtmTag" matTooltip="View">-->
<!--              <mat-icon>visibility</mat-icon>-->
<!--            </button>-->
            <button mat-icon-button
                    (click)="copyItem(element, $event)" [disabled]="options && options.disableOptions &&
                    options.disableOptions.disableActivation &&
                    options.disableOptions.disableActivation(element)"
                     matTooltip="Plan this trip" [id]="'LIBRARY_PLAN_RUN' | gtmTag">
              <mat-icon [id]="'LIBRARY_PLAN_RUN' | gtmTag">library_add</mat-icon>
            </button>
          </ng-container>
          <button mat-icon-button *ngIf="expand">
            <mat-icon *ngIf="element === expandedElement">expand_less</mat-icon>
            <mat-icon *ngIf="element !== expandedElement">expand_more</mat-icon>
          </button>
        </td>
      </ng-container>
    </ng-container>
    <ng-container matColumnDef="expandedDetail" *ngIf="expand">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="maps-preview">
        <div class="example-element-detail"
             [@detailExpand]="expand && element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="run-list">
            <div class="run-img">
              <app-map-view *ngIf="element == expandedElement" #map
                            [waypoints]="waypointsPreparation(element)"
                            [avoidFerries]="element.avoidFerries"
                            [avoidHighways]="element.avoidHighways"
                            [mode]="element.mode"
                            [avoidTolls]="element.avoidTolls"
                            [origin]="{latitude: element.startingPointLatitude, longitude: element.startingPointLongitude}"
                            [destination]="{latitude: element.endingPointLatitude, longitude: element.endingPointLongitude}"
              ></app-map-view>
            </div>
            <div class="run-info">
              <div class="run-header">
                Trip details
              </div>
              <div class="run-info-line">
                <span class="run-item-title">Start point: </span>
                <span>
                {{element.startingPointName}}
              </span>
              </div>
              <div class="run-info-line">
                <span class="run-item-title">End point: </span>
                <span>{{element.endingPointName}}</span>
              </div>
              <div class="run-info-line" *ngIf="!!element[waypointsName] && element[waypointsName].length > 0">
                <span class="run-item-title">Waypoints</span>
                <div>
                  <div *ngFor="let w of element[waypointsName]; let i = index" class="waypoint-item">
                    <span>{{i + 1}}.</span> {{w.title}}
                  </div>
                </div>
              </div>
              <div class="run-info-divider"></div>
              <div>
                <span class="run-item-title" *ngIf="element.alphaFirstName || element.alphaLasttName">Alpha: </span>
                <span>{{element.alphaFirstName}} {{element.alphaLasttName}}</span>
              </div>
              <div *ngIf="element.distance">
                <span class="run-item-title">Planned Distance: </span>
                <span>{{element.distance | kmDistance : distance || 'km'}}</span>
              </div>
              <div *ngIf="element.userDistanceCovered">
                <span class="run-item-title">Distance covered: </span>
                <span>{{element.userDistanceCovered | kmDistance : distance || 'km'}}</span>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <ng-container *ngIf="expand">
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === row"
          (click)="expandedElement = expandedElement === row ? null : row; displayMap(row)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </ng-container>
    <ng-container *ngIf="!expand">
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-detail-row" (click)="displayDetails(row, $event)"></tr>
    </ng-container>
  </table>
</div>
<mat-paginator [length]="totalCount"
               [pageSizeOptions]="[5, 10, 25, 100]"
               [pageSize]="pageSize" (page)="changePage($event)">
</mat-paginator>
