import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class PremiumAccountService {

  constructor(private apiService: ApiService) {}

  public paypalInit(data) {
    return this.apiService.post('payment/paypal/start', {
      data: data
    });
  }

}
