<div class="popup-header">
  Add Pack Members
</div>
<div>
  <div class="send-wolfpack-id">
    <mat-form-field class="details-item">
      <mat-label>ID or email</mat-label>
      <input matInput [regexReplace]="'email'" [(ngModel)]="searchWolfpackMember">
    </mat-form-field>
    <button mat-raised-button color="primary"
            (click)="sendInvitation()"
            class="send-invitation-btn" [disabled]="loading ||
            !searchWolfpackMember || !isValidSearchText()">
      <mat-icon>send</mat-icon>
    </button>
  </div>
  <div class="invalid-email-error" *ngIf="!!searchWolfpackMember && !idWPId() && !isValidEmail()">
    Invalid email
  </div>
</div>
<div class="pack-member-container">
  <div class="new-friends">
    <div class="column-header">Friends</div>
    <div *ngIf="friends && friends.length > 0">
      <span>
        <mat-checkbox [(ngModel)]="friendsAll" [disabled]="loading"
                      (ngModelChange)="selectAll(friendsAll, friends, true)">
                <span class="column-title">Name</span>
        </mat-checkbox>
      </span>
    </div>
    <div class="friends-container">
      <div *ngFor="let friend of friends" class="friend-user">
      <span>
         <mat-checkbox [(ngModel)]="friend.selected"
                       (ngModelChange)="checkSelection()"
                       [disabled]="loading">
           <div class="user-wrapper">
             <img [src]="friend.pictureUrl" class="picture-url"
                      onerror="this.src='assets/images/default-user.png';"/> <!--this.style.display = 'none';  -->
              <ng-container *ngIf="friend.firstName">
                 <span>{{friend.firstName}} {{friend.lastName}}</span>
             </ng-container>
              <ng-container *ngIf="!friend.firstName">
                 <span>{{friend.username}}</span>
             </ng-container>
           </div>
         </mat-checkbox>
      </span>
      </div>
    </div>
  </div>
  <div class="arrows-btn">
    <button mat-raised-button
            class="arrow-btn"
            (click)="moveToMembers()"
            [disabled]="loading">
      <mat-icon class="change-column-btn">keyboard_arrow_right</mat-icon>
    </button>
    <button mat-raised-button class="arrow-btn"
            (click)="removeFromMembers()" [disabled]="loading">
      <mat-icon class="change-column-btn">keyboard_arrow_left</mat-icon>
    </button>
  </div>
  <div class="to-be-added-friends">
    <div class="column-header">To be added</div>
    <div *ngIf="toBeAdded && toBeAdded.length > 0">
      <span>
        <mat-checkbox
          [(ngModel)]="toBeAddedAll"
          [disabled]="loading"
          (ngModelChange)="selectAll(toBeAddedAll, toBeAdded, true)">
            <span class="column-title">Name</span>
        </mat-checkbox>
      </span>
    </div>
    <div class="friends-container">
      <div *ngFor="let friend of toBeAdded" class="friend-user">
      <span>
         <mat-checkbox [(ngModel)]="friend.selected"
                       (ngModelChange)="checkSelection()"
                       [disabled]="loading">
             <div class="user-wrapper">
               <img [src]="friend.pictureUrl" class="picture-url"
                    onerror="this.src='assets/images/default-user.png';"/> <!--this.style.display = 'none'; -->
               <ng-container *ngIf="friend.firstName">
                   <span>{{friend.firstName}} {{friend.lastName}}</span>
               </ng-container>
                <ng-container *ngIf="!friend.firstName">
                   <span>{{friend.username}}</span>
               </ng-container>
             </div>
         </mat-checkbox>
      </span>
      </div>
    </div>
  </div>
</div>
<div class="actions-table">
  <div class="actions-table-wrapper">
    <button mat-raised-button color="primary" type="submit"
            [disabled]="loading" (click)="saveMembers()">Save</button>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
  </div>
  <div class="progress-bar-loading">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
