import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DialogPackComponent } from './dialog-pack.component';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CoreModule} from '../../../../core/core.module';

@NgModule({
  declarations: [
    DialogPackComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    FormsModule,
    DragDropModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [DialogPackComponent]
})
export class DialogPackModule { }
