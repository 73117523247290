<div>
  <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
</div>
<div class="table-description-item"></div>
<div class="filter-container">
  <div>
    <div>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          [(ngModel)]="filter.name"
          [regexReplace]="'name'" titleCase>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="point-item">
        <mat-label>Mode</mat-label>
        <mat-select [(ngModel)]="filter.mode"
                    disableOptionCentering>
          <mat-option [value]="''">All</mat-option>
          <mat-option *ngFor="let mode of modes"
                      [value]="mode.id">
            {{mode.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="point-item">
        <mat-label>Pack</mat-label>
        <mat-select [(ngModel)]="filter.packId"
                    disableOptionCentering>
          <mat-option [value]="''"></mat-option>
          <mat-option *ngFor="let pack of packs"
                      [value]="pack.id">
            {{pack.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="filter.startDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div>
    <div>
      <mat-slide-toggle [(ngModel)]="filter.capturedPointExisted">
        Saved waypoints available
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle [(ngModel)]="filter.participated">
        Show all trips (included unattended)
      </mat-slide-toggle>
    </div>
  </div>
  <div>
    <button mat-raised-button (click)="filterPage()" [disabled]="loading">
      Apply
    </button>
  </div>
</div>
<app-table  #runTable
  (rsvp)="rsvp($event)"
            (start)="start($event)"
               [dataSource]="comingUpRuns"
               [totalCount]="totalCount"
                [distance]="distance"
                [preferences]="preferences"
               [runType]="RunTypeEnum.LOGBOOK"
   (currentPage)="nextPageList($event)"
   (sortChange)="sortList($event)"
                *ngIf="comingUpRuns && comingUpRuns.length > 0"
   ></app-table>
