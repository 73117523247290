import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PublicRunsComponent } from './public-runs.component';
import { SharedModule } from '../../../shared/shared.module';
import { PublicRunsService } from './public-runs.service';
import { FormsModule } from '@angular/forms';
import { PublicRunDetailsModule } from './public-run-details/public-run-details.module';
import { MultiplePublicRunsModule } from './multiple-public-runs/multiple-public-runs.module';
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    PublicRunsComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        FormsModule,
        PublicRunDetailsModule,
        MultiplePublicRunsModule,
        CoreModule
    ],
  providers: [
    PublicRunsService
  ],
  bootstrap: [PublicRunsComponent]
})
export class PublicRunsModule { }
