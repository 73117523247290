import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CommonService {

  constructor() { }

  public base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  public displayDate(timestamp) {
    return new Date(timestamp).toLocaleDateString('en-US');
  }

  public distanceMapper(v) {
    switch (+v) {
      case 2: {
        return 'mi';
      }
      case 3: {
        return 'km';
      }
      default: {
        return 'km';
      }
    }
  }

  public getStartZero(value) {
    return +value > 0 && +value < 10 ? '0' + (+value) : value + '';
  }

  generateUniqueString() {
    const random = () => Math.random().toString(36).substr(2);
    return random() + random() + random();
  }


}
