import { NgModule } from '@angular/core';
import { DialogConfirmationComponent } from './dialog-confirmation.component';
import { MaterialModule } from '../../external-modules/material.module';

@NgModule({
  declarations: [
    DialogConfirmationComponent
  ],
  imports: [
    MaterialModule
  ],
  providers: [],
  bootstrap: [DialogConfirmationComponent]
})
export class DialogConfirmationModule { }

