import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RunLibraryDetailsComponent } from './run-library-details.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { FormsModule } from "@angular/forms";
import { CoreModule } from "../../../../../core/core.module";
import { RunLibraryService } from "../run-library.service";

@NgModule({
  declarations: [
    RunLibraryDetailsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    CoreModule,
  ],
  providers: [
    RunLibraryService
  ],
  bootstrap: [RunLibraryDetailsComponent]
})
export class RunLibraryDetailsModule { }
