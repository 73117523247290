
<div class="restore-container">
  <mat-card class="restore-card">
    <mat-card-title>{{!isResetFinished ? 'Type new password' : 'Password was updated'}}</mat-card-title>
    <mat-card-content>
      <div class="restore-img-logo restore-logo">
        <img src="../../../../assets/images/wolf-logo.png"/>
      </div>
      <form [formGroup]="restoreForm" (ngSubmit)="restore(formDirective)" #formDirective="ngForm" *ngIf="!isResetFinished">
        <ng-container >
          <div class="restore-inputs">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput formControlName="password"
                     [regexReplace]="'password'"
                     type="password">
            </mat-form-field>
            <div *ngIf="!password?.valid && password.touched && password.errors.required" class="error-message">
              Required
            </div>
            <div *ngIf="!password?.valid && password.touched && password.errors.minlength" class="error-message">
              Min length is {{password.errors.minlength.requiredLength}} symbols
            </div>
            <div *ngIf="!password?.valid && password.touched && password.errors.mustMatch" class="error-message">
              Passwords are not the same
            </div>
          </div>
          <div class="restore-inputs">
            <mat-form-field>
              <mat-label>New password</mat-label>
              <input matInput formControlName="confirmPassword"
                     [regexReplace]="'password'"
                     type="password">
            </mat-form-field>
            <div *ngIf="!confirmPassword?.valid && confirmPassword.touched && confirmPassword.errors.required" class="error-message">
              Required
            </div>
            <div *ngIf="!confirmPassword?.valid && confirmPassword.touched && confirmPassword.errors.minlength" class="error-message">
              Min length is {{confirmPassword.errors.minlength.requiredLength}} symbols
            </div>
            <div *ngIf="!confirmPassword?.valid && confirmPassword.touched && confirmPassword.errors.mustMatch" class="error-message">
              Passwords are not the same
            </div>
          </div>
        </ng-container>
        <mat-card-footer>
          <div class="restore-btn-grp">
            <button mat-raised-button class="btn-item btn-item-general green-primary" type="submit"
               [disabled]="restoreForm.invalid || loading">Change password</button>
          </div>
          <div class="restore-request" *ngIf="loading">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
        </mat-card-footer>
      </form>
      <div class="restore-links" *ngIf="isResetFinished">
        <div class="restore-link primary-link" (click)="goToSignInPage()">
          Login to your account
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
