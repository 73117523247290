<div>
  <div>
    <div class="header {{data.type}}"></div>
    <div class="img-container">
      <image-cropper
        [imageFile]="image"
        [maintainAspectRatio]="true"
        [aspectRatio]="4 / 3"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </div>
</div>
<div class="btn-submit-container">
  <button mat-button class="btn-submit" (click)="saveResultImage()">Ok</button>
  <button mat-button class="btn-submit" mat-dialog-close="true">Cancel</button>
</div>

