import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TranslationService {

    private data: any = {};

    constructor(private http: HttpClient) {}

    use(lang: string): Promise<{}> {
        return new Promise<{}>(resolve => {
            const langPath = `assets/i18n/${lang || 'en'}/server.json`;
            forkJoin([
                this.http.get(langPath),
            ])
            .subscribe(
                response => {
                    this.data = {
                        server: response[0],
                    };
                    resolve(this.data);
                },
                err => {
                    this.data = {};
                    resolve(this.data);
                }
            );
        });
    }

    public translate(key: string, addDefaultValue = false, searchPlace = 'server') {
        if (addDefaultValue) {
            if (searchPlace) {
                return this.data[searchPlace] ? this.data[searchPlace][key] || key : key;
            }
            return this.data[key] || key
        }
        if (searchPlace) {
            return this.data[searchPlace] ? this.data[searchPlace][key] : undefined;
        }
        return this.data[key];
    }
}
