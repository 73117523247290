import {Directive, ElementRef, HostListener, Input, OnInit, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
@Directive({
  selector: '[regexReplace]'
})
export class RegexReplaceDirective implements OnInit {
  @Input('regexReplace')
  public regexReplace;
  private regexStr;
  private regexStrReplaced;
  constructor(private el: ElementRef,
              @Optional() @Self()
              private control: NgControl) {}
  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    const regex = new RegExp(this.regexStr).test(event.key);
    return regex;
  }
  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(this.regexStrReplaced, '');
    this.el.nativeElement.value = pasteData;
    if (this.control && this.control.control) {
      this.control.control.setValue(pasteData);
    }
  }

  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace(this.regexStrReplaced, '');
    document.execCommand('insertHTML', false, pasteData);
  }

  getRegex() {
    switch (this.regexReplace) {
      case 'number': {
        return /^[0-9]*$/gu;
      }
      case 'text': {
        return /^[\p{L}\d\s?!.,_\-+@#$%^&*()]*$/gu;
      }
      case 'text_punctuation': {
        return /^[\p{L}\d\s?!.,_\-+@#$%^&*()\p{P}]*$/gu;
      }
      case 'name': {
        return /^[\p{L}\d\-\s!@\\'\\+#$%^&*()_]*$/gu;
      }
      case 'name_punctuation': {
        return /^[\p{L}\d\-\s!@\\'\\+#$%^&*()_\p{P}]*$/gu;
      }
      case 'email': {
        return /^[a-zA-Z0-9_@.+]*$/gu;
      }
      case 'url': {
        return /^[a-zA-Z0-9#_.\/\\:-]*$/gu;
      }
      case 'password': {
        return /^[\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]*$/gu;
      }
      case 'emoji': {
        return /^[\p{L}\d\s\p{Emoji_Modifier_Base}\p{Emoji_Modifier}\p{Emoji_Presentation}\p{Emoji}?!.+,_@#$%^&*()\-\uFE0F\p{P}]*$/gu;
      }
    }
  }

  getReplaceRegex() {
    switch (this.regexReplace) {
      case 'number': {
        return /[^0-9]/gu;
      }
      case 'text': {
        return /[^\p{L}\d_\-?!.,+@#$%^&*()\s]/gu;
      }
      case 'text_punctuation': {
        return /^[\p{L}\d\s?!.,_\-+@#$%^&*()\p{P}]*$/gu;
      }
      case 'name': {
        return /[^\p{L}\d\-\s!@#\\'\\+$%^&*()_]/gu;
      }
      case 'name_punctuation': {
        return /^[\p{L}\d\-\s!@\\'\\+#$%^&*()_\p{P}]*$/gu;
      }
      case 'email': {
        return /[^a-zA-Z0-9@_.\-+]+/gu;
      }
      case 'url': {
        return /[^a-zA-Z0-9#_.\/\\:-]/gu;
      }
      case 'password': {
        return /[^\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]/gu;
      }
      case 'emoji': {
        return /[^\p{L}\d\s\p{Emoji_Modifier_Base}\p{Emoji_Modifier}\p{Emoji_Presentation}\p{Emoji}?!.+,_@#$%^&*()\-\uFE0F\p{P}]/gu;
      }
    }
  }

  ngOnInit(): void {
    this.regexStr = this.getRegex();
    this.regexStrReplaced = this.getReplaceRegex();
  }
}
