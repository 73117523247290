import { Injectable } from '@angular/core';
import ApiService from '../../core/services/api.service';

@Injectable()
export class FeedbackService {

  constructor(private apiService: ApiService) {}

  public pushFeedback(data) {
    return this.apiService.post('users/feedback/add', {
      data
    });
  }

  public feedbackCategories() {
    return this.apiService.post('users/feedback/get/categories', {
      data: {}
    });
  }

}

