import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class ProfileService {

  constructor(private apiService: ApiService) {}

  public getCountries() {
    return this.apiService.post('util/get/all/countries', {
      data: {},
      pageSize: 1000,
      sort:[{field:"name", desc: false}],
    });
  }

  public getStates() {
    return this.apiService.post('util/get/all/us/states', {
      data: {},
      pageSize: 1000,
      sort:[{field:"name", desc: false}],
    });
  }

  public getUserProfile() {
    return this.apiService.post('user/auth/check', {
      data: {}
    });
  }

  public getSubscriptionVendorInfo(subscriptionId) {
    return this.apiService.post('payment/subscription/vendor', {
      data: {
        id: subscriptionId
      }
    });
  }

  public updateUser(user) {
    return this.apiService.post('user/edit', {
      data: user
    });
  }

  public uploadImage(image) {
    const formData: FormData = new FormData();
    formData.append('file', image);
    return this.apiService.post('user/edit/profile-image', formData);
  }

  // public updatePassword(data) {
  //   return this.apiService.post('user/password/change', {
  //     data: data
  //   });
  // }

  public updatePassword(data) {
    return this.apiService.post('user/password/change/by/user/token', {
      data: data
    });
  }



  public updatePreferences(data) {
    return this.apiService.post('user/preferences/edit', {
      data: data
    });
  }

  public getPreferences() {
    return this.apiService.post('user/preferences/get', {
    });
  }

  public getCharities() {
    return this.apiService.post('charity/organisations', {});
  }
}
