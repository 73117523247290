<div>
  <mat-progress-bar *ngIf="isLoaded()" mode="query"></mat-progress-bar>
</div>
<app-main-page-title [title]="'Trip library'"></app-main-page-title>
<div class="countries-form">
  <div class="form-header">
    Change Location
  </div>
  <div>
    <div class="settings-part">
      <div class="country-filters">
        <mat-form-field class="point-item">
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="filterList.country"
                      (ngModelChange)="parameterTable.currentPage = 1; minDistance.value=''; maxDistance.value = ''; setValues('', ''); initTable(); updateStateInfo(); displayList()"
                      [disabled]="isLoaded()" disableOptionCentering>
            <mat-option [value]="'ALL'">All</mat-option>
            <mat-option *ngFor="let country of countries"
                        [value]="country.code">
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="point-item">
          <mat-label>State</mat-label>
          <mat-select disableOptionCentering [(ngModel)]="filterList.state"
                      [disabled]="filterList.country !== 'US' || isLoaded()"
                      (ngModelChange)="parameterTable.currentPage = 1; minDistance.value=''; maxDistance.value = ''; setValues('', ''); initTable();  displayList()">
            <mat-option></mat-option>
            <mat-option *ngFor="let state of states"
                        [value]="state.code">
              {{state.name}} {{state.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="distances">
        <mat-form-field class="point-item">
          <mat-label>Min distance</mat-label>
          <input [regexReplace]="'number'" matInput #minDistance appOnlyNumber>
        </mat-form-field>
        <mat-form-field class="point-item">
          <mat-label>Max distance</mat-label>
          <input [regexReplace]="'number'" matInput #maxDistance appOnlyNumber>
        </mat-form-field>
        <button mat-raised-button
                (click)="setValues(minDistance.value, maxDistance.value);displayList(true);"
                [disabled]="isLoaded()">
          <span class="btn-action-wrapper-general" [id]="'LIBRARY_RUN_RESEARCH' | gtmTag">
            Search
          </span>
        </button>
      </div>
    </div>
    <div id="publicRunsId">

    </div>
    <div class="display-all-container" *ngIf="!displayedInfo.all &&
        runLibraries && runLibraries.length > 0">
      <span>
        <button mat-raised-button color="primary" (click)="parameterTable.currentPage = 1; initTable(); displayList(true)">Display all</button>
      </span>
      <span class="point-description">
        Multiple runs for one point
      </span>
    </div>
    <div>
        <app-table #publicRunTable
           [dataSource]="runLibraries"
           [expand]="false"
           [distance]="distance"
           [columns]="runLibraryTableColumns"
           [totalCount]="totalCount"
           [pageSize]="parameterTable.pageSize"
           [options]="options"
           [user]="userPremInformation"
           (duplication)="copyItem($event)"
           (currentPage)="nextPageList($event)"
           (sortChange)="sortList($event)"
           [runType]="TableTypeEnum.RUN_LIBRARY"
           (view)="displayRunInfo($event)"
           (details)="displayRunInfo($event)"
           [onRowClickSupport]="true"
           *ngIf="runLibraries && runLibraries.length > 0"
        ></app-table>
    </div>

  </div>
</div>
