<div>
  <div>
    <div class="header">{{data.type}}</div>
    <div class="message">
      {{data.message}}
    </div>
  </div>
</div>
<div class="btn-submit-container">
  <button mat-button class="btn-submit" (click)="result(true)">{{data.okResponse || 'Ok'}}</button>
  <button mat-button class="btn-submit" (click)="result(false)">{{data.cancelResponse || 'Cancel'}}</button>
</div>

