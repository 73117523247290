import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SignInQrComponent } from './sign-in-qr.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {SignInService} from '../sign-in.service';

@NgModule({
  declarations: [
    SignInQrComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    SignInService
  ],
  bootstrap: [SignInQrComponent]
})
export class SignInQrModule { }
