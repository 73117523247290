<div>
  <div class="map-view">
    <div class="run-view">
      <app-map-view *ngIf="displayedMap" #map
                    [waypoints]="waypointsPreparation(run)"
                    [avoidFerries]="run.avoidFerries"
                    [avoidHighways]="run.avoidHighways"
                    [avoidTolls]="run.avoidTolls"
                    [origin]="{latitude: run.startingPointLatitude, longitude: run.startingPointLongitude}"
                    [destination]="{latitude: run.endingPointLatitude, longitude: run.endingPointLongitude}"
      ></app-map-view>
    </div>
    <div>
      <div class="run-info">
        <div class="run-header">
          Trip details
        </div>
        <div class="run-info-line">
          <span class="run-item-title">Start point: </span>
          <span>
                {{run.startingPointName}}
              </span>
        </div>
        <div class="run-info-line">
          <span class="run-item-title">End point: </span>
          <span>{{run.endingPointName}}</span>
        </div>
        <div class="run-info-line" *ngIf="run.waypoints && run.waypoints.length > 0">
          <span class="run-item-title">Waypoints</span>
          <div class="waypoint-item-list">
            <div *ngFor="let w of run.waypoints; let i = index" class="waypoint-item">
              <span>{{i + 1}}.</span> {{w.title}}
            </div>
          </div>
        </div>
        <div class="run-info-divider"></div>
        <div>
          <span class="run-item-title">Alpha: </span>
          <span>{{run.alphaFirstName}} {{run.alphaLasttName}}</span>
        </div>
        <div>
          <span class="run-item-title">Planned Distance: </span>
          <span>{{run.distance | kmDistance : distance || 'km'}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="actions-table">
    <div class="actions-table-wrapper">
      <button mat-raised-button type="button"
              (click)="cancel()" mat-dialog-close="true" class="cancel-btn">Cancel</button>
      <button mat-raised-button color="primary" type="submit"
              [disabled]="loading" (click)="join()" *ngIf="!isJoined">Join</button>

      <div class="error-display-information">
        {{errorNotification}}
      </div>
    </div>
  </div>
  <div class="progress-bar-loading">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
