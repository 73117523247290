import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import ApiService from './api.service';
import {map} from "rxjs/operators";

@Injectable()
export class AppService {

  public paymentTypes = [];

  constructor(private appStorageService: AppStorageService,
              private apiService: ApiService) { }

  public getPaymentTypes() {
    return this.apiService.post('payment/payment-types/get', {
      data: {
        type: '1'
      },
    }).pipe(map(data => {
      if (data.result && data.result.data) {
        this.paymentTypes = data.result.data || [];
      }
      return data;
    }));
  }

}
