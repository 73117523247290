import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject, NgZone, OnDestroy,
  OnInit,
  QueryList, Renderer2,
  ViewChild, ViewChildren
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, FormControl, Validators} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import * as moment from 'moment';
import {ThemePalette} from "@angular/material/core";
import {BaseComponent} from "../../../../shared/classes/base.component";
import {DialogMessageComponent} from "../../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../../enums/message-type.enum";
import {MapEnum} from "../../../../enums/map.enum";
import { METERS_TO_MILES, PUBLIC_RADIUS} from "../../../../consts/distances";
import {DOCUMENT} from "@angular/common";
import { LETTERS } from 'src/app/consts/symbols';
import {PremiumAccountComponent} from "../../../../shared/components/premium-account/premium-account.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {DialogWaypointComponent} from "../dialog-waypoint/dialog-waypoint.component";
import { PointTypeEnum } from 'src/app/enums/point-type.enum';
declare const google: any;

@Component({
  selector: 'app-user-dialog-run',
  templateUrl: './dialog-run.component.html',
  styleUrls: ['./dialog-run.component.scss']
})
export class DialogRunComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  isPathValid = false;

  public map: google.maps.Map;

  public initMapDraw = 0;

  public currentPage = 0;

  @ViewChild('runMapId')
  public runMapId: ElementRef;

  @ViewChild('startingPointInput')
  public startingPointInput: ElementRef;

  @ViewChild('endingPointInput')
  public endingPointInput: ElementRef;

  @ViewChild('startPointIcon')
  public startPointIcon: ElementRef;
  @ViewChild('endPointIcon')
  public endPointIcon: ElementRef;
  public runForm;

  public pointDetails: any = {
    start: {},
    destination: {}
  };
  public PointTypeEnum = PointTypeEnum;

  public errorNotification = '';

  public runMapParameters = {
    startMarker: undefined,
    startCoordinates: undefined,
    startCity: undefined,
    startCountry: undefined,
    startState: undefined,
    endMarker: undefined,
    endCoordinates: undefined,
    endCity: undefined,
    endCountry: undefined,
    endState: undefined,
    distance: 0,
    duration: 0
  }
  public tripDescriptionMaxLength = 1000;
  public approximateTime = '';

  public directionsService: google.maps.DirectionsService;
  public directionsDisplay;
  public packs = [];
  public duration = 0;
  public loading: boolean = false;

  public packSelectorPreList = [
    {
      id: 'NO_ID',
      name: 'No Pack'
    },
    {
      id: 'SOLO',
      name: 'SOLO RIDE'
    },
    {
      id: 'NONE',
      name: 'PUBLIC RUN'
    }
  ]

  public disabled = false;
  public showSeconds = false;
  public hideTime = true;
  public touchUi = false;
  public enableMeridian = false;
  public minDate = new Date();
  public maxDate = new Date();
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public waypoints = [];
  public waypointsDetails = [];

  public isPremium = false;

  public get waypointsListArray() {
    return this.runForm.get('waypoints') as UntypedFormArray;
  }

  @ViewChildren('wpoint')
  public wpointElems: QueryList<ElementRef>;

  public LETTERS = LETTERS;

  private isValidWP = true;

  public isCopy = false;
  public user;

  pointCategories = [];

  public constructor(private builder: UntypedFormBuilder,
                     public dialogRef: MatDialogRef<DialogRunComponent>,
                     public dialog: MatDialog,
                     @Inject(MAT_DIALOG_DATA)
                     public data: any,
                     @Inject(DOCUMENT) document,
                     private compFactoryResolver: ComponentFactoryResolver,
                     private renderer: Renderer2,
                     private zone: NgZone,
                     public snackBar: MatSnackBar
                     ) {
    super(dialog, snackBar);
  }

  ngOnDestroy(): void {
    google.maps.event.clearListeners(this.map, 'bounds_changed');
    google.maps.event.clearListeners(this.map, 'resize');
    google.maps.event.clearListeners(this.directionsService, 'directions_changed');
    this.map = null;
  }

  ngOnInit(): void {
    this.isPremium = this.data.isPremium;
    this.isCopy = !!this.data.isCopy;
    this.user = this.data.user;
    this.initForm(this.data.run);
    this.getPacks();
    this.initPointDetailsLoading();
    // this.centerMap();
  }

  initPointDetailsLoading() {
    if (this.data.getPointCategories) {
      this.pointCategories = [];
      this.data.getPointCategories([], t => {
        this.pointCategories = this.pointCategories.concat(t.result.data || []);
      }, () => {});
    }
  }

  public isPackNotFilled() {
    return this.runForm.get('packId').value === 'NO_ID';
  }

  public displayMinStartDate() {
    return this.data.run ? new Date(this.data.run.startDate) || new Date() : new Date();
  }

  public displayMinEndDate() {
    return this.data.run ? new Date(this.data.run.endDate) || new Date() : new Date();
  }

  public dropWaypointList(event) {
    this.dropWaypoint(event, this.waypointsListArray);
    this.dropWaypoint(event, this.waypoints);
    this.dropWaypoint(event, this.waypointsDetails);
    this.displayRoute(this.runMapParameters.startCoordinates,
      this.runMapParameters.endCoordinates,
      this.directionsService, this.directionsDisplay, true);
  }

  public dropWaypoint(event, waypointsListArray) {
    const itempreviousIndex = waypointsListArray[ event.previousIndex];
    const itemcurrentIndex = waypointsListArray[event.currentIndex];
    waypointsListArray[event.currentIndex] = itempreviousIndex;
    waypointsListArray[ event.previousIndex] = itemcurrentIndex;
  }

  private updateMapPoints() {
    if (this.data.run) {

      this.runMapParameters.startMarker = new google.maps.Marker({
        position: {lat: this.data.run.startingPointLatitude,
          lng: this.data.run.startingPointLongitude},
        title: ''
      });

      this.runMapParameters.endMarker = new google.maps.Marker({
        position: {lat: this.data.run.endingPointLatitude,
          lng: this.data.run.endingPointLongitude},
        title: ''
      });

      this.runMapParameters.startCoordinates =
        new google.maps.LatLng(this.data.run.startingPointLatitude,
          this.data.run.startingPointLongitude);
      this.runMapParameters.startMarker.setMap(this.map);

      this.runMapParameters.endCoordinates =
        new google.maps.LatLng(this.data.run.endingPointLatitude,
          this.data.run.endingPointLongitude);
      this.runMapParameters.endMarker.setMap(this.map);
      this.displayRoute(this.runMapParameters.startCoordinates,
        this.runMapParameters.endCoordinates,
        this.directionsService, this.directionsDisplay, false);

    }

  }

  private checkFilledWaypoints() {
    return this.waypoints.every(y => {
      return y.location;
    })
  }

  public addWaypoint() {
    if (!this.isPremium) {
      this.displayDialog(PremiumAccountComponent, {},
        () => {}, () => {});
      return;
    }
    if (!this.runForm.controls.startingPointName.value ||
      !this.runForm.controls.endingPointName.value) {
      this.displayDialog(DialogMessageComponent, {
          type: MessageTypeEnum.ERROR,
          message: 'Need to set start and end points',
          explanation: 'Need to set start and end points'
        },
        () => {},
        () => {});
      return;
    }
    const isReadyForNew = this.checkFilledWaypoints();
    if (isReadyForNew) {
      this.createOrUpdateWaypointWithID(this.waypoints.length + 1);
    } else {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.ERROR,
        message: 'Need to set all waypoints',
        explanation: 'Need to set all waypoints'
      },
      () => {},
      () => {});
    }
  }

  private createOrUpdateWaypointWithID(waypointId, location?, name?) {
    this.endPointIcon.nativeElement.innerHTML = LETTERS.charAt(waypointId + 1);
    if ((waypointId > this.waypoints.length) || this.waypoints.length == 0) {
      this.waypoints.push({});
      this.waypointsListArray.insert(waypointId - 1, this.builder.control(''));
      this.waypointsDetails.push({});
    } else {
      if (this.waypointsListArray.at(waypointId - 1)) {
        this.waypointsListArray.at(waypointId - 1).setValue( name || '');
        // if (location) {
        //   this.waypoints[waypointId - 1] = {
        //     location: location
        //   };
        // }
      } else {
        this.waypointsListArray.insert(waypointId - 1,
        this.builder.control({value:name, disabled: this.isWPExistsAndBasicEditMode()}));
        this.waypoints[waypointId - 1] = {
          location: location
        };
        if (!this.waypointsDetails[waypointId - 1]) {
          this.waypointsDetails[waypointId - 1] = {};
        }
      }
    }
    this.resize();
  }

  public checkWaypointStatus(elem, $event) {
    const item = document.getElementById('wp-' + $event);
    if (item) {
      const waypointAutocomplete = new google.maps.places.Autocomplete(item);
      google.maps.event.addListener(waypointAutocomplete,
        'place_changed', () => {
        this.resize();
        const place = waypointAutocomplete.getPlace();
        const location = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        this.waypoints[$event] = { location: location.lat().toFixed(4) + ',' + location.lng().toFixed(4)};
        this.displayRoute(this.runMapParameters.startCoordinates,
          this.runMapParameters.endCoordinates,
          this.directionsService,
          this.directionsDisplay, true);
      });
    }
  }

  public detailsWaypoint(index) {
    const waypoint = this.waypointsDetails[index];
    this.displayDialog(DialogWaypointComponent,
    {
      waypoint: Object.assign(waypoint || {}),
      readOnly: !this.isPremium,
      pointCategories: this.pointCategories
    },
    (response) => {
      if (response) {
        this.waypointsDetails[index].url = response.url;
        this.waypointsDetails[index].description = response.description;
        this.waypointsDetails[index].name = response.name;
        this.waypointsDetails[index].pictureUrl = response.pictureUrl;
        this.waypointsDetails[index].pointCategoryId = response.pointCategoryId;
      }
    }, () => {});
  }

  public isWpInfoExist(i) {
    return this.waypointsDetails[i] && (!!this.waypointsDetails[i].name ||
      !!this.waypointsDetails[i].description ||
      !!this.waypointsDetails[i].url ||
      !!this.waypointsDetails[i].pictureUrl ||
      !!this.waypointsDetails[i].pointCategoryId
    );
  }

  public removeWaypoint(index) {
    this.waypointsListArray.removeAt(index);
    this.waypoints.splice(index, 1);
    this.waypointsDetails.splice(index, 1);
    this.displayRoute(this.runMapParameters.startCoordinates,
      this.runMapParameters.endCoordinates,
      this.directionsService, this.directionsDisplay, true);
  }

  private getPacks() {
    this.packs = this.packSelectorPreList;
    this.loading = true;
    this.data.getPacks([], t => {
      this.packs = this.packs.concat(t.result.data || []);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  private initForm(run?) {
    this.runForm = this.builder.group({
      name: [{value: run ? run.name + (this.isCopy ? 'Copy # 1' : '') : '', disabled: this.isWPExistsAndBasicEditMode()}, [Validators.required]],
      isProtected: [{value: run ? (this.isCopy ? false : run.isProtected) : false, disabled: this.isWPExistsAndBasicEditMode()}],
      isPublic: [{value: run ? (this.isCopy ? false : run.isPublic) : false, disabled: this.isWPExistsAndBasicEditMode()}],
      isSolo: [{value: run ? (this.isCopy ? false : run.isSolo) : false, disabled: this.isWPExistsAndBasicEditMode()}],
      startingPointName: [{value: run ? run.startingPointName : '', disabled: this.isWPExistsAndBasicEditMode()}, [Validators.required]],
      endingPointName: [{value: run ? run.endingPointName : '', disabled: this.isWPExistsAndBasicEditMode()}, [Validators.required]],
      avoidHighways: [{value: run ? (+run.avoidHighways == 1) : false, disabled: this.isWPExistsAndBasicEditMode()}],
      avoidTolls: [{value: run ? (+run.avoidTolls == 1) : false, disabled: this.isWPExistsAndBasicEditMode()}],
      avoidFerries: [{value: run ? (+run.avoidFerries == 1) : false, disabled: this.isWPExistsAndBasicEditMode()}],
      startDate: [{value: run && !this.isCopy ? moment(new Date(run.startDate)) : '', disabled: this.isWPExistsAndBasicEditMode()},
        [Validators.required]],
      endDate: [{value: run && !this.isCopy  ? moment(new Date(run.endDate)) : '', disabled: this.isWPExistsAndBasicEditMode()},
        [Validators.required]],
      note: [{value:run ? run.note : '', disabled: this.isWPExistsAndBasicEditMode()}],
      startingPointLongitude: [{value: run ? run.startingPointLongitude : '', disabled: this.isWPExistsAndBasicEditMode()}],
      startingPointLatitude: [{value:run ? run.startingPointLatitude : '', disabled: this.isWPExistsAndBasicEditMode()}],
      endingPointLongitude: [{value: run ? run.endingPointLongitude : '', disabled: this.isWPExistsAndBasicEditMode()}],
      endingPointLatitude: [{value: run ? run.endingPointLatitude : '', disabled: this.isWPExistsAndBasicEditMode()}],
      startCountry: [{value: run ? run.startCountry : '', disabled: this.isWPExistsAndBasicEditMode()}],
      endCountry: [{value: run ? run.endCountry :'', disabled: this.isWPExistsAndBasicEditMode()}],
      startState: [{value:run ? run.startState : '', disabled: this.isWPExistsAndBasicEditMode()}],
      endState: [{value: run ? run.endState : '', disabled: this.isWPExistsAndBasicEditMode()}],
      startCity: [{value: run ? run.startCity : '', disabled: this.isWPExistsAndBasicEditMode()}],
      endCity: [{value: run ? run.endCity : '', disabled: this.isWPExistsAndBasicEditMode()}],
      packId: [{value: run ? (this.isCopy ? 'NO_ID' : run.packId) : 'NO_ID',
        disabled: this.isCopy ? false : (!(!run || (run && (!!run.isSolo || (!run.isPublic && !run.packId)))) ||
          this.isWPExistsAndBasicEditMode())}, [Validators.required]],
      waypoints: this.builder.array([]),
    });
    this.updateRequiredParameters();
    this.userPreferences(run);
    this.setPointDetails(run);
    this.packParameters(run);
    this.resetFieldsForCopy();
  }

  private setPointDetails(run) {
    this.pointDetails = {
      start: run && run.pointDetails ?
        run.pointDetails.find(item => item.type.toLowerCase() === PointTypeEnum.START) ?
          run.pointDetails.find(item => item.type.toLowerCase() === PointTypeEnum.START) : {} : {},
      destination: run && run.pointDetails ?
        run.pointDetails.find(item => item.type.toLowerCase() === PointTypeEnum.DESTINATION) ?
          run.pointDetails.find(item => item.type.toLowerCase() === PointTypeEnum.DESTINATION) : {} : {},
    };
  }

  private resetFieldsForCopy() {
    if (this.isCopy) {
      this.runForm.controls.startDate.setValue('');
      this.runForm.controls.endDate.setValue('');
    }
  }

  private createWaypoints(run) {
    if (run) {
      run.waypoints = run.waypoints || [];
      this.waypoints = run.waypoints.map(t => {
        return {
          location: t.lat.toFixed(4) + ',' + t.lon.toFixed(4)
        };
      });
      this.waypointsDetails = run.waypoints.map(t => {
        return {
          name: t.pointDetails ? t.pointDetails.name : t.name,
          url: t.pointDetails ? t.pointDetails.url : t.url,
          description: t.pointDetails ? t.pointDetails.description : t.description,
          pictureUrl: t.pointDetails ? t.pointDetails.name : t.pictureUrl,
          pointCategoryId: t.pointDetails ? t.pointDetails.pointCategoryId : undefined,
        };
      });
      // run.waypoints.forEach((t, index) => {
      //   setTimeout(() => {
      //     this.createOrUpdateWaypointWithID(index + 1, t.lat + ',' + t.lon, t.title);
      //   })
      // })
    }
  }

  private updateRequiredParameters() {
    this.runForm.get('packId').valueChanges
      .subscribe(packId => {
        if (packId === 'SOLO') {
          this.runForm.get('startDate').setValidators(null);
          this.runForm.get('endDate').setValidators(null);
          this.runForm.get('isSolo').setValue(true);
        } else {
          this.runForm.get('startDate').setValidators([Validators.required]);
          this.runForm.get('endDate').setValidators([Validators.required]);
          this.runForm.get('isSolo').setValue(false);
        }
      });
  }

  private packParameters(run) {
    if (run && !run.packId && !this.isCopy) {
      if (run.isSolo == 1) {
        this.runForm.controls.packId.setValue('SOLO');
      } else if (run.isPublic  == 1 && run.isProtected  != 1) {
        this.runForm.controls.packId.setValue('NONE');
      } else {
        this.runForm.controls.packId.setValue('NO_ID');
      }
    }
  }

  private userPreferences(run) {
    this.data.preferences = this.data.preferences || [];
    this.data.preferences.forEach(t => {
      if (t.keyword === MapEnum.FERRIES) {
        this.runForm.controls.avoidFerries.setValue(+t.value == 1);
      }
      if (t.keyword === MapEnum.HIGHWAYS) {
        this.runForm.controls.avoidHighways.setValue(+t.value == 1);
      }
      if (t.keyword === MapEnum.TOLLS) {
        this.runForm.controls.avoidTolls.setValue(+t.value == 1);
      }
    });
    if (run) {
      this.runForm.controls.avoidHighways.setValue(+run.avoidHighways == 1);
      this.runForm.controls.avoidTolls.setValue(+run.avoidTolls == 1);
      this.runForm.controls.avoidFerries.setValue(+run.avoidFerries == 1);
    }
  }

  private initMap() {
    this.map = new google.maps.Map(this.runMapId.nativeElement, {
      center: {lat: 0, lng: 0},
      zoom: 1.7
    });
    google.maps.event.trigger(this.map, "resize");
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      draggable: !this.isWPExistsAndBasicEditMode(),
      map: this.map
    });
    this.listenInputHandlers();
    this.listenCoordChanges();
  }

  private updateAddressNames(locationNames, locationLogLtn) {
    this.runForm.controls.startingPointName.setValue(locationNames[0]);
    this.runForm.controls.endingPointName.setValue(locationNames[locationNames.length - 1]);
    // if (this.isPremium) {
      locationNames.forEach((t, index) => {
        if (index !== 0 && index !== locationNames.length - 1) {
          this.createOrUpdateWaypointWithID(index, locationLogLtn[index - 1], locationNames[index]);
        }
      });
    // }
  }

  public detailsPoint(type) {
    const point: any = type === PointTypeEnum.START ? this.pointDetails.start : this.pointDetails.destination;
    this.displayDialog(DialogWaypointComponent,
      {
        waypoint: Object.assign(point),
        pointCategories: this.pointCategories
       // readOnly: !this.isPremium
      },
      (response) => {
        if (response) {
          point.url = response.url;
          point.description = response.description;
          point.name = response.name;
          point.pictureUrl = response.pictureUrl;
          point.pointCategoryId = response.pointCategoryId;
        }
      }, () => {});
  }

  public isPointInfoExist(type) {
    return this.pointDetails[type] && (!! this.pointDetails[type].name ||
      !! this.pointDetails[type].description ||
      !! this.pointDetails[type].url ||
      !! this.pointDetails[type].pictureUrl ||
      !! this.pointDetails[type].pointCategoryId
    );
  }

  private listenCoordChanges() {
    this.directionsDisplay
        .addListener('directions_changed', () => {


      const waypointObj = {};
      this.waypoints.forEach((t, index) => {
        waypointObj[t.location + '_' + index] = Object.assign({}, this.waypointsDetails[index]);
        waypointObj[t.location] = Object.assign({}, this.waypointsDetails[index]);
      })

      let detectedChange = false;
      const newWaypoints = [];
      const response = this.directionsDisplay.getDirections();
      if (response) {

        const waypointObj = {};
        this.waypoints.forEach((t, index) => {
          waypointObj[t.location + '_' + index] = Object.assign({}, this.waypointsDetails[index]);
          waypointObj[t.location] = Object.assign({}, this.waypointsDetails[index]);
        })

        const route = response.routes[0].legs;
        for (let i = 0; i < route.length; i++) {
          if (i > 0 && i < route.length) {
            newWaypoints.push({location: route[i].start_location.lat().toFixed(4) + ',' + route[i].start_location.lng().toFixed(4)});
          }
          if (route[i].via_waypoint.length > 0) {
            for (let wpi = 0; wpi < route[i].via_waypoint.length; wpi++) {
              detectedChange = true;
              newWaypoints.push({
                location: route[i].via_waypoint[0].location.lat().toFixed(4) + ',' +
                  route[i].via_waypoint[0].location.lng().toFixed(4)
              });
            }
          }
        }
        if (newWaypoints.length != this.waypoints.length) {
          detectedChange = true;
          if (!this.isPremium) {
            this.displayRoute(this.runMapParameters.startCoordinates,
              this.runMapParameters.endCoordinates,
              this.directionsService, this.directionsDisplay, true);
            return;
          }
        } else {
          for (let i = 0; i < newWaypoints.length; i++) {
            if (this.waypoints[i].location != newWaypoints[i].location) {
              detectedChange = true;
              break;
            }
          }
        }
        const newStartCoords = new google.maps.LatLng(
          route[0].start_location.lat(),
          route[0].start_location.lng()
        );
        const newEndCoords = new google.maps.LatLng(
          route[route.length - 1].end_location.lat(),
          route[route.length - 1].end_location.lng()
        );
        if (newStartCoords.lat() - this.runMapParameters.startCoordinates.lat() != 0 ||
          newStartCoords.lng() - this.runMapParameters.startCoordinates.lng() != 0 ||
          newEndCoords.lat() - this.runMapParameters.endCoordinates.lat() != 0 ||
          newEndCoords.lng() - this.runMapParameters.endCoordinates.lng() != 0) {
          detectedChange = true;
          this.runMapParameters.startCoordinates = new google.maps.LatLng(
            route[0].start_location.lat(),
            route[0].start_location.lng()
          );
          this.runMapParameters.endCoordinates = new google.maps.LatLng(
            route[route.length - 1].end_location.lat(),
            route[route.length - 1].end_location.lng()
          );
        }
        if (detectedChange) {

          const newwaypointsDetails = [];
          if (newWaypoints.length === this.waypoints.length) {
            newWaypoints.forEach((nW, i) => {
              newwaypointsDetails[i] = waypointObj[nW.location + '_' + i] || {};
            });
            this.waypointsDetails = newwaypointsDetails;
          } else {

            let index = 0;
            for(let i = 0; i < newWaypoints.length; i++) {
              const nW = newWaypoints[i];
              if (this.waypoints[index] && nW.location === this.waypoints[index].location) {
                newwaypointsDetails[i] = waypointObj[nW.location + '_' + index] || {};
                index++;
              } else {
                newwaypointsDetails[i] = {};
              }
            }
          }

          this.waypoints = newWaypoints;
          this.waypointsDetails = newwaypointsDetails;
          newWaypoints.forEach((t, i) => {
            if (!(this.waypoints[i]) ||
              (this.waypoints[i] && t.location != this.waypoints[i].location)
              ) {
              this.waypoints[i] = { location: t.location };
            }
          });




          const legs = this.directionsDisplay.directions.routes[0].legs;
          let totalDuration = 0;
          for (let j = 0; j < legs.length; j++) {
            totalDuration += parseInt(legs[j].duration.value, 10);
          }
          this.duration = totalDuration;
          this.displayRoute(this.runMapParameters.startCoordinates,
            this.runMapParameters.endCoordinates,
            this.directionsService, this.directionsDisplay, true);
        }
      }
      this.resize();
    });
  }

  private listenInputHandlers() {
    const startPointAutocomplete =
      new google.maps.places.Autocomplete(this.startingPointInput.nativeElement);
    const endingPointAutocomplete =
      new google.maps.places.Autocomplete(this.endingPointInput.nativeElement);
    this.listenInput(startPointAutocomplete, (place, country) => {
      this.runForm.controls.startCountry.setValue(country ? country.short_name : null);
      if (this.runForm.controls.startCountry.value === 'US') {
        const state = place.address_components ?
          place.address_components.find(ac => ac.types[0].startsWith('administrative_area_level_1')) : null;
        this.runForm.controls.startState.setValue(state ? state.short_name : null);
      } else {
        this.runForm.controls.startState.setValue(null);
      }
      const city = place.address_components ?
        place.address_components.find(ac => ac.types[0] === 'locality') : null;
      this.runForm.controls.startCity.setValue(city ? city.short_name : null);
      if (this.runMapParameters.startMarker) {
        this.runMapParameters.startMarker.setMap(null);
      }
      this.runMapParameters.startMarker = new google.maps.Marker({
        position: {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()},
        title: ''
      });
      this.runForm.controls.startingPointLongitude
        .setValue(place.geometry.location.lng());
      this.runForm.controls.startingPointLatitude
        .setValue(place.geometry.location.lat());
      this.runMapParameters.startCoordinates =
        new google.maps.LatLng(place.geometry.location.lat(),
          place.geometry.location.lng());
      this.runMapParameters.startMarker.setMap(this.map);
      this.displayRoute(this.runMapParameters.startCoordinates, this.runMapParameters.endCoordinates,
        this.directionsService, this.directionsDisplay, false);
    });
    this.listenInput(endingPointAutocomplete, (place, country) => {
      this.runForm.controls.endCountry.setValue(country ? country.short_name : null);
      if (this.runForm.controls.endCountry.value === 'US') {
        const state = place.address_components ?
          place.address_components.find(ac => ac.types[0].startsWith('administrative_area_level_1')) : null;
        this.runForm.controls.endState.setValue(state ? state.short_name : null);
      } else {
        this.runForm.controls.endState.setValue(null);
      }
      const city = place.address_components ?
        place.address_components.find(ac => ac.types[0] === 'locality') : null;
      this.runForm.controls.endCity.setValue(city ? city.short_name : null);
      if (this.runMapParameters.endMarker) {
        this.runMapParameters.endMarker.setMap(null);
      }
      this.runMapParameters.endMarker = new google.maps.Marker({
        position: {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()},
        title: ''
      });
      this.runForm.controls.endingPointLongitude
        .setValue(place.geometry.location.lng());
      this.runForm.controls.endingPointLatitude
        .setValue(place.geometry.location.lat());
      this.runMapParameters.endCoordinates =
        new google.maps.LatLng(place.geometry.location.lat(),
          place.geometry.location.lng());
      this.runMapParameters.endMarker.setMap(this.map);
      this.runMapParameters.endCoordinates =
        new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
      this.resize();
      this.displayRoute(this.runMapParameters.startCoordinates,
        this.runMapParameters.endCoordinates,
        this.directionsService, this.directionsDisplay, false);
    });
  }

  private centerMap() {
    if (!(this.runMapParameters.startCoordinates && this.runMapParameters.endCoordinates)) {
      return;
    }
    this.initMapDraw = 1;
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.runMapParameters.startCoordinates);
    this.waypoints.forEach(x => {
      if (x.location) {
        const latLng = x.location.split(',');
        bounds.extend(new google.maps.LatLng(latLng[0], latLng[1]));
      }
    });
    bounds.extend(this.runMapParameters.endCoordinates);
    google.maps.event.trigger(this.map, 'resize');
    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);
    this.resize();
  }

  private isWpValid(lonlatwplist) {
    return this.waypoints.length !== lonlatwplist.length;
  }

  isNotWaypointSelected() {
    return this.waypoints.some(t => !t || !t.location);
  }

  private displayRoute(origin, destination, service, display, isCenteredIgnored?) {
    if (!this.runMapParameters.startCoordinates || !this.runMapParameters.endCoordinates) {
      return;
    }
    if (this.runMapParameters.startMarker) {
      this.runMapParameters.startMarker.setMap(null);
    }
    if (this.runMapParameters.endMarker) {
      this.runMapParameters.endMarker.setMap(null);
    }
    setTimeout(t => {
      service.route({
        origin,
        destination,
        waypoints: this.waypoints,
        travelMode: 'DRIVING',
        avoidFerries: !!this.runForm.controls.avoidFerries.value,
        avoidHighways: !!this.runForm.controls.avoidHighways.value,
        avoidTolls: !!this.runForm.controls.avoidTolls.value,
      }, (response, status) => {

        this.zone.run(() => {
          if (status === 'OK') {
            this.isPathValid = true;
            this.resize();
            display.setDirections(response);
            const route = display.getDirections().routes[0].legs;

            const locationNames = [];
            const locationLogLtn = [];
            for (let i = 0; i < route.length; i++) {
              locationNames.push(route[i].start_address);
            }

            for (let i = 0; i < route.length; i++) {
              if (i > 0 && i < route.length) {
                locationLogLtn.push(route[i].start_location.lat().toFixed(4) + ',' + route[i].start_location.lng().toFixed(4));
              }
              if (route[i].via_waypoint.length > 0) {
                for (let wpi = 0; wpi < route[i].via_waypoint.length; wpi++) {
                  locationLogLtn.push(route[i].via_waypoint[0].location.lat().toFixed(4) + ',' +
                    route[i].via_waypoint[0].location.lng().toFixed(4));
                }
              }
            }

            locationNames.push(route[route.length - 1].end_address);
            this.isValidWP = this.isWpValid(locationLogLtn);

            this.updateAddressNames(locationNames, locationLogLtn);

            if (!isCenteredIgnored) {
              setTimeout(() => {
                this.centerMap();
                // this.resize();
              }, 200);
            }

            this.updateDuration(route);

            this.setGeoCoder(this.runMapParameters.startCoordinates,
              (city, cityAlt, country, countryCode) => {
                this.runForm.controls.startCity.setValue(city);
                this.runForm.controls.startCountry.setValue(countryCode);
                this.resize();
              });
            this.setGeoCoder(this.runMapParameters.endCoordinates,
              (city, cityAlt, country, countryCode) => {
                this.runForm.controls.endCity.setValue(city);
                this.runForm.controls.endCountry.setValue(countryCode);
                this.resize();
              });

          } else {
            this.isPathValid = false;
            this.displayDialog(DialogMessageComponent,
              {message: 'The route you requested cannot be charted', type: MessageTypeEnum.WARNING}, () => {
              },
              () => {
              });
            // this.displayNotificationMessage('Could not display directions due to: ' + status);
          }
        });
      });
    }, 50)
  }

  private updateDuration(route) {
    let totalDistance = 0;
    let totalDuration: number;
    totalDuration = 0;
    for (let i = 0; i < route.length; ++i) {
      totalDistance += route[i].distance.value;
      totalDuration += parseInt(route[i].duration.value, 10);
    }
    if (!this.duration) {
      this.duration = totalDuration;
    }
    let totalDays = totalDuration / 86400;
    totalDays = Math.floor(totalDays);
    let totalHours = (totalDuration - totalDays * 86400) / 3600;
    totalHours = Math.floor(totalHours);
    let totalMinutes = (totalDuration - (totalDays * 86400) - (totalHours * 3600)) / 60;
    totalMinutes = Math.floor(totalMinutes);
    this.approximateTime = totalDays + ' days ' + totalHours +
      ' hours ' + totalMinutes + ' minutes';
    this.runMapParameters.distance = totalDistance;
    this.runMapParameters.duration = totalDuration;
  }

  private setGeoCoder(coordidates, callback) {
    new google.maps.Geocoder().geocode({'latLng' : coordidates}, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          let country = null, countryCode = null, city = null, cityAlt = null;
          let c, lc, component;
          for (let r = 0, rl = results.length; r < rl; r += 1) {
            const result = results[r];

            if (!city && result.types[0] === 'locality') {
              for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                component = result.address_components[c];

                if (component.types[0] === 'locality') {
                  city = component.long_name;
                  break;
                }
              }
            }
            else if (!city && !cityAlt && result.types[0] === 'administrative_area_level_1') {
              for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                component = result.address_components[c];

                if (component.types[0] === 'administrative_area_level_1') {
                  cityAlt = component.long_name;
                  break;
                }
              }
            } else if (!country && result.types[0] === 'country') {
              country = result.address_components[0].long_name;
              countryCode = result.address_components[0].short_name;
            }
            if (city && country) {
              break;
            }
          }
          if (callback) {
            callback(city, cityAlt, country, countryCode);
          }
        }
      }
    });
  }

  private listenInput(autocomplete, action) {
    google.maps.event.addListener(autocomplete,
      'place_changed', () => {
        const place = autocomplete.getPlace();
        const country = place.address_components ? place.address_components.find(ac => ac.types[0] === 'country') : null;
        this.resize();
        action(place, country);
      });
  }

   resize() {
    const currentCenter = this.map.getCenter();  // Get current center before resizing
    google.maps.event.trigger(this.map, "resize");
    this.map.setCenter(currentCenter); // Re-set previous center
  }

  public ngAfterViewInit(): void {
    this.createWaypoints(this.data.run);
    this.initMap();
    this.updateMapPoints();
    setTimeout(y => {
      if (!this.data.run || (this.data.run && !this.isCopy)) {
        this.initMapDraw = 1;
        this.map.setZoom(1.9);
        this.resize();
      }
    }, 300)
  }

  public saveRun() {
    if (this.runForm.invalid) {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.WARNING,
        explanation: 'Please fill required fields',
        message: ''
      }, () => {}, () => {});
      return;
    }
    if (!this.formValidation()) {
      return;
    }
    const direction = this.getDirection();
    if (!direction || !direction.routes ||
      direction.routes.length === 0) {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.WARNING,
        explanation: 'Check the route correctly',
        message: ''
      }, () => {}, () => {});
      return;
    }
    if (!this.data.run || (this.data.run && this.isCopy)) {
      this.loading = true;
      this.data.createRun(this.runPreparation(), (run) => {
        this.loading = false;
        this.dialogRef.close(run)
      }, () => {
        this.loading = false;
      })
    } else {
      this.loading = true;
      this.data.editRun(this.runPreparation(), (run) => {
        this.loading = false;
        this.dialogRef.close(run)
      }, () => {
        this.loading = false;
      })
    }
  }

  private formValidation() {
    if (!this.runForm.controls.isSolo.value &&
      (+this.runForm.controls.startDate.value.toDate() >
      +this.runForm.controls.endDate.value.toDate())) {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.ERROR,
        explanation: 'Start date connot be after end date',
        message: ''
      }, () => {}, () => {});
      return false;
    }
    if (this.waypoints.some(t => {
      return !t.location;
    })) {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.ERROR,
        explanation: 'All waypoints should be selected',
        message: ''
      }, () => {}, () => {});
      return false;
    }
    return true;
  }

  private runPreparation() {
    const runObj = this.runForm.getRawValue();
    runObj.id = this.data.run && !this.isCopy ? this.data.run.id : null;
    runObj.startDate = this.runForm.controls.startDate.value ?
      this.runForm.controls.startDate.value.toDate().toISOString() : null;
    runObj.endDate = this.runForm.controls.endDate.value ?
      this.runForm.controls.endDate.value.toDate().toISOString() : null;
    runObj.avoidFerries = this.runForm.controls.avoidFerries.value ? '1' : '0';
    runObj.avoidHighways = this.runForm.controls.avoidHighways.value ? '1' : '0';
    runObj.avoidTolls = this.runForm.controls.avoidTolls.value ? '1' : '0';
    runObj.isProtected = this.runForm.controls.isProtected.value ? '1' : '0';
    runObj.isSolo = this.runForm.controls.isSolo.value ? '1' : '0';
    runObj.isPublic = this.runForm.controls.isPublic.value ? '1' : '0';
    runObj.packId = isNaN(this.runForm.controls.packId.value) ? null : this.runForm.controls.packId.value;
    runObj.routeImageUrl = this.getRouteImageUrl();
    runObj.startingPointLatitude = this.runMapParameters.startCoordinates.lat();
    runObj.startingPointLongitude = this.runMapParameters.startCoordinates.lng();
    runObj.endingPointLongitude = this.runMapParameters.endCoordinates.lng();
    runObj.endingPointLatitude = this.runMapParameters.endCoordinates.lat();
    runObj.distance = Math.round((this.runMapParameters.distance * METERS_TO_MILES * 10) / 10) * 1.60934;
    runObj.estimatedDistance = Math.round((this.runMapParameters.distance * METERS_TO_MILES * 10) / 10) * 1.60934;
    runObj.publicRadius = PUBLIC_RADIUS;
    runObj.duration = this.runMapParameters.duration;
    runObj.estimatedTime = this.runMapParameters.duration;
    runObj.waypoints = runObj.waypoints.map((title, i) => {
      const waypoint: any = {
        lat: this.waypoints[i].location.split(',')[0],
        lon: this.waypoints[i].location.split(',')[1],
        ord: i + 1,
        ordLetter: i + 1,
        title
      };
      if (this.waypointsDetails[i] && (this.waypointsDetails[i].name ||
        this.waypointsDetails[i].url ||
        this.waypointsDetails[i].description ||
        this.waypointsDetails[i].pictureUrl ||
        this.waypointsDetails[i].pointCategoryId
      )) {
        waypoint.pointDetails = {
          name: this.waypointsDetails[i].name,
          url: this.waypointsDetails[i].url,
          description: this.waypointsDetails[i].description,
          pictureUrl: this.waypointsDetails[i].pictureUrl,
          pointCategoryId: this.waypointsDetails[i].pointCategoryId,
        };
      }
      return waypoint;
    });
    runObj.pointDetails = [];
    if (this.isPointInfoExist(PointTypeEnum.START)) {
      runObj.pointDetails.push(this.getPointDetails(PointTypeEnum.START, 1));
    }
    if (this.isPointInfoExist(PointTypeEnum.DESTINATION)) {
      runObj.pointDetails.push(this.getPointDetails(PointTypeEnum.DESTINATION, 2));
    }
    return runObj;
  }

  private getPointDetails(point, typeId) {
    return {
      id: this.pointDetails[point].id,
      name: this.pointDetails[point].name,
      description: this.pointDetails[point].description,
      pictureUrl: this.pointDetails[point].pictureUrl,
      pointCategoryId: this.pointDetails[point].pointCategoryId,
      url: this.pointDetails[point].url,
      type: point.toUpperCase()
    };
  }

  private getDirection() {
    return this.directionsDisplay.getDirections();
  }

  private getRouteImageUrl() {
    const directionsResponse = this.getDirection();//this.directionsDisplay.getDirections();
    const Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let mapImageUrl = 'https://maps.googleapis.com/maps/api/staticmap' +
      '?key=AIzaSyBCFhCOky97Ewo3ViCMQyNK0zLBmaO20go&size=640x400&maptype=roadmap&path=enc:' +
      directionsResponse.routes[0].overview_polyline;
    mapImageUrl += '&markers=color:red|label:A|' +
      this.runMapParameters.startCoordinates.lat() + ',' +
      this.runMapParameters.startCoordinates.lng();
    for (let i = 0; i < this.waypoints.length; i++) {
      mapImageUrl += '&markers=color:red|label:' + Alphabet.charAt(i + 1) + '|' +
        this.waypoints[i].location;
    }
    mapImageUrl += '&markers=color:red|label:' +
      Alphabet.charAt(this.waypoints.length + 1) + '|' +
      +this.runMapParameters.endCoordinates.lat() + ',' +
      this.runMapParameters.endCoordinates.lng();
    return mapImageUrl;
  }

  public packUpdateHandler($event) {
    if ($event === 'SOLO') {
      this.runForm.controls.isSolo.setValue(true);
    } if ($event === 'NONE') {
      this.runForm.controls.isPublic.setValue(true);
      this.runForm.controls.isProtected.setValue(false);
    } else {
      this.runForm.controls.isPublic.setValue(false);
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  public changedMapSettings(statusField) {
    this.displayRoute(this.runMapParameters.startCoordinates,
      this.runMapParameters.endCoordinates,
      this.directionsService, this.directionsDisplay, true);
  }

  private displayNotificationMessage(message) {
    this.errorNotification = message;
    setTimeout(t => {
      this.errorNotification = '';
    }, 3000)
  }

  public isWPExistsAndBasicEditMode() {
    return !!this.data.run && this.data.run.waypoints &&
      this.data.run.waypoints.length > 0 && !this.isPremium;
  }

  public displayDatePicker($event, pickerStartDate) {
    $event.stopPropagation();
    pickerStartDate.datepicker.open()
  }

  public checkCurrentDateTime($event) {
    const currentDateTime = new Date($event);
    if (!this.runForm.get('endDate').value ||
      (+new Date(this.runForm.get('endDate').value) < +currentDateTime)) {
      currentDateTime.setHours(23);
      currentDateTime.setMinutes(59);
      currentDateTime.setSeconds(59);
      this.runForm.get('endDate').setValue(moment(currentDateTime));
    }
  }

}
