import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class SupportFacebookLoginService {

  constructor(private apiService: ApiService) {}

  public confirm(data) {

  }

}
