<div class="actions-table">
  <div class="actions-table-wrapper">
    <div class="trophie-content">
      <img [src]="tag.pictureUrl" class="trophie-item-img"/>
      <div class="trophie-item-title">{{tag?.name}}</div>
      <div class="trophie-item-title">Awarded in {{achievementDate | date : 'MMMM yyyy'}}</div>
      <div class="trophie-item-description">{{tag?.description}}</div>
    </div>
    <div class="trophie-item-content-btn-items">
      <button mat-raised-button color="primary" mat-dialog-close=""
              (click)="ok()">Ok</button>
    </div>

  </div>
</div>

