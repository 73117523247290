import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject, NgZone, OnDestroy,
  OnInit,
} from '@angular/core';
import {FormArray, UntypedFormBuilder, FormControl, Validators} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {BaseComponent} from "../../../../shared/classes/base.component";
import {DOCUMENT} from "@angular/common";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ImageDisplayComponent} from "../../../../shared/components/image-cropper/image-display.component";
import {RunsService} from "../runs.service";
import {finalize} from "rxjs/operators";
import {DialogMessageComponent} from "../../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../../enums/message-type.enum";
import base64ToFile from "../../../../shared/functions/base64-to-file";
import resizeImage from "../../../../shared/functions/resize-image";
import {NgxImageCompressService} from "ngx-image-compress";

@Component({
  selector: 'app-user-dialog-run-waypoint',
  templateUrl: './dialog-waypoint.component.html',
  styleUrls: ['./dialog-waypoint.component.scss']
})
export class DialogWaypointComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public waypointDetails = {
    name: '',
    description: '',
    url: '',
    pictureUrl: '',
    pointCategoryId: undefined
  };
  readonlyMode;
  uploadedImage;
  currentUpdatedImage;

  loading = false;

  pointCategories = [];

  public constructor(private builder: UntypedFormBuilder,
                     public dialogRef: MatDialogRef<DialogWaypointComponent>,
                     public dialog: MatDialog,
                     @Inject(MAT_DIALOG_DATA)
                     public data: any,
                     @Inject(DOCUMENT) document,
                     public snackBar: MatSnackBar,
                     private runService: RunsService,
                     private imageCompress: NgxImageCompressService
                     ) {
    super(dialog, snackBar);
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.readonlyMode = !!this.data.readOnly;
    this.pointCategories = this.data.pointCategories;
    this.generateItems();
  }

  private generateItems() {
    this.waypointDetails.name = this.data.waypoint.name;
    this.waypointDetails.description = this.data.waypoint.description;
    this.waypointDetails.url = this.data.waypoint.url;
    this.waypointDetails.pictureUrl = this.data.waypoint.pictureUrl;
    this.waypointDetails.pointCategoryId = this.data.waypoint.pointCategoryId;
  }

  ngAfterViewInit(): void {

  }

  public removePictureUrl() {
    this.waypointDetails.pictureUrl = '';
    this.currentUpdatedImage = undefined;
  }

  public fileChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.displayDialog(ImageDisplayComponent,
        {
          image: event.target.files[0]
        },
        (result) => {
          if (result && result.image) {
            this.uploadedImage = result.image;
            this.currentUpdatedImage = result.base64;
            this.loading = true;
            const file = this.uploadedImage;
            const reader = new FileReader();
            reader.onload = () => {
              const fileResult = base64ToFile(reader.result,
                new Date().getTime() + file.name.substring(file.name.lastIndexOf('.')));
              resizeImage(fileResult, 800, 800).then(blob => {
                this.imageCompress.compressFile(window.URL.createObjectURL(blob),
                  0, 80, 90).then(
                  result => {

                    this.runService.uploadWaypointImage(base64ToFile(result,
                      new Date().getTime() +
                      file.name.substring(file.name.lastIndexOf('.')))).pipe(
                      finalize(() => {
                        this.loading = false;
                      })
                    ).subscribe(res => {
                      if (res.success) {
                        this.waypointDetails.pictureUrl = res.result.data[0].path;
                      } else {
                        this.displayDialog(DialogMessageComponent, {
                            type: MessageTypeEnum.ERROR,
                            message: 'Problem with uploading the image',
                            explanation: 'Problem with uploading the image'
                          },
                          () => {},
                          () => {});
                      }
                    });

                  });
              });

            };
            reader.readAsDataURL(file);
          }
        },
        () => {});
    }
  }

  public saveWaypointDetails() {
    // if (this.waypointDetails.url &&
    //   (!this.isValidUrl(this.waypointDetails.url) ||
    //     this.isStartedWithHttp(this.waypointDetails.url))) {
    //   return;
    // }
    this.dialogRef.close(this.waypointDetails);
  }

  public cancel() {
    this.dialogRef.close();
  }

  isStartedWithHttp(value) {
    value = value || '';
    return value.indexOf('http') === 0;
  }

  isValidUrl(value) {
    value = value || '';
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);
    return !value || (!!value && value.match(regex));
  }

}
