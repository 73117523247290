import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {TagModel} from "../../../../enums/statistics.model";

@Component({
  selector: 'app-stats-trophie',
  templateUrl: './trophie-item.component.html',
  styleUrls: ['./trophie-item.component.scss']
})
export class TrophieItemComponent implements OnInit {

  public tag: TagModel;
  public achievementDate: string;

  public constructor(public dialog: MatDialog,
     @Inject(MAT_DIALOG_DATA)
     public data: any) {}

  ngOnInit(): void {
    this.tag = this.data.tag.tag;
    this.achievementDate = this.data.tag.achievementDate;
  }

  public ok() {

  }

}
