import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStorageService } from '../services/app-storage.service';
import { StorageEnum } from '../../enums/storage.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private appStorageService: AppStorageService) {}

  intercept(request: HttpRequest<any>,
            next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newBody;
    if (request.body instanceof FormData) {
      newBody = this.cloneFormData(request.body);
      newBody.append('token',
        this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN));
    } else {
      newBody =  Object.assign(request.body ? request.body : {},
        { token: this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN) });
    }
    const copy = request.clone({
      headers: request.headers.set('access_token',
        this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN))
        .set('Authorization', this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN)
          || this.appStorageService.getCookie(StorageEnum.TOKEN)),
      body: newBody
    });
    return this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN) ? next.handle(copy) : next.handle(request);
  }

  private cloneFormData(formData) {
    const cloneFormData = new FormData();
    formData.forEach((value, key) => {
      cloneFormData.append(key, value);
    })
    return cloneFormData;
  }

}
