import {Component, NgZone, OnInit} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StatsService } from './stats.service';
import StatisticsModel from '../../../enums/statistics.model';
import {MapEnum} from '../../../enums/map.enum';
import {CommonService} from '../../../core/services/common.service';
import {BaseComponent} from '../../../shared/classes/base.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TrophieItemComponent} from './trophie-item/trophie-item.component';
import {LETTERS} from '../../../consts/symbols';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../core/services/auth.service';
import {UserModel} from '../../../models/user.model';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

declare const google: any;

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent extends BaseComponent implements OnInit {

  public loading = {
    stats: false,
    preferences: false,
    runs: false,
    runsForStats: false
  };

  public response: StatisticsModel;
  public preferences: any[] = [];
  public distance = '';

  public runs: any[] = [];
  public runsForMap: any[] = [];

  public map: google.maps.Map;
  public markers: google.maps.Marker[];
  // @ts-ignore MarkerClusterer defined via script
  public clusterManager: MarkerClusterer;

  public option = {
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: true
    }],
    itemStyle: {normal: {areaStyle: {type: 'default'}}},
    lineStyle: {
      color: '#c92c3c',
    },
    dataZoom: []
  };

  public yearList = [];

  public runStatistics = [];
  public userInfo: UserModel;
  public currentYear;

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private statsService: StatsService,
                     private commonService: CommonService,
                     private authService: AuthService,
                     public dialog: MatDialog,
                     private zone: NgZone,
                     public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.loadStatistics();
    this.userPreferences();
    this.generateYearList();
    this.getHistogramRuns();
  }

  public checkDisplayData() {
    this.getHistogramRuns();
  }

  private generateYearList() {
    for (let i = environment.firstYearForSearch; i <= new Date().getFullYear(); i++) {
      this.yearList.push(i);
    }
  }

  private getHistogramRuns() {
    this.loading.runsForStats = true;
    this.authService.isUserPremium().subscribe(t => {
      if (t.result && t.result.data) {
        this.userInfo = t.result.data[0];
        this.statsService.getHistogramRuns(this.userInfo.id, this.currentYear)
          .subscribe(t => {
            if (t && t.result && t.result.data) {
              this.runStatistics = t.result.data || [];
            }
            const obj = this.calculationForMonthes(this.runStatistics);
            this.option.xAxis.data = obj.arrayAxis;
            this.option.series[0].data = obj.arraySeries;
            const percent = (obj.countOfYears != 0 ? (Math.round((1 / (obj.countOfYears + 1)) * 100) / 100) : 1) * 100;
            if (!this.currentYear) {
              this.option.dataZoom = [
                {
                  type: 'inside',
                  start: (100 - percent) < 0 ? 0 : (100 - percent),
                  end: 100,
                },
                {
                  start: (100 - percent) < 0 ? 0 : (100 - percent),
                  end: 100,
                  handleSize: percent  + '%'
                }
              ];
            } else {
              this.option.dataZoom = [];
            }
            this.loading.runsForStats = false;
          }, () => {
            this.loading.runsForStats = false;
          });
        this.loadMapInfo();
      }
    });
  }

  private calculationForMonthes(runStatistics) {
    const arrayAxis = [];
    const arraySeries = [];
    const startMonth = runStatistics[runStatistics.length - 1];
    const endMonth = runStatistics[0];
    const obj = {};
    runStatistics.forEach(y => {
      obj[this.commonService.getStartZero(y.month) + '.' + y.year] = y.runCount;
    });
    let countOfYears = 0;
    if (startMonth && endMonth) {
      countOfYears = Math.abs(endMonth.year - startMonth.year);
      for (let i = 0; i <= countOfYears; i++) {
        for (let j = 1; j <= 12; j++) {
          const period = this.commonService.getStartZero(j) + '.' + (startMonth.year + i);
          arrayAxis.push(period);
          arraySeries.push(obj[period] || 0);
        }
      }
    }
    return {
      arrayAxis,
      arraySeries,
      countOfYears
    };
  }

  private loadMapInfo() {
    this.loading.runs = true;
    this.statsService.getRuns(this.userInfo.id).subscribe(t => {
      if (t && t.result && t.result.data &&
        t.result.data[0]) {
        this.runs = t.result.data;
      } else {
        this.runs = [];
      }
      this.runPreparations();
      this.initMap(this.runsForMap);
      this.loading.runs = false;
    }, () => {
      this.loading.runs = false;
    });
  }

  private runPreparations() {
    this.runsForMap = this.runs.map((e) => {
      return {
        startingPointLatitude: Number(e.run.endingPointLatitude),
        startingPointLongitude: Number(e.run.endingPointLongitude),
        future: e.userToRunStatus === 4
      };
    });
  }

  private loadStatistics() {
    this.loading.stats = true;
    this.statsService.getStats().subscribe(t => {
      if (t && t.result && t.result.data &&
        t.result.data[0]) {
        this.response = t.result.data[0];
      } else {
        this.response = new StatisticsModel();
      }
      this.loading.stats = false;
    }, () => {
      this.loading.stats = false;
    });
  }

  private userPreferences() {
    this.loading.preferences = true;
    this.statsService.getUserPreferences()
      .subscribe((preferences) => {
        if (preferences) {
          this.preferences = preferences.result.data;
          const distance = this.preferences.find(t => {
            return t.keyword === MapEnum.DISTANCE_FORMAT;
          });
          if (distance) {
            this.distance =
              this.commonService.distanceMapper(distance.value);
          }
        }
        this.loading.preferences = false;
      }, () => {
        this.loading.preferences = false;
      });
  }

  public isLoaded() {
    return this.loading.stats || this.loading.preferences || this.loading.runs;
  }

  public displayMoreInfo(item) {
    this.displayDialog(TrophieItemComponent, {tag: item}, () => {}, () => {});
  }

  private resetMarkers() {
    if (this.map && this.markers) {
      this.markers.forEach(t => {
        t.setMap(null);
      });
      this.markers = [];
      if (this.clusterManager) {
        this.clusterManager.clearMarkers();
      }
      // @ts-ignore MarkerClusterer defined via script
      new MarkerClusterer(this.map, this.markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        maxZoom: 20,
      });
    }
  }

  private initMap(locations) {
    this.resetMarkers();
    if (!this.map) {
      const opt = {
        zoom: 2.5,
        center: { lat: 0, lng: 0 },
      };
      this.map = new google.maps.Map(
        document.getElementById('runsId') as HTMLElement,
        opt
      );
    }
    // const geocoder = new google.maps.Geocoder();
    // geocoder.geocode( {'address' : `${country}, ${countryCode}` + (state ? `${state}, ${stateCode}` : '')},
    //   (results, status) => {
    //     if (status == google.maps.GeocoderStatus.OK) {
    //       this.map.setCenter(results[0].geometry.location);
    //       this.map.setZoom(3.5);
    //     }
    //   });
    this.map.setZoom(2);
    this.map.setCenter({lat: 0, lng: 0});
    // Create an array of alphabetical characters used to label the markers.
    const labels = LETTERS;
    // Add some markers to the map.
    // Note: The code uses the JavaScript Array.prototype.map() method to
    // create an array of markers based on a given "locations" array.
    // The map() method here has nothing to do with the Google Maps API.
    this.markers = locations.map((location, i) => {
      const marker = new google.maps.Marker({
        position: {
          lat: location.startingPointLatitude,
          lng: location.startingPointLongitude
        },
        label: '', // labels[i % labels.length],
        icon: {
          url: !location.future ? environment.mapAttendedMarkerUrl :
            environment.mapNotAttendedMarkerUrl,
          scaledSize: new google.maps.Size(15, 20), // scaled size
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0) // anchor
        }
      });
      marker.addListener('click', (e, y) => {
        e.vb.stopPropagation();
      }, {passive: true});
      marker.setMap(this.map);
      return marker;
    });
    // Add a marker clusterer to manage the markers.
    // @ts-ignore MarkerClusterer defined via script
    // this.clusterManager = new MarkerClusterer(this.map, this.markers, {
    //   imagePath:
    //     "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    // });
    // google.maps.event.addListener(this.clusterManager,
    //   'clusterclick', (cluster) => {});
  }


}
