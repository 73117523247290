import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DialogRunComponent } from './dialog-run.component';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CoreModule} from '../../../../core/core.module';
import {DialogWaypointModule} from '../dialog-waypoint/dialog-waypoint.module';

@NgModule({
  declarations: [
    DialogRunComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        FormsModule,
        DragDropModule,
        DialogWaypointModule,
        CoreModule
    ],
  providers: [],
  bootstrap: [DialogRunComponent]
})
export class DialogRunModule { }
