import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { WhatsNewComponent } from './whats-new.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WhatsNewService } from './whats-new.service';
import { SharedModule } from '../../shared/shared.module';
import {CoreModule} from "../../core/core.module";
import {ScrollingModule} from "@angular/cdk/scrolling";

@NgModule({
  declarations: [
    WhatsNewComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    ScrollingModule
  ],
  providers: [
    WhatsNewService
  ],
  bootstrap: [WhatsNewComponent]
})
export class WhatsNewModule { }
