import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from "../../../../core/services/auth.service";
import {PublicRunsService} from "./../public-runs.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { publicRunTableColumns } from 'src/app/shared/common-structures/public-run-table.list';

declare const google: any;

@Component({
  selector: 'app-user-multiple-public-run',
  templateUrl: './multiple-public-runs.component.html',
  styleUrls: ['./multiple-public-runs.component.scss']
})
export class MultiplePublicRunsComponent implements OnInit {

  public countriesObject = {};

  public loading = false;

  public run;
  public distance: string = '';

  public location = {
    lat: '',
    lng: ''
  };

  public publicRunTableColumns = publicRunTableColumns;

  constructor(private authService: AuthService,
              private publicRunsService: PublicRunsService,
              public dialogRef: MatDialogRef<MultiplePublicRunsComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: any,) {
  }

  ngOnInit(): void {
    this.location = this.data.location;
  }

  public cancel() {
    this.dialogRef.close();
  }

}
