
<div class="faq-container">
  <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
  <app-main-page-title [title]="'Help'"></app-main-page-title>
  <mat-accordion class="faq-headers-align" multi *ngFor="let faq of faqs">

    <mat-expansion-panel hideToggle="true">
      <mat-expansion-panel-header class="faq-question"  (click)="faq.icon = !faq.icon">
        <mat-panel-description>
          <div></div>
        </mat-panel-description>
        <mat-panel-title>
          <mat-icon>{{!faq.icon  ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          <div class="faq-name" [id]="'FAQ_CATEGORY' | gtmTag">
            <span [id]="'FAQ_CATEGORY' | gtmTag">
              {{faq.name}}
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="faq-wrapper">
        <mat-accordion class="faq-headers-align" multi *ngFor="let f of faq.faqs">
          <mat-expansion-panel hideToggle="true" >
            <mat-expansion-panel-header class="faq-answer" (click)="f.icon = !f.icon">
              <mat-panel-description>
                <div></div>
              </mat-panel-description>
              <mat-panel-title>
                <mat-icon class="question-mark">{{f.icon  ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
                <span class="category-question-item" [id]="'FAQ_CATEGORY_QUESTION' | gtmTag">{{f.question}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="faq-content">
              <div [innerHTML]="f.answer"></div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-expansion-panel>


  </mat-accordion>

</div>

