import { Injectable } from '@angular/core';
import { DialogMessageComponent } from '../../shared/components/dialog-message/dialog-message.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {NotificationDialogModel} from '../../models/dialogs/notification-dialog.model';

@Injectable()
export class MessageHandlerService {

  public isDialogOpen = false;
  constructor(public dialog: MatDialog) { }

  public openDialog(data: NotificationDialogModel, callback?): any {
    if (this.isDialogOpen || !data.type) {
      return false;
    }
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if (callback) {
        callback();
      }
    });
  }

}
