export default class TableOptionsModel {
  public disableOptions: TableOptionsDisableModel;
  public tooltip;
  public hideOptions: TableOptionsHideModel;
}

export class TableOptionsDisableModel {
  public disableDeletion;
  public disableEdition;
  public disableActivation;
}

export class TableOptionsHideModel {
  public hideDeletion;
}
