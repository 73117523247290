import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import { TableTypeEnum } from 'src/app/enums/table-type.enum';
import { publicRunTableColumns } from 'src/app/shared/common-structures/public-run-table.list';
import {AuthService} from "../../../core/services/auth.service";
import {PublicRunsService} from "./public-runs.service";
import {LETTERS} from "../../../consts/symbols";
import {BaseComponent} from "../../../shared/classes/base.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {PublicRunDetailsComponent} from "./public-run-details/public-run-details.component";
import {MapEnum} from "../../../enums/map.enum";
import {CommonService} from "../../../core/services/common.service";
import {RsvpResponseEnum} from "../../../enums/rsvp.response.enum";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {DISTANCE_CONST, PUBLIC_RUN_MAP_ZOOM} from "../../../consts/distances";
import TableOptionsModel, {TableOptionsDisableModel} from "../../../models/tables/table-options.model";
import {DialogConfirmationComponent} from "../../../shared/components/dialog-confirmation/dialog-confirmation.component";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {DialogMessageComponent} from "../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../enums/message-type.enum";
import {UserToRunStatusEnum} from "../../../enums/user-to-run-status.enum";
declare const google: any;

@Component({
  selector: 'app-user-public-runs',
  templateUrl: './public-runs.component.html',
  styleUrls: ['./public-runs.component.scss']
})
export class PublicRunsComponent extends BaseComponent implements OnInit {

  public userPremInformation;
  public isPremium = false;

  public countries = [];
  public states = [];

  public countriesObject = {};
  public stateObject = {};


  public displayedInfo = {
    all: true,
    lat: '',
    lng: ''
  };

  public publicRunTableColumns = publicRunTableColumns;

  public loading = {
    countries: false,
    states: false,
    runs: false,
    user: false,
    rsvp: false,
    myRuns: false
  };

  public filterList = {
    country: '',
    state: ''
  };

  public publicRuns = [];
  public publicRunsCount = {};

  public totalCount = 0;

  public TableTypeEnum = TableTypeEnum;

  public parameterTable = {
    currentPage: 1,
    pageSize: 10,
    sorts: [{field: 'startDate', desc: true}]
  };

  public distance = '';
  public preferences = [];
  public myJoinedPublicRuns = {};

  public map: google.maps.Map;
  public markers: google.maps.Marker[];
  public dialogDisplayRun = null;

  // @ts-ignore MarkerClusterer defined via script
  public clusterManager: MarkerClusterer;

  public options: TableOptionsModel;

  public parameterForSearch = {
    minDistance: '',
    maxDistance: ''
  }

  constructor(private authService: AuthService,
              private publicRunsService: PublicRunsService,
              public dialog: MatDialog,
              private commonService: CommonService,
              public snackBar: MatSnackBar,
              private zone: NgZone,
              private router: Router) {
    super(dialog, snackBar);
  }

  @ViewChild('publicRunTable')
  public publicRunTable;

  public currentPage = 0;

  ngOnInit(): void {
    this.loading.user = true;
    this.createTableOptions();
    this.authService.isUserPremium().subscribe(t => {
      this.loading.user = false;
      if (t.result && t.result.data) {
        this.userPremInformation = t.result.data[0];
        const info = t.serverTime;
        this.isPremium = this.userPremInformation.paidPeriodEndDateBuffer &&
          this.userPremInformation.paidPeriodEndDateBuffer >= info;
        this.filterList.country = this.userPremInformation.locationDetails.countryCode;
        this.filterList.state = this.userPremInformation.locationDetails.stateCode;
        this.initInfoAboutCountries();
      }
    }, () => {
      this.loading.user = false;
    });
    this.userPreferences();
    this.getJoinedPublicRunsList();
  }

  private createTableOptions() {
    this.options = new TableOptionsModel();
    this.options.tooltip = (t) => {
      switch (t.runStatus) {
        case UserToRunStatusEnum.ACTIVE:
        case UserToRunStatusEnum.NOT_YET_STARTED: {
          return !!this.myJoinedPublicRuns[t.id] ? 'You are already part of this run' : 'Click to join this run';
        }
        case UserToRunStatusEnum.DONE: {
          return 'Run completed - no longer possible to join';
        }
      }
    };
    this.options.disableOptions = new TableOptionsDisableModel();
    this.options.disableOptions.disableActivation = (t) => {
      return !!this.myJoinedPublicRuns[t.id] || (t.runStatus === +UserToRunStatusEnum.DONE);
    }
  }

  private getJoinedPublicRunsList() {
    this.loading.myRuns = true;
    this.publicRunsService.getJoinedPublicRuns().subscribe(t => {
      this.loading.myRuns = false;
      if (t && t.result && t.result.data) {
        const data = t.result.data || [];
        data.forEach(y => {
          this.myJoinedPublicRuns[y.id] = y;
        });
      }
    }, () => {
      this.loading.myRuns = false;
    });
  }

  public displayList(init?, lat?, lng?) {
    if (this.filterList.country) {
      this.loading.runs = true;
      this.publicRunsService
        .getPublicRuns(this.filterList.country,
          this.filterList.state, this.parameterTable.currentPage,
          this.parameterTable.pageSize, this.parameterTable.sorts,
          this.calculateDistance(this.parameterForSearch.minDistance),
          this.calculateDistance(this.parameterForSearch.maxDistance)
          ).subscribe(t => {
            this.displayedInfo.all = true;
            if (t && t.result && t.result.data) {
              this.publicRuns = t.result.data || [];
              this.totalCount = t.result.totalCount || 0;
              this.publicRunsCount = {};
              this.initMap(this.publicRuns,
                this.countriesObject[this.filterList.country], this.filterList.country,
                this.stateObject[this.filterList.state], this.filterList.state,
                init, lat, lng);
            }
            this.loading.runs = false;
      }, () => {
        this.loading.runs = false;
      });
    }

  }

  private prepareLatLonData(array) {
    return array.map(t => {
      return {
        lat: t.startingPointLatitude,
        lng: t.startingPointLongitude
      };
    })
  }

  private initInfoAboutCountries() {
    this.loading.countries = true;
    this.publicRunsService.getCountries().subscribe(t => {
      this.loading.countries = false;
      if (t.result) {
        this.countries = t.result.data || [];
      }
      this.loading.states = true;
      this.publicRunsService.getStates().subscribe( t=> {
        this.loading.states = false;
        if (t.result) {
          this.states = t.result.data || [];
          this.mapperForCountries();
        }
        this.displayList(true,
          this.userPremInformation.locationDetails.registrationLatitude,
          this.userPremInformation.locationDetails.registrationLongitude);
      }, () => {
        this.loading.states = false;
      });
    }, () => {
      this.loading.countries = false;
    });
  }

  private mapperForCountries() {
    this.countries.forEach(t => {
      this.countriesObject[t.code] = t.name;
    });
    this.states.forEach(t => {
      this.stateObject[t.code] = t.name;
    });
  }

  public isLoaded() {
    return Object.keys(this.loading).some(y => this.loading[y]);
  }

  public updateStateInfo() {
    if (this.filterList.country !== 'US') {
      this.filterList.state = '';
    }
  }

  public nextPageList($event) {
    this.parameterTable.currentPage = $event.pageIndex;
    this.parameterTable.pageSize = $event.pageSize;
    if (this.displayedInfo.all) {
      this.displayList();
    } else {
      this.displayMultiplePointsForCluster(this.displayedInfo.lat, this.displayedInfo.lng);
    }
  }

  public initTable() {
    if (this.publicRunTable) {
      this.publicRunTable.resetPaginator();
    }
  }

  public sortList($event) {
    if ($event.direction) {
      const t: any =
        {field: $event.active, desc: $event.direction === 'desc'};
      this.parameterTable.sorts = [t];
      const columninfo = this.publicRunTableColumns.find(t => t.field === $event.active);
      if (columninfo && columninfo.extraSort) {
        columninfo.extraSort.forEach(t => {
          t.desc = $event.direction === 'desc';
          this.parameterTable.sorts.push(t);
        });
      }
    } else {
      this.parameterTable.sorts = [{field: 'name', desc: true}];
    }
    this.displayList();
  }

  private resetMarkers() {
    if (this.map && this.markers) {
      this.markers.forEach(t => {
        t.setMap(null);
      });
      this.markers = [];
      if (this.clusterManager) {
        this.clusterManager.clearMarkers();
      }
      // @ts-ignore MarkerClusterer defined via script
      new MarkerClusterer(this.map, this.markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        maxZoom: 7,
     //   zoomOnClick: false
      });
    }
  }

  private initMap(locations, country, countryCode, state, stateCode, init?, lat?, lng?) {

      this.resetMarkers();

      if (!this.map) {
        const opt = {
          zoom: 4.5,
          center: { lat: (country && country !== 'ALL') ? lat || 0 : 0,
            lng: (country && country !== 'ALL') ? lng || 0 : 0},
          maxZoom: 20
        };
        this.map = new google.maps.Map(
          document.getElementById("publicRunsId") as HTMLElement,
          opt
        );
      } else {

      }
      if (country && country !== 'ALL') {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode( {'address' : `${country}, ${countryCode}` + (state ? `${state}, ${stateCode}` : '')},
          (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            // this.map.setCenter(results[0].geometry.location);
            this.map.fitBounds(results[0].geometry.viewport);
          }
        });
      } else {
        this.map.setZoom(5);
        this.map.setCenter({lat: 0, lng: 0})
      }

    // Add some markers to the map.
      // Note: The code uses the JavaScript Array.prototype.map() method to
      // create an array of markers based on a given "locations" array.
      // The map() method here has nothing to do with the Google Maps API.
      this.markers = locations.map((location, i) => {

         const option = {
           position: {
             lat: location.startingPointLatitude,
             lng: location.startingPointLongitude
           },
           label: ''
         };

        const marker = new google.maps.Marker(option);

        if (!this.publicRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude]) {
          this.publicRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] = 0;
        }
        this.publicRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] =
          this.publicRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] + 1;

        marker.addListener('click', (e,y) => {
          if (e && e.vb) {
            e.vb.stopPropagation();
          }
          this.zone.run(() => {
            if (this.publicRunsCount[location.startingPointLatitude + '_' + location.startingPointLongitude] > 1) {

              this.displayDialog(DialogConfirmationComponent,
                {
                  message: 'There are multiple runs on this point, would you like to see them? ',
                  type: 'Confirmation'
                }, (result) => {
                  if (result) {
                    this.router.navigate(['user/runs/location/public'],
                      { queryParams: {lat: location.startingPointLatitude, lng: location.startingPointLongitude}
                      });
                  }
                }, () => {});
            } else {
              this.displayRunPopupFromMarker(location);
            }
          })
        }, {passive: true});
        return marker;
      });
      this.updateMarkerIcons();
      // Add a marker clusterer to manage the markers.
      // @ts-ignore MarkerClusterer defined via script
      this.clusterManager = new MarkerClusterer(this.map, this.markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        maxZoom: 7,
        zoomOnClick: false
      });

      google.maps.event.addListener(this.clusterManager,
        'clusterclick', (cluster) => {
          this.map.setZoom(environment.maximumClusterZoom * 1.25);
          this.map.setCenter(cluster.center_);
         // if (cluster.map_.zoom > PUBLIC_RUN_MAP_ZOOM) {
         //   this.zone.run(() => {
         //     this.displayedInfo.lat = cluster.center_.lat();
         //     this.displayedInfo.lng = cluster.center_.lng();
         //     this.displayDialog(DialogConfirmationComponent,
         //       {
         //         message: 'There are multiple runs on this point, would you like to see them? ',
         //         type: 'Confirmation'
         //       }, (result) => {
         //         if (result) {
         //           this.parameterTable.currentPage = 1;
         //           this.displayMultiplePointsForCluster(this.displayedInfo.lat, this.displayedInfo.lng, true);
         //         }
         //       }, () => {});
         //   })
         // }
      });
  }

  private updateMarkerIcons() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setIcon(this.displayLocationInfoIcon(this.markers[i].getPosition().lat() + '_' + this.markers[i].getPosition().lng()));
    }
  }

  private displayLocationInfoIcon(location) {
    return this.publicRunsCount[location] > 1 ?
      {
        url: environment.mapMultipleMarkerUrl,
        scaledSize: new google.maps.Size(30, 45), // scaled size
        // origin: new google.maps.Point(0,0), // origin
        // anchor: new google.maps.Point(0, 0) // anchor
      } : null
  }

  private displayMultiplePointsForCluster(lat, lng, init?) {
    this.loading.runs = true;
    this.publicRunsService.getPublicMultiRuns(
      null,
      null, lat, lng,
      this.parameterTable.currentPage,
      this.parameterTable.pageSize, this.parameterTable.sorts
    ).subscribe(t => {
      this.displayedInfo.all = false;
      if (init) {
        this.initTable();
      }
      if (t && t.result && t.result.data) {
        this.publicRuns = t.result.data || [];
        this.totalCount = t.result.totalCount || 0;
        this.initMap(this.publicRuns,
          this.countriesObject[this.filterList.country], this.filterList.country,
          this.stateObject[this.filterList.state], this.filterList.state,
          true, lat, lng);
      }
      this.loading.runs = false;
    }, () => {
      this.loading.runs = false;
    });
  }

  private displayRunPopupFromMarker(location) {
    this.displayRunInfo(location);
  }

  private userPreferences() {
    this.publicRunsService.getUserPreferences()
      .subscribe((preferences) => {
      if (preferences) {
        this.preferences = preferences.result.data;
        const distance = this.preferences.find(t => {
          return t.keyword === MapEnum.DISTANCE_FORMAT;
        });
        if (distance) {
          this.distance = this.commonService.distanceMapper(distance.value);
        }
      }
    })
  }

  public joinRun(item, callback?, ignoreLoading?) {
    this.displayDialog(DialogConfirmationComponent, {
      type: 'Join Public Run',
      message: '',
      okResponse: 'Join'
    }, () => {
      this.joinRunHandler(item, callback, ignoreLoading);
    }, () => {

    });
  }

  public joinRunFromPopup(item, callback?, ignoreLoading?) {
    this.joinRunHandler(item, callback, ignoreLoading);
  }

  private joinRunHandler(item, callback?, ignoreLoading?) {
    this.loading.rsvp = !ignoreLoading;
    this.publicRunsService
      .sendRsvpForPublicRun(RsvpResponseEnum.YES, item.id)
      .subscribe(t => {
        this.loading.rsvp = false;
        if (t && t.result && t.result.data && t.result.data[0]) {
          if (callback) {
            callback();
          }
          this.myJoinedPublicRuns[t.result.data[0].runId] = t.result.data[0];

          this.displayDialog(DialogMessageComponent, {
            type: MessageTypeEnum.SUCCESS,
            explanation: 'You were joined',
            message: ''
          }, () => {}, () => {});


        }
      }, () => {
        this.loading.rsvp = false;
      });
  }

  public setValues(minValue, maxValue) {
    this.parameterForSearch.minDistance = minValue;
    this.parameterForSearch.maxDistance = maxValue;
  }

  public resetDistanceParams(minDistance, maxDistance) {
    minDistance.value = '';
    maxDistance.value = '';
    this.parameterForSearch.maxDistance = '';
    this.parameterForSearch.minDistance = '';
  }

  private calculateDistance(value) {
    return this.distance === 'km' ? value : value * DISTANCE_CONST;
  }

  public displayRunInfo(run) {
    if (!this.dialogDisplayRun) {
      this.dialogDisplayRun = this.displayDialog(PublicRunDetailsComponent, {
        run,
        isJoined: this.myJoinedPublicRuns[run.id] || (run.runStatus === UserToRunStatusEnum.DONE),
        distance: this.distance,
        joinRun: (run, callback) => {
          this.joinRunFromPopup(run, callback, true);
        }
      }, () => {
        this.dialogDisplayRun = null;
      }, () => {
        this.dialogDisplayRun = null;
      });
    }
  }

  public refresh() {
    this.parameterTable.currentPage = 1;
    this.initTable();
    this.displayList()
  }

}
