import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { CommonService } from "../../../core/services/common.service";
@Component({
  selector: 'app-display-image',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.scss']
})
export class ImageDisplayComponent implements OnInit {

  public constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                     public dialogRef: MatDialogRef<ImageDisplayComponent>,
                     private commonService: CommonService) {}
  public image: string;
  public storedImage: string;

  public imageCropped(event: ImageCroppedEvent) {
    this.storedImage = event.base64;
  }

  public imageLoaded() {
  }

  public cropperReady() {
  }

  public loadImageFailed() {

  }

  ngOnInit(): void {
    this.image = this.data.image;
  }

  public saveResultImage() {
    this.dialogRef.close({
      image: this.commonService.base64ToFile(
        this.storedImage,
        new Date().toUTCString(),
      ),
      base64: this.storedImage
    });
  }

}
