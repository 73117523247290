<div>
  <mat-progress-bar *ngIf="isLoaded()" mode="query"></mat-progress-bar>
</div>
<app-main-page-title [title]="'Stats'"></app-main-page-title>
<div class="statistics-container">
  <div class="header"></div>
  <div class="run-statistics">
    <div class="run-statistics-info">
      <ng-container>
        <mat-card>
          <mat-card-title>
            <div class="header">Statistics</div>
          </mat-card-title>
          <mat-card-content>
            <mat-progress-spinner diameter="20" mode="indeterminate" *ngIf="loading.stats"></mat-progress-spinner>
            <div *ngIf="response">
              <div class="item-line">
                <span class="title">Trip started: </span>
                <span>{{response.startedRunsCount}}</span>
              </div>
              <div class="item-line">
                <span class="title">Total distance: </span>
                <span>{{response.totalDistanceCovered | kmDistance : distance || 'km'}}</span>
              </div>
              <div class="item-line">
                <span class="title">Longest trip: </span>
                <span>{{response.longestRun | kmDistance : distance || 'km'}}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-title>
            <div class="header">Activity Stats</div>
          </mat-card-title>
          <mat-card-content>
            <mat-progress-spinner diameter="20" mode="indeterminate" *ngIf="loading.stats"></mat-progress-spinner>
            <div *ngIf="response">
              <div class="item-line">
                <span class="title">Friends: </span>
                <span>{{response.friendCount}}</span>
              </div>
              <div class="item-line">
                <span class="title">Lifetime Packs: </span>
                <span>{{response.packsCount}}</span>
              </div>
              <div class="item-line">
                <span class="title">Members in your current packs: </span>
                <span>{{response.currentPackMembersCount}}</span>
              </div>
              <div class="item-line">
                <span class="title">Unique Fellow Riders - Lifetime: </span>
                <span>{{response.totalUniqueFellowRidersCount}}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
    <div id="runsId"></div>
  </div>
  <div class="header"></div>
  <div>
    <mat-card>
      <mat-card-title>
        <div class="header">Trip activity</div>
      </mat-card-title>
      <div class="year-selection">
        <mat-form-field>
          <mat-label>Year</mat-label>
          <mat-select [(ngModel)]="currentYear"
                      (ngModelChange)="checkDisplayData()" [disabled]="loading.runsForStats">
            <mat-option [value]="">All</mat-option>
            <mat-option *ngFor="let year of yearList"
                        [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-progress-spinner diameter="20" mode="indeterminate" *ngIf="loading.runsForStats"></mat-progress-spinner>
      <div echarts [options]="option" class="statistics-chart" *ngIf="!loading.runsForStats && option.xAxis.data.length > 0"></div>
    </mat-card>
  </div>
  <ng-container>
    <mat-card>
      <mat-card-title>
        <div class="header">Trophies</div>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-progress-spinner diameter="20" mode="indeterminate" *ngIf="loading.stats"></mat-progress-spinner>
        </div>
        <div class="trophies-list" *ngIf="response && !loading.stats">
          <img [src]="img.tag.pictureUrl" *ngFor="let img of response.userTags" class="img-item" (click)="displayMoreInfo(img)"
               [id]="'STAT_TROPHY' | gtmTag"/>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>

