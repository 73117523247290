<div>
  <div>
    <div class="header {{data.type}}"></div>
    <div class="message">
      <div class="premium-explanation">
        To create a trip with waypoints, you will need to become a WolfPack Premium user.
      </div>
      <div class="payment-type">
        <div class="payment-selection">
          <div class="payment-description">
            Payment Type
          </div>
          <div>
            <mat-radio-group [(ngModel)]="paymentType">
              <mat-radio-button [value]="item"
                                name="payment-type"
                *ngFor="let item of paymentsList" [disabled]="loading">
                {{item.periodType + 'ly'}} (${{item.amount}} / {{item.periodType}})
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="paypal-button-wrapper" [id]="'ACCOUNT_UPGRADE_TO_PREMIUM' | gtmTag">
          <div class="paypal-button" (click)="initPayment()" [id]="'ACCOUNT_UPGRADE_TO_PREMIUM' | gtmTag">
            Get Wolfpack Premium
          </div>
        </div>
<!--        <div class="support-cards">-->
<!--          <img src="assets/images/pay-paypal.png" class="card-item"/>-->
<!--          <img src="assets/images/pay-visa.png" class="card-item"/>-->
<!--          <img src="assets/images/pay-master.png" class="card-item"/>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>
<div class="btn-submit-container">
  <button mat-button class="btn-submit"
        color="primary" mat-dialog-close="true">No, thanks</button>
</div>
<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
