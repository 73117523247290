import {Component, Inject, OnInit} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackService } from './feedback.service';
import { AuthService } from '../../core/services/auth.service';
import {UserModel} from "../../models/user.model";
import {DialogMessageComponent} from "../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../enums/message-type.enum";
import {BaseComponent} from "../../shared/classes/base.component";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {DOCUMENT} from "@angular/common";
import {PacksService} from "../user/packs/packs.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent extends BaseComponent implements OnInit {

  public loading = false;
  public features = [];
  private userInfo: UserModel;

  public feedback = {
    feature: '',
    comments: ''
  };

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private feedBackService: FeedbackService,
                     private authService: AuthService,
                     public dialog: MatDialog,
                     public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.loading = true;
      this.authService.isUserPremium().subscribe(t => {
        if (t.result && t.result.data) {
          this.userInfo = t.result.data;
        }
        this.feedBackService.feedbackCategories().subscribe(t => {
          if (t && t.result && t.result.data) {
            this.features = t.result.data.filter(t => environment.feedbackItemsDisplayIds.indexOf(t.id) != -1);
          }
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }, () => {
        this.loading = false;
      });

  }

  public postFeedBack() {
    if (!this.feedback.feature || !this.feedback.comments) {
      return;
    }
    const object = {
      message: this.feedback.comments,
      userName: this.userInfo.firstName + ' ' + this.userInfo.lastName,
      userEmail: this.userInfo.username,
      feedbackCategoryId: this.feedback.feature,
      devicePlatformId: 3
    };
    this.loading = true;
    this.feedBackService.pushFeedback(object).subscribe(t => {
      this.loading = false;
      if (t && t.result && t.result.data) {
        this.feedback.feature = '';
        this.feedback.comments = '';
        this.displayDialog(DialogMessageComponent, {
          type: MessageTypeEnum.SUCCESS,
          explanation: 'Thank you for your feedback!',
          message: ''
        }, () => {}, () => {});
      }
    }, () => {
      this.loading = false;
    });
  }

  public feedbackTypeSelection() {

  }

}
