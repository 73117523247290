import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as gtmTags from '../configs/gtm-tag.config';

@Pipe({ name: 'gtmTag' })
export class GtmTagPipe implements PipeTransform {
  transform(value: string): string {
    return gtmTags.default[value] ; // + (environment.production ? '' : ('_' + environment.environment));
  }
}
