<div class="app-container">
  <mat-sidenav-container class="app-sidenav-container"
                          autosize>
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 *ngIf="isDisplayedNavBar"
                 [fixedInViewport]="mobileQuery.matches"  [ngClass]="{'hide-menu': !isDisplayedNavBar}"
                 opened="true" position="start" role="navigation">
      <div class="menu-logo menu-icon-container" [ngClass]="{'collapsed': !!snav && !snav.opened}" >
        <img [src]="!(!!snav && !snav.opened) ? 'assets/images/app-logo-header.png' : '/assets/images/wolf_pic.png'"/>
      </div>
      <mat-nav-list [ngClass]="{'small-icons-nav-list': (snav && !snav.opened)}">
        <a mat-list-item (click)="navigateTo('user/runs');" class="navigation-item" [ngClass]="{'current': currentUrl === '/user/runs'}">
          <mat-icon> map</mat-icon>
          <span>Trips</span>
        </a>
        <a mat-list-item (click)="navigateTo('user/mypacks');"  class="navigation-item" [ngClass]="{'current': currentUrl === '/user/mypacks'}">
          <mat-icon>people</mat-icon>
          <span>Packs</span>
        </a>
        <a mat-list-item (click)="navigateTo('user/run-library');"  class="navigation-item" [ngClass]="{'current': currentUrl === '/user/run-library'}">
          <mat-icon> local_library</mat-icon>
          <span>Trip library</span>
        </a>
        <a mat-list-item (click)="navigateTo('user/public-runs');"  class="navigation-item" [ngClass]="{'current': currentUrl === '/user/public-runs'}">
          <mat-icon>public</mat-icon>
          <span>Public trips</span>
        </a>
        <a mat-list-item (click)="navigateToExternal($event);"  class="navigation-item" [ngClass]="{'current': currentUrl === '/user/faq'}">
          <mat-icon>help_outline</mat-icon>
          <span>Help</span>
        </a>
        <a mat-list-item (click)="navigateTo('user/feedback');"  class="navigation-item" [ngClass]="{'current': currentUrl === '/user/feedback'}">
          <mat-icon>chat_bubble</mat-icon>
          <span>Leave Feedback</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="accent" *ngIf="isDisplayedNavBar" [ngClass]="{'navigation-item-menu': !!snav && !snav.opened}">
        <button mat-icon-button class="example-icon"
                (click)="snav.toggle()" aria-label="icon-button with menu icon" >
          <mat-icon>menu</mat-icon>
        </button>
        <div class="menu-navigation">
        </div>
        <span class="user-info">
      <button mat-raised-button color="warn" (click)="navigateTo('user/stats');" class="btn-itm-navigation-top">
        <mat-icon class="btn-title-icon">poll</mat-icon>
        <span class="btn-title-content">My stats</span>
      </button>
      <button mat-raised-button color="warn" (click)="navigateTo('user/profile');" class="btn-itm-navigation-top">
        <img [src]="getUser()?.pictureUrl" onerror="this.src='assets/images/default-user.png';" class="user-info-logo"/>
        <span class="btn-title-content">My settings</span>
      </button>
      <span class="logout-icon">
         <mat-icon (click)="snav.toggle(); logout();">exit_to_app</mat-icon>
      </span>
  </span>
      </mat-toolbar>
      <div class="app-content" [ngClass]="{'app-content-closed': !!snav && !snav.opened, 'full': !isDisplayedNavBar}">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
