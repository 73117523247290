import { NgModule } from '@angular/core';
import { RunTableComponent } from './run-table.component';
import { MaterialModule } from '../../external-modules/material.module';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../../../core/core.module';
import {MapViewModule} from '../map-view/map-view.module';
// import {AgmCoreModule} from '@agm/core';

@NgModule({
  declarations: [
    RunTableComponent,
    RunTableComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    CoreModule,
    MapViewModule,
    // AgmCoreModule
  ],
  providers: [],
  exports: [
    RunTableComponent
  ],
  bootstrap: [RunTableComponent]
})
export class RunTableModule { }

