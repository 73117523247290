import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { genderList } from 'src/app/consts/gender';
import {BaseComponent} from "../../../shared/classes/base.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {PremiumAccountComponent} from "../../../shared/components/premium-account/premium-account.component";
import {ImageDisplayComponent} from "../../../shared/components/image-cropper/image-display.component";
import {comparePasswordValidator} from "../../../shared/validators/compare-password.validor";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {DISTANCES} from "../../../consts/distances";
import {MapEnum} from "../../../enums/map.enum";
import {EMAIL_MAPPER, EMAIL_PREFERENCES, ON_RUN_MESSAGES} from "../../../consts/on-run-communications";
import {CommonService} from "../../../core/services/common.service";
import {AppStorageService} from "../../../core/services/app-storage.service";
import {StorageEnum} from "../../../enums/storage.enum";
import {phoneCodeValidator} from "../../../shared/validators/phone-code.validor";
import {DialogMessageComponent} from "../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../enums/message-type.enum";
import base64ToFile from "../../../shared/functions/base64-to-file";
import resizeImage from "../../../shared/functions/resize-image";
import {NgxImageCompressService} from "ngx-image-compress";
import {VendorTypeEnum} from "../../../enums/vendor-type.enum";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {

  public countries = [];
  public states = [];
  public distances = DISTANCES;
  public currentPage = 0;
  VendorTypeEnum = VendorTypeEnum;
  public preferencesObj = {
    distance: 0,
    isAvoidTools: false,
    isAvoidHighways: false,
    isAvoidFerries: false,
    onRunMessages: [],
    onEmailPreferences: []
  }

  public loading = {
    countries: false,
    states: false,
    profile: false,
    preferences: false,
    charities: false
  };
  public user;
  public profileForm;
  public changePasswordForm;

  public genderList = genderList;
  public isPremium = false;
  public nextPaymentDate;
  public vendorType;
  public lastSubscriptionId;

  public get password() {
    return this.changePasswordForm.
      controls.newPassword;
  }
  // public get oldPassword() {
  //   return this.changePasswordForm.
  //     controls.oldPassword;
  // }

  public get confirmPassword() {
    return this.changePasswordForm.
      controls.confirmPassword;
  }
  public preferences: any[] = [];
  public charities;
  public isRegularLogin = true;

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private profileService: ProfileService,
                     public dialog: MatDialog,
                     public snackBar: MatSnackBar,
                     private commonService: CommonService,
                     private storageService: AppStorageService,
                     private imageCompress: NgxImageCompressService) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.initData();
    this.initAccountForm(undefined);
    this.initPasswordForm();
    this.loadPreferences();
  }

  private loadPreferences() {
    this.loading.preferences = true;
    this.profileService.getPreferences().subscribe(t => {
      if (t.result && t.result.data) {

        this.preferences = t.result.data || [];
        const distance = this.preferences
          .find(t => t.keyword === MapEnum.DISTANCE_FORMAT);
        if (distance) {
          this.preferencesObj.distance = +distance.value;
        }
        const tolls = this.preferences
          .find(t => t.keyword === MapEnum.TOLLS);
        if (tolls) {
          this.preferencesObj.isAvoidTools = +tolls.value === 1;
        }
        const highways = this.preferences
          .find(t => t.keyword === MapEnum.HIGHWAYS);
        if (highways) {
          this.preferencesObj.isAvoidHighways = +highways.value === 1;
        }
        const ferries = this.preferences
          .find(t => t.keyword === MapEnum.FERRIES);
        if (ferries) {
          this.preferencesObj.isAvoidFerries = +ferries.value === 1;
        }

        const preferences =
          this.preferences.filter(t =>
            ON_RUN_MESSAGES.indexOf(t.keyword) !== -1);

        const emailPreferences =
          this.preferences.filter(item =>
            EMAIL_PREFERENCES.indexOf(item.keyword) !== -1);

        this.preferencesObj.onRunMessages = JSON.parse(JSON.stringify(preferences));
        this.preferencesObj.onEmailPreferences = JSON.parse(JSON.stringify(emailPreferences)).map(item => {
          return {
            ...item,
            name: EMAIL_MAPPER[item.keyword],
            value: item.value === 'true' || !item.value ? true : false
          };
        });

      }
      this.loading.preferences = false;
    }, () => {
      this.loading.preferences = false;
    });
  }

  private initAccountForm(profile?) {
    this.profileForm = this.builder.group({
      firstName: [{value: profile ? profile.firstName : '', disabled: false}, [Validators.required]],
      lastName: [{value: profile ? profile.lastName : '', disabled: false}, [Validators.required]],
      nickname: [{value: profile ? profile.nickname : '', disabled: false}, []],
      gender: [{value: profile ? profile.gender : '', disabled: false}, []],
      email: [{value: profile ? profile.username : '', disabled: true}, []],
      phone: [{value: profile ? profile.phone : '', disabled: false}, []],
      charity: [{value: profile && profile.charity ? profile.charity.id : 0, disabled: false}, []],
      country: [{value: profile && profile.locationDetails ?
          profile.locationDetails.countryCode : '', disabled: true}, []],
      state: [{value: profile && profile.locationDetails ?
          profile.locationDetails.stateCode : '', disabled:
          !!profile && !!profile.locationDetails &&
          profile.locationDetails.countryCode !== 'US'}, []],
      phoneCountryCode: [{value: profile ? profile.phoneCountryCode : '', disabled: false}, []],
      ecEmail: [{value: profile ? profile.ecEmail : '', disabled: false}, [Validators.email]],
      ecName: [{value: profile ? profile.ecName : '', disabled: false}, []],
      ecPhone: [{value: profile ? profile.ecPhone : '', disabled: false}, []],
      ecPhoneCountryCode: [{value: profile ? profile.ecPhoneCountryCode : '',
        disabled: false}, []],
      profilePrivate: [{value: profile ? profile.profilePrivate : false, disabled: false}, []],
    }, {validators: [phoneCodeValidator('phone',
        'phoneCountryCode'), phoneCodeValidator('phoneCountryCode',
        'phone'),
        phoneCodeValidator('ecPhone',
          'ecPhoneCountryCode'), phoneCodeValidator('ecPhoneCountryCode',
          'ecPhone')
      ]});
  }

  private initPasswordForm() {
    this.changePasswordForm = this.builder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    }, {validator: comparePasswordValidator('newPassword',
        'confirmPassword')});
    if (!this.isRegularLogin) {
      this.changePasswordForm.disable();
    }
  }

  private initData() {
    this.isRegularLogin = !this.storageService.getItemFromLocalStorage(StorageEnum.LOGIN);
    this.loading.countries = true;
    this.profileService.getCountries().subscribe(t => {
      this.loading.countries = false;
      if (t.result) {
        this.countries = t.result.data || [];
      }
    }, () => {
      this.loading.countries = false;
    });
    this.loading.states = true;
    this.profileService.getStates().subscribe( t=> {
      this.loading.states = false;
      if (t.result) {
        this.states = t.result.data || [];
      }
    }, () => {
      this.loading.states = false;
    });
    this.infoUser();
  }

  private infoUser() {
    this.loading.profile = true;
    this.profileService.getUserProfile().subscribe(u => {
      if (u.result && u.result.data) {
        this.user = u.result.data[0];
        this.checkAccountType(u);
        this.checkVendorTypeForSubscription();
        this.initAccountForm(this.user);
        this.loading.profile = false;
      }
    }, () => {
      this.loading.profile = false;
    });
  }

  private checkVendorTypeForSubscription() {
      if (this.lastSubscriptionId) {
        this.profileService
          .getSubscriptionVendorInfo(this.lastSubscriptionId)
          .subscribe((response) => {
            this.vendorType = response.result.data[0];
            if (this.vendorType === VendorTypeEnum.SPECIAL) {
              this.profileForm.controls.country.disable();
              this.profileForm.controls.state.disable();
            }
          });
      }
  }

  public countryChanged() {
    if (this.profileForm.controls.country.value !== 'US') {
      this.profileForm.controls.state.setValue('');
      this.profileForm.controls.state.disable();
    } else {
      this.profileForm.controls.state.enable();
    }
  }

  private checkAccountType(t) {
    const info = t.serverTime;
    this.isPremium = this.user.paidPeriodEndDateBuffer &&
      this.user.paidPeriodEndDateBuffer >= info;
    this.lastSubscriptionId = this.user.lastSubscriptionId;
    this.nextPaymentDate = this.commonService.displayDate(
      this.user.paidPeriodEndDateBuffer);
    if (this.isPremium) {
      this.loadCharities();
    } else {
      this.profileForm.controls.charity.disable();
    }
  }

  private loadCharities() {
    this.loading.charities = true;
    this.profileService.getCharities()
      .subscribe(t => {
      this.charities = t.result.data || [];
      this.loading.charities = false;
    }, () => {
      this.loading.charities = false;
    });
  }

  public isInProgress() {
    return Object.keys(this.loading).some(t => this.loading[t]);
  }

  public moveToPremium() {
    this.displayDialog(PremiumAccountComponent,
      {}, () => {}, () => {});
  }

  public updateUser() {
    if (this.profileForm.dirty &&
      !this.profileForm.invalid && this.isPhoneValid() &&
      this.isECPhoneValid()) {
      this.loading.profile = true;
      // this.profileForm.disable();
      this.profileService.updateUser(this.preparedData())
        .subscribe((t) => {
          this.loading.profile = false;
          // this.profileForm.enable();
          this.displayDialog(DialogMessageComponent, {
            type: MessageTypeEnum.SUCCESS,
            explanation: 'Profile was updated',
            message: ''
          }, () => {}, () => {});
          this.infoUser();
      }, () => {
        this.loading.profile = false;
        // this.profileForm.enable();
      });
    }
  }

  private isPhoneValid() {
    return (this.profileForm.controls.phone.value &&
      this.profileForm.controls.phoneCountryCode.value)
      || (!this.profileForm.controls.phone.value &&
        !this.profileForm.controls.phoneCountryCode.value)
  }

  private isECPhoneValid() {
    return (this.profileForm.controls.ecPhone.value &&
      this.profileForm.controls.ecPhoneCountryCode.value)
      || (!this.profileForm.controls.ecPhone.value &&
        !this.profileForm.controls.ecPhoneCountryCode.value)
  }

  private preparedData() {
    const value = this.profileForm.getRawValue();
    value.charity = {id: value.charity || 0};
    value.phone = value.phone || null;
    value.phoneCountryCode = value.phoneCountryCode || null;
    value.ecPhone = value.ecPhone || null;
    value.ecPhoneCountryCode = value.ecPhoneCountryCode || null;
    value.locationDetails =  {
      countryCode: value.country || null,
      stateCode:  value.state || null
    };
    delete value['country'];
    delete value['state'];
    return value;
  }

  public fileChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.displayDialog(ImageDisplayComponent,
        {
          image: event.target.files[0]
        },
        (result) => {
          if (result && result.image) {
            this.uploadImageHandler(result.image);
          }
        },
        () => {});
    }
  }

  private uploadImageHandler(image) {
    this.loading.profile = true;

    const file = image;
    const reader  = new FileReader();
    reader.onload = () => {
      const fileResult = base64ToFile(reader.result,
        new Date().getTime() + file.name.substring(file.name.lastIndexOf('.')));
      resizeImage(fileResult, 800, 800).then(blob => {
        this.imageCompress.compressFile(window.URL.createObjectURL(blob),
          0, 80, 90).then(
          result => {

            this.profileService.uploadImage(base64ToFile(result,
              new Date().getTime() +
              file.name.substring(file.name.lastIndexOf('.')))).subscribe((response) => {
              this.loading.profile = false;
              if (response.result && response.result.data) {
                this.user.pictureUrl = response.result.data[0].pictureUrl;
              }
            }, () => {
              this.loading.profile = false;
            });

          })
      });
    };
    reader.readAsDataURL(file);








  }

  public passwordUpdate() {
    if (this.changePasswordForm.dirty &&
      !this.changePasswordForm.invalid) {
      this.passwordUpdateHandler();
    }
  }

  public refreshPasswordPart() {
    this.initPasswordForm();
    this.changePasswordForm.reset();
    this.resetForm(this.changePasswordForm);
  }

  private passwordUpdateHandler() {
    const newPassword = this.password.value;
    this.loading.profile = true;
    this.changePasswordForm.disable();
    this.profileService.updatePassword({
      from: StorageEnum.TYPE,
      newPassword
    }).subscribe((t) => {
      this.loading.profile = false;
      this.changePasswordForm.enable();
      this.initPasswordForm();
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.SUCCESS,
        explanation: 'Password was updated',
        message: ''
      }, () => {
        this.refreshPasswordPart();
      }, () => {});
    }, (e) => {
      this.loading.profile = false;
      this.changePasswordForm.enable();
    });
  }

  public updatePreferences() {
    const preferencesobject = {};
    this.preferencesObj.onRunMessages.forEach((t, index) => {
      preferencesobject[t.keyword] = index;
    });
    this.preferencesObj.onEmailPreferences.forEach((t, item) => {
      preferencesobject[t.keyword] = item + '';
    });
    let wasUpdated = false;
    this.preferences.forEach(t => {
      if (t.keyword === MapEnum.DISTANCE_FORMAT) {
        if (+t.value != +this.preferencesObj.distance) {
          wasUpdated = true;
        }
        t.value = this.preferencesObj.distance;
      }
      if (t.keyword === MapEnum.FERRIES) {
        if (+t.value !== +((this.preferencesObj.isAvoidFerries ? '1' : '0'))) {
          wasUpdated = true;
        }
        t.value = this.preferencesObj.isAvoidFerries ? '1' : '0';
      }
      if (t.keyword === MapEnum.TOLLS) {
        if (+t.value !== +((this.preferencesObj.isAvoidTools ? '1': '0'))) {
          wasUpdated = true;
        }
        t.value = this.preferencesObj.isAvoidTools ? '1' : '0';
      }
      if (t.keyword === MapEnum.HIGHWAYS) {
        if (+t.value !== +((this.preferencesObj.isAvoidHighways ? '1' : '0'))) {
          wasUpdated = true;
        }
        t.value = this.preferencesObj.isAvoidHighways ? '1' : '0';
      }
      if (!isNaN(preferencesobject[t.keyword]) &&
        this.preferencesObj.onRunMessages[preferencesobject[t.keyword]]) {
        if (t.value !== this.preferencesObj.onRunMessages[preferencesobject[t.keyword]].value) {
          wasUpdated = true;
        }
        t.value = this.preferencesObj.onRunMessages[preferencesobject[t.keyword]].value;
      }
      if (!isNaN(preferencesobject[t.keyword]) &&
        this.preferencesObj.onEmailPreferences[preferencesobject[t.keyword]]) {
        if (t.value !== this.preferencesObj.onEmailPreferences[preferencesobject[t.keyword]].value) {
          wasUpdated = true;
        }
        // tslint:disable-next-line:no-construct
        t.value = new String(this.preferencesObj.onEmailPreferences[preferencesobject[t.keyword]].value);
      }
    });
    if (wasUpdated) {
      this.loading.preferences = true;
      this.profileService.updatePreferences(this.preferences)
        .subscribe((t) => {
          this.loading.preferences = false;

          this.displayDialog(DialogMessageComponent, {
            type: MessageTypeEnum.SUCCESS,
            explanation: 'Settings were updated',
            message: ''
          }, () => {}, () => {});


        }, () => {
          this.loading.preferences = false;
        });
    }
  }

  public updateTab(indexForUpdate) {
    if (this.currentPage !== indexForUpdate) {
      switch (indexForUpdate) {
        case 0: {
          this.refreshUser();
          break;
        }
        case 1: {
          this.refreshPreferences();
          break;
        }
        default: {

        }
      }

    }

  }

  public refreshUser() {
    this.infoUser();
  }

  public refreshPreferences() {
    this.loadPreferences();
  }

}
