import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {SignInService} from '../sign-in.service';

@Component({
  selector: 'app-support-facebook-login',
  templateUrl: './support-facebook-login.component.html',
  styleUrls: ['./support-facebook-login.component.scss']
})
export class SupportFacebookLoginComponent implements OnInit, AfterViewInit {

  public steps = [
    {
      step: 0,
      message: 'Set replacement username - step 1 of 2',
      title: 'Sorry, we could not find an existing account associated with your Facebook account. Please register using another method in the WolfPack app'
    },
    {
      step: 1,
      message: 'Set replacement username - step 1 of 2',
      title: 'Login with Facebook no longer supported'
    },
    {
      step: 2,
      message: 'Set replacement username - step 2 of 2',
      title: 'Set a new username and password'
    },
    {
      step: 3,
      message: 'Set replacement username - Complete',
      title: 'New username and password are saved.'
    }
  ];

  public currentStep = this.steps[1];
  public index = 1;

  public loading = false;
  public resultSuccess;

  public loginForm;
  private token: string;

  public get email() {
    return this.loginForm.controls.email;
  }

  public get password() {
    return this.loginForm.controls.password;
  }

  private initForm() {
    this.loginForm = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private signInService: SignInService,
                     private zone: NgZone,
                     ) {
    this.token = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit() {

  }

  backToSignIn() {
    this.router.navigate(['login']);
  }

  goToNextStep() {
    this.currentStep = this.steps[++this.index];
  }

  save() {
    if (!this.loginForm.invalid) {
      this.loading = true;
      this.signInService
        .migrateFB({
          username: this.email.value,
          password: this.password.value,
          facebookToken: this.token
        }).subscribe(t => {
        this.loading = false;
        this.goToNextStep();
      }, () => {
        this.loading = false;
      });
    }
  }

  facebookLogin() {
    this.loading = true;
    // @ts-ignore
    FB.login((fbResponse) => {
      this.loading = false;
      if (fbResponse.status === 'connected') {
        this.zone.run(() => {
          this.token = fbResponse.authResponse.accessToken;
          this.loading = true;
          this.signInService.migrateFBAvailabilityStatus({
            facebookToken: this.token
          }).subscribe((result) => {
            this.loading = false;
            if (result?.result?.data?.[0]) {
              console.log('Next')
              this.goToNextStep();
            } else {
              console.log('Not found')
              this.goToNotFoundStep();
            }
          }, (error) => {
            this.loading = false;
            this.goToNotFoundStep();
          });
        })
      }
    }, {scope: 'public_profile,email'});
  }

  private goToNotFoundStep() {
    this.index = 0;
    this.currentStep = this.steps[this.index];
  }

}
