<app-main-page-title [title]="'What\'s New - History'"></app-main-page-title>
<div class="what-new-container" (scroll)="scrollAction($event)">
  <div *ngFor="let new of news" class="new-item">
    <div class="news-title">{{new.title}}</div>
    <div class="new-item-obj">
      <div class="item-date">{{new.creationTime | date}}</div>
      <div class="item-message">
        <div [innerHTML]="new.message"></div>
      </div>
    </div>
  </div>
  <div class="progress-bar-itm">
    <mat-progress-bar
      class="example-margin"
      mode="indeterminate" [ngClass]="{'loading-status': loading}">
    </mat-progress-bar>
  </div>
</div>

