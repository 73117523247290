import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DialogPackMembersComponent } from './dialog-pack-members.component';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CoreModule} from '../../../../core/core.module';
import {IConfig, NgxMaskDirective, provideEnvironmentNgxMask} from 'ngx-mask';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  declarations: [
    DialogPackMembersComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        FormsModule,
        DragDropModule,
        CoreModule,
        NgxMaskDirective
    ],
  providers: [
    provideEnvironmentNgxMask(maskConfigFunction),
  ],
  bootstrap: [DialogPackMembersComponent]
})
export class DialogPackMembersModule { }
