import TableColumnModel from '../../models/tables/table-column.model';

export const packMemberList: TableColumnModel[] = [
  {
    name: 'Name',
    label: 'firstName',
    field: 'firstName',
    isSticky: true,
    initial: true,
    displayImage: true,
    displayedImgLogo: (item) => {
      {
        return item.pictureUrl;
      }
    },
    formatter: (value) => {
      return value.firstName || value.username;
    }
  },
  {
    name: 'Type',
    label: 'type',
    field: 'type',
    formatter: (value) => {
      return value.isOwner ? 'Me (Owner)' : 'Member';
    }
  },
  {
    name: 'WolfPack ID',
    label: 'uniqueId',
    field: 'uniqueId',
    pipe: 'packID'
  },
  {
    name: '',
    label: 'action',
    field: '',
    isAction: true
  },
];
