<div class="sign-in-container">
    <mat-card class="sign-in-card">
      <mat-card-title>Please Login</mat-card-title>
      <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="login(LoginTypeEnum.token)">
          <div class="sign-in-img-logo">
            <img src="assets/images/wolf-logo.png"/>
          </div>
          <div class="sign-in-inputs">
            <mat-form-field>
              <mat-label>User Name</mat-label>
              <input matInput formControlName="email"
                     [regexReplace]="'email'">
            </mat-form-field>
            <div *ngIf="!email?.valid && email.touched && email.errors.required" class="error-message">
              Required
            </div>
            <div *ngIf="!email?.valid && email.touched && email.errors.email" class="error-message">
              Invalid email
            </div>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" type="password"
                     [regexReplace]="'password'">
            </mat-form-field>
            <div *ngIf="!password?.valid && password.touched && password.errors.required" class="error-message">
              Required
            </div>
          </div>
          <mat-card-footer>
            <div class="sign-in-btn-grp">
              <button mat-raised-button class="btn-item btn-item-general green-primary" type="submit" [disabled]="loading">Login</button>
              <div class="sign-in-items-external">
                <button mat-raised-button class="btn-item btn-item-facebook blue-primary"
                        type="button" [disabled]="loading"
                        (click)="login(LoginTypeEnum.FACEBOOK)">Login with Facebook</button>
              </div>
              <div id="appleid-signin"
                   type="button"
                   data-color="black" data-border="true" data-type="sign in" style="margin: auto;font-size: 1rem;
                 height:45px;margin-top:5px;">
                Sign In with Apple
              </div>
              <button mat-raised-button class="btn-item btn-item-general gray-primary"
                      type="button" (click)="redirectOnPage()" [disabled]="loading">Login with QR</button>
            </div>
            <div class="sign-in-links">
              <span class="sign-in-link primary-link" (click)="goToRestorePage()">Forgot password</span>
              <span class="sign-in-link primary-link"(click)="goToLPPage()">Back to landing page</span>
            </div>
            <div class="sign-in-request" *ngIf="loading">
              <mat-progress-bar mode="query"></mat-progress-bar>
            </div>
          </mat-card-footer>
        </form>
      </mat-card-content>
      <div>
        <h3 class="download-title">Download the app</h3>
        <div class="install-app-img-btn-container">
          <img src="assets/images/App_Store_Badge.png"
               (click)="navigateUrl('https://itunes.apple.com/us/app/wolfpack-get-there-together/id1115818510?mt=8')" class="install-app-img-btn"/>
          <img src="assets/images/google-play-badge.png"
               (click)="navigateUrl('https://play.google.com/store/apps/details?id=com.productsavvy.wolfpack&hl=en_US ')" class="install-app-img-btn google"/>
        </div>
      </div>
    </mat-card>
</div>
