<div class="rsvp-container">
  <mat-tab-group (selectedIndexChange)="checkTab($event)">
    <mat-tab label="RSVP" *ngIf="type !== RunTypeEnum.LOGBOOK">
      <div class="answer-content">
        <div class="answer" [ngClass]="{'yes-answer': RsvpStatusEnum.YES === status}"
             (click)="selectStatus(RsvpStatusEnum.YES)">Yes</div>
        <div class="answer " [ngClass]="{'maybe-answer': RsvpStatusEnum.MAYBE === status}"
             (click)="selectStatus(RsvpStatusEnum.MAYBE)">Maybe</div>
        <div class="answer "  [ngClass]="{'no-answer': RsvpStatusEnum.NO === status}"
             (click)="selectStatus(RsvpStatusEnum.NO)">No</div>
        <div class="answer friends">+{{countOfFriends}} Friends</div>
      </div>
      <div class="friend-counter">
        <button mat-icon-button (click)="minusFriend()">
          <mat-icon>remove</mat-icon>
        </button>
        <span>{{countOfFriends}}</span>
        <button mat-icon-button (click)="plusFriend()">
          <mat-icon>person_add</mat-icon>
        </button>
      </div>
      <div class="rsvp-btn-container-actions">
        <div class="rsvp-btn" [ngClass]="{'disabled': !status || loading}" (click)="saveRsvp()"
             [id]="'RSVP_DIALOG_SAVE_RSVP' | gtmTag">
          Save
        </div>
        <button mat-button mat-dialog-close="" mat-raised-button>
          Close
        </button>
      </div>
    </mat-tab>
    <mat-tab label="RSVP Responses">
      <ng-container *ngIf="responsesUsers">
        <div class="card-container">
          <mat-card *ngFor="let user of responsesUsers">
            <div class="rsvp-item rsvp-item-wrapper">
              <div>
                <div>{{user.firstName}} {{user.lastName}} {{user.guestsCount ? '(+' + user.guestsCount + ')' : ''}}</div>
                <div class="rsvp-item-description">
                  {{user.id === data.item.creatorId ? 'Trip Owner' : ''}}
                  {{user.isAdmin ? 'Trip admin' : ''}}
                </div>
              </div>
              <div class="answer small"
                   [ngClass]="{'yes-answer': RsvpStatusEnum.YES === user.response,
                 'maybe-answer': RsvpStatusEnum.MAYBE === user.response,
                 'no-answer': RsvpStatusEnum.NO === user.response}">
                {{displayAnswer(user)}}
              </div>
            </div>
          </mat-card>
        </div>
      </ng-container>
      <div class="rsvp-btn-container-actions">
        <button mat-button mat-dialog-close="" class="close-btn" mat-raised-button>
          Close
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="progress-status">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
</div>

