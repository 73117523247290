import { UntypedFormGroup, AbstractControl } from '@angular/forms';

// To validate password and confirm password
export function phoneCodeValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustCodeAndPhone) {
      return;
    }
    if ((control.value && !matchingControl.value) ||
      (!control.value && matchingControl.value)) {
      matchingControl.setErrors({ mustCodeAndPhone: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
