import { NgModule } from '@angular/core';
import { SignInModule } from './sign-in/sign-in.module';
import { RestoreModule } from './restore-password/restore/restore.module';
import { ResetModule } from './restore-password/reset/reset.module';
import { VerifyModule } from './verify/verify.module';
import { RunsModule } from './user/runs/runs.module';
import { FaqModule } from './user/faq/faq.module';
import { ProfileModule } from './user/profile/profile.module';
import { PaymentResultModule } from './payment-result/payment-result.module';
import { PacksModule } from './user/packs/packs.module';
import { PublicRunsModule } from './user/public-runs/public-runs.module';
import { RunLibraryModule } from './user/runs/run-library/run-library.module';
import { StatsModule } from './user/stats/stats.module';
import { LocationRunsModule } from './user/runs/location-runs/location-runs.module';
import { WhatsNewModule } from './whats-new/whats-new.module';
import { FeedbackModule } from './feedback/feedback.module';
import {SignInQrModule} from './sign-in/qr/sign-in-qr.module';
import {InvitationModule} from './user/invitation/invitation.module';
import {SupportFacebookLoginModule} from './sign-in/facebook/support-facebook-login.module';

@NgModule({
    declarations: [],
    imports: [
        SignInModule,
        RestoreModule,
        ResetModule,
        VerifyModule,
        RunsModule,
        ProfileModule,
        PaymentResultModule,
        PacksModule,
        PublicRunsModule,
        RunLibraryModule,
        StatsModule,
        LocationRunsModule,
        WhatsNewModule,
        FeedbackModule
    ],
    exports: [
        SignInModule,
        SignInQrModule,
        InvitationModule,
        RestoreModule,
        ResetModule,
        VerifyModule,
        RunsModule,
        FaqModule,
        ProfileModule,
        PaymentResultModule,
        PacksModule,
        PublicRunsModule,
        RunLibraryModule,
        StatsModule,
        LocationRunsModule,
        WhatsNewModule,
        FeedbackModule,
        SupportFacebookLoginModule
    ],
    providers: []
})
export class PageModule { }
