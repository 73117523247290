import TableColumnModel from '../../models/tables/table-column.model';
import {MODE} from "../../consts/mode-type";

export const runTableColumns: TableColumnModel[] = [

  {
    name: 'Name',
    label: 'name',
    field: 'name',
    isSticky: true,
    initial: true
  },
  {
    name: 'Pack',
    label: 'packName',
    field: 'packName',
    formatter: (t) => {
      return t.isSolo ? t.packName || 'Solo Ride' : t.isPublic ? 'Public Run' : t.packName;
    },
    extraSort: [{field: 'isPublic'}, {field: 'isSolo'}]
  },
  {
    name: 'Mode',
    label: 'mode',
    field: 'mode',
    formatter: (t) => {
      return MODE[t.mode];
    },
  },
  {
    name: 'Start Date',
    label: 'startDate',
    field: 'startDate',
    pipe: 'date'
  },
  {
    name: 'End Date',
    label: 'endDate',
    field: 'endDate',
    pipe: 'date'
  },
  // {
  //   name: 'Planned Distance',
  //   label: 'plannedDistance',
  //   field: 'distance',
  //   pipe: 'kmDistance'
  // },
  // {
  //   name: 'Distance Traveled',
  //   label: 'distanceTraveled',
  //   field: 'userDistanceCovered',
  //   pipe: 'kmDistance'
  // },
  {
    name: 'Creation Date',
    label: 'creationDate',
    field: 'creationDate',
    pipe: 'date'
  },
  {
    name: 'Pack Size',
    label: 'packSize',
    field: 'totalUsers',
  },
  {
    name: 'Alpha',
    label: 'alpha',
    field: '',
    formatter: (t) => {
      return t.alphaFirstName + ' ' + t.alphaLasttName;
    }
  },
  {
    name: 'Attended',
    label: 'attended',
    field: 'userStartDate',
    formatter: (t) => {
      return !!t.userStartDate ? ' Yes ' : 'No';
    }
  },
  {
    name: '',
    label: 'action',
    field: '',
    isAction: true
  },
  // {
  //   name: '',
  //   label: 'expandable',
  //   field: '',
  //   isAction: true,
  //   isExpand: true
  // }
];
