import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ProfileService } from './profile.service';
import { SharedModule } from '../../../shared/shared.module';
import { ImageCropperModule } from "ngx-image-cropper";
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    ProfileComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        CoreModule
    ],
  providers: [
    ProfileService
  ],
  bootstrap: [ProfileComponent]
})
export class ProfileModule { }
