import { NgModule } from '@angular/core';
import { MapViewComponent } from './map-view.component';
import { MaterialModule } from '../../external-modules/material.module';
import {CoreModule} from '../../../core/core.module';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    MapViewComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    CoreModule,
  ],
  providers: [],
  exports: [
    MapViewComponent
  ],
  bootstrap: [MapViewComponent]
})
export class MapViewModule { }

