<div class="restore-container">
  <mat-card class="restore-card">
    <mat-card-title>Type your e-mail</mat-card-title>
    <mat-card-content>
      <div class="restore-img-logo restore-logo">
        <img src="../../../../assets/images/wolf-logo.png"/>
      </div>
      <form [formGroup]="restoreForm" (ngSubmit)="restore(formDirective)" #formDirective="ngForm">
        <div class="restore-inputs">
          <mat-form-field>
            <mat-label>User Name</mat-label>
            <input matInput formControlName="email"
                   [regexReplace]="'email'">
          </mat-form-field>
          <div *ngIf="!email?.valid && email.touched && email.errors.required" class="error-message">
            Required
          </div>
          <div *ngIf="!email?.valid && email.touched && email.errors.email" class="error-message">
            Invalid email
          </div>
        </div>
        <mat-card-footer>
          <div class="restore-btn-grp">
            <button mat-raised-button class="btn-item btn-item-general green-primary" type="submit"
                    [disabled]="restoreForm.invalid || loading">Restore password</button>
          </div>
          <div class="restore-links">
            <div class="restore-link primary-link" (click)="goToSignInPage()">
              Back to Login
            </div>
          </div>
          <div class="restore-request" *ngIf="loading">
            <mat-progress-bar mode="query"></mat-progress-bar>
          </div>
        </mat-card-footer>
      </form>
    </mat-card-content>
  </mat-card>
</div>
