import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStorageService } from '../services/app-storage.service';
import { tap } from 'rxjs/operators';
import { MessageHandlerService } from '../services/message-handler.service';
import { MessageTypeEnum } from '../../enums/message-type.enum';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation.service';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private ignoredListCodes = [10004, 10022, 11200];

  constructor(private storage: AppStorageService,
              private errorService: MessageHandlerService,
              private authService: AuthService,
              private router: Router,
              private translationService: TranslationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        tap(
          (event: any) => {
            const ok = event instanceof HttpResponse ? 'succeeded' : '';
            if (ok === 'succeeded') {
              if (event.body.errors && event.body.errors.length) {
                if (event.body && event.body.errors &&  event.body.errors[0]) {
                  if (this.ignoredListCodes.indexOf(
                      event.body.errors[0].code) === -1
                  ) {
                    this.errorService.openDialog({
                      type: MessageTypeEnum.ERROR,
                      explanation: this.errorCodeMessage(event) || event.body.errors[0].explanation,
                      message: this.errorCodeMessage(event) || event.body.errors[0].message
                    }, () => {
                      if (event.body.errors[0].code === 10003) {
                        this.authService.logout(() => {
                          this.router.navigate(['login']);
                        });
                      }
                    });
                  }
                }
                throw new HttpErrorResponse({
                  error: event.body.errors,
                  headers: event.headers,
                  status: 500,
                  statusText: event.body.errors[0].message,
                  url: event.url
                });
              }
            }
          },
          error => {
            if (error.status === 403) {
              this.authService.logout(() => {
                this.router.navigate(['login']);
              });
            }
          }
        )
      );
  }

  private errorCodeMessage(event) {
    const code = event.body.errors[0].code;
    const translate = this.translationService.translate(`server_error_${code}`, false, 'server');
    return translate ||
      (event.body.errors[0].message || event.body.errors[0].explanation || 'Something went wrong');
  }

}
