<div class="progress-bar-item-container">
  <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
</div>
<app-main-page-title [title]="'Feedback'"></app-main-page-title>
<mat-card class="feedback-container">
  <div class="feedback-container-internal">
    <div class="feedback-container-header">
      Leave Feedback
    </div>
    <div class="feedback-cotainer-content">
      <div class="feedback-container-content-title">
        We hope you are enjoying WolfPack. Let us know your feedback and ideas below
      </div>
      <div class="row-item">
        <div class="row-item-container-list">
          <mat-form-field>
            <mat-label>Feature</mat-label>
            <mat-select (valueChange)="feedbackTypeSelection()" [(ngModel)]="feedback.feature">
              <mat-option *ngFor="let feature of features" [value]="feature.id">
                {{feature.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-item-container-list">
          <mat-form-field class="details-item">
            <mat-label>Details</mat-label>
            <textarea matInput [(ngModel)]="feedback.comments"
                      [regexReplace]="'emoji'" titleCase
                      class="comments"></textarea>
          </mat-form-field>
        </div>
        <div class="send-feedback-btn-container">
          <button class="send-feedback-btn" mat-raised-button (click)="postFeedBack()"
            [disabled]="!feedback.comments || !feedback.feature || loading">
            SEND FEEDBACK
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-card>

