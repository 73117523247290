import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class InvitationService {

  constructor(private apiService: ApiService) {}

  public getRegistrationDetails(token) {
    return this.apiService.get(`user/register/token/${token}`, {
    });
  }

}
