import { NgModule } from '@angular/core';
import { MainPageTitleComponent } from './main-page-title.component';
import { MaterialModule } from '../../external-modules/material.module';
import {CommonModule} from "@angular/common";
import {ImageCropperModule} from "ngx-image-cropper";

@NgModule({
  declarations: [
    MainPageTitleComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule
  ],
  providers: [],
  exports: [
    MainPageTitleComponent
  ],
  bootstrap: [MainPageTitleComponent]
})
export class MainPageTitleModule { }

