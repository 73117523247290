import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'packID'
})
export class PackIdPipe implements PipeTransform {

  transform(value: string): string {
    return value ? value.match(/.{2,3}/g).join('-') : '';
  }

}
