import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { RunsComponent } from './pages/user/runs/runs.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { RestoreComponent } from './pages/restore-password/restore/restore.component';
import { ResetComponent } from './pages/restore-password/reset/reset.component';
import {VerifyComponent} from './pages/verify/verify.component';
import {ProfileComponent} from './pages/user/profile/profile.component';
import {PaymentResultComponent} from './pages/payment-result/payment-result.component';
import {PacksComponent} from './pages/user/packs/packs.component';
import {PublicRunsComponent} from './pages/user/public-runs/public-runs.component';
import {RunLibraryComponent} from './pages/user/runs/run-library/run-library.component';
import {StatsComponent} from './pages/user/stats/stats.component';
import {LocationRunsComponent} from './pages/user/runs/location-runs/location-runs.component';
import {WhatsNewComponent} from './pages/whats-new/whats-new.component';
import {FeedbackComponent} from './pages/feedback/feedback.component';
import {SignInQrComponent} from './pages/sign-in/qr/sign-in-qr.component';
import {InvitationComponent} from './pages/user/invitation/invitation.component';
import {SupportFacebookLoginComponent} from './pages/sign-in/facebook/support-facebook-login.component';

const routes: Routes = [
  {
    path: 'login',
    component: SignInComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'login/facebook',
    component: SupportFacebookLoginComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'login/qr',
    component: SignInQrComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'restore',
    component: RestoreComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'reset/:id',
    component: ResetComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'verify/:id',
    component: VerifyComponent
  },
  {
    path: 'user/runs',
    component: RunsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'whats-new',
    component: WhatsNewComponent
  },
  {
    path: 'user/profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-result',
    component: PaymentResultComponent,
  },
  {
    path: 'user/mypacks',
    component: PacksComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/public-runs',
    component: PublicRunsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/run-library',
    component: RunLibraryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/stats',
    component: StatsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/runs/location/:type',
    component: LocationRunsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user/feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'traveller_invitation',
    component: InvitationComponent
  },
  {
    path: '',
    redirectTo: 'user/runs',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/user/runs'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
