// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev-api.wolfpack.run/api/',
  // apiUrl: 'https://dev-api.wolfpack.run/api/',
  // landingPage: 'https://staging-landing.wolfpack.run/',
 // apiUrl: 'https://staging-api.wolfpack.run/api/',
  landingPage: 'https://staging-landing.wolfpack.run/',
  APPLE_CLIENT_ID: 'run.wolfpack.dev',
  APPLE_REDIRECT_URI: 'https://dev.wolfpack.run/login',
  GOOGLE_KEY: 'AIzaSyD_Unz5TXpXne5IkXQts-fD0ydy_yfd7Do',
  displayMessageTime: 3000,
  paypalPaymentType: 1,
  defaultDistance: 2, /*km*/
  uniqueId: 10,
  mapAttendedMarkerUrl: 'https://files.wolfpack.run/generic-assets/small-pin-green.png',
  mapMultipleMarkerUrl: 'https://files.wolfpack.run/generic-assets/small-pin-green.png',
  mapNotAttendedMarkerUrl: 'https://files.wolfpack.run/generic-assets/small-pin-blue.png',
  firstYearForSearch: 2017,
  environment: 'local',
  emailImage: 'assets/images/email.png',
  maximumClusterZoom: 10,
  feedbackItemsDisplayIds: [1, 3, 4, 5, 7, 11],
  wssLoginUrl: 'ws://localhost:8080/api/login/ws',
  wssLoginDisconnectTimeout: 60000,
  faqPath: 'https://www.wolfpack.run/faq'
};

/*
 * For easier debugging in development mode, yo/u can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
