<mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
<div class="invitation-bg">
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="userRegistrationInfo.exists">
      <div class="invitation-panel">
        <div class="invitation-icon-container">
          <img class="invitation-icon"
               [src]="userRegistrationInfo.organizationLogo"
               onerror="this.src='assets/images/default-user.png';"/>
        </div>
        <div class="invitation-main-text">
          Your trip includes mobile device support powered by WolfPack.
          Scan the QR Code below with your mobile device to download the WolfPack app.
        </div>
        <div class="invitation-icon-container">
          <img class="invitation-img" [src]="userRegistrationInfo.registrationUrl"/>
        </div>
        <div class="invitation-text">
          Powered by WolfPack.
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!userRegistrationInfo.exists">
      <div class="invitation-panel">
        <div class="invitation-icon-container">
          <img class="invitation-image-logo" src="/assets/images/WP Logo Vertical.png"
               onerror="this.src='assets/images/default-user.png';"/>
        </div>
        <div class="invitation-main-text">
          This link is expired, invalid, or has already been used.
          Please contact {{userRegistrationInfo.organizationEmail || 'support@wolfpack.run'}} for assistance.
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
