import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { PaymentResultService } from "./payment-result.service";

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit {

  private token: string;
  private payerId: string;
  public loading = false;
  public success = false;

  public constructor(private route: ActivatedRoute,
                     private paymentResult: PaymentResultService) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.payerId = params['PayerID'];
      this.acceptPayment();
    });
  }

  private acceptPayment() {
    if (this.token && this.payerId) {
      this.loading = true;
      this.paymentResult.accept({
        transactionId: this.token,
        payerId: this.payerId
      }).subscribe(t => {
        this.loading = false;
        if (t.result && t.result.data[0]) {
          this.success = t.result.data[0]
        }
      }, () => {
        this.loading = false;
      })
    }
  }

}
