import { Injectable } from '@angular/core';
import ApiService from "../../../core/services/api.service";

@Injectable()
export class RestoreService {

  constructor(private apiService: ApiService) {}

  public restore(data) {
    return this.apiService.post('user/password/change/with/token/initiate', {
      data: {
        username: data.username
      }
    });
  }

}
