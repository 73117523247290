import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqService } from './faq.service';
import { AuthService } from '../../../core/services/auth.service';
import {isEmbed} from "../../../shared/functions/is-embed";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public loading = false;

  public faqs = [];

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private faqService: FaqService,
                     private authService: AuthService) {}

  ngOnInit(): void {
    if (this.router.url === '/user/public-faq') {
      this.getPublicFaqs();
    } else {
      if (this.authService.isLogin()) {
        this.getFaqs();
      } else {
        this.getPublicFaqs();
      }
    }
  }

  private getPublicFaqs() {
    this.loading = true;
    this.faqService.getPublicFaq().subscribe((t) => {
      this.loading = false;
      this.faqs = t.result.data || [];
    }, () => {
      this.loading = false;
    });
  }

  private getFaqs() {
    this.loading = true;
    this.faqService.getFaq().subscribe((t) => {
      this.loading = false;
      this.faqs = t.result.data || [];
    }, () => {
      this.loading = false;
    });
  }

}
