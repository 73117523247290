import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {BaseComponent} from "../../../../shared/classes/base.component";
import {DialogMessageComponent} from "../../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../../enums/message-type.enum";
import {DOCUMENT} from "@angular/common";
import {ImageDisplayComponent} from "../../../../shared/components/image-cropper/image-display.component";
import {DialogPackMembersComponent} from "../dialog-pack-members/dialog-pack-members.component";
import {TableTypeEnum} from "../../../../enums/table-type.enum";
import {packMemberList} from "../../../../shared/common-structures/pack-member.list";
import TableOptionsModel, {
  TableOptionsDisableModel,
  TableOptionsHideModel
} from "../../../../models/tables/table-options.model";
import { cloneDeep } from 'lodash-es';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {NgxImageCompressService} from "ngx-image-compress";
import base64ToFile from "../../../../shared/functions/base64-to-file";
import resizeImage from "../../../../shared/functions/resize-image";

@Component({
  selector: 'app-user-dialog-pack',
  templateUrl: './dialog-pack.component.html',
  styleUrls: ['./dialog-pack.component.scss']
})
export class DialogPackComponent extends BaseComponent implements OnInit, AfterViewInit {

  public packForm;
  public descriptionCount = 1000;
  public errorNotification = '';
  public isPremium = false;
  public loading = false;
  public packMemberColumns = packMemberList;
  public isView: boolean = false;
  public activeUsers = [];
  public TableType = TableTypeEnum;
  public uploadedImage;
  public currentUpdatedImage;
  public optionsForTable: TableOptionsModel;
  public isEditor = true;
  public user;
  public potentialActiveUsers = {
    emails: [],
    uniqueIds: [],
    newActiveUsers: []
  };

  public potentialRemovedUsers = {
    removedActiveUsers: []
  }

  @ViewChild('membersTable')
  public table;

  public constructor(private builder: UntypedFormBuilder,
     public dialogRef: MatDialogRef<DialogPackComponent>,
     public dialog: MatDialog,
     public snackBar: MatSnackBar,
     private imageCompress: NgxImageCompressService,
     @Inject(MAT_DIALOG_DATA,)
     public data: any,
     @Inject(DOCUMENT) document) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.isView = this.data.isView;
    this.user = this.data.user;
    if (this.data.pack) {
      this.data.pack = cloneDeep(this.data.pack);
    }
    this.initForm(this.data.pack);
    if (this.data.pack) {
      this.activeUsers = this.data.pack.activeUsers || [];
      const owner = this.activeUsers.find(t => t.id === this.user.id);
      if (owner) {
        owner.isOwner = true;
      }
    }
    this.initOptions();
    this.initPreferences();
  }

  private initPreferences() {
    this.isEditor = !this.data.pack || this.data.isEditor;
  }

  private initOptions() {
    this.optionsForTable = new TableOptionsModel();
    this.optionsForTable.hideOptions = new TableOptionsHideModel();
    this.optionsForTable.hideOptions.hideDeletion = (item) => {
      return this.isView;
    }
    this.optionsForTable.disableOptions = new TableOptionsDisableModel();
    this.optionsForTable.disableOptions.disableDeletion = (item) => {
      return (this.data.pack &&
        this.data.pack.alpha &&
        this.data.pack.alpha.id === item.id);
    }
  }

  private initForm(pack?) {
    this.packForm = this.builder.group({
      name: [{value: pack ? pack.name : '', disabled: false}, [Validators.required]],
      description: [{value:pack ? pack.description : '', disabled: false}],
    });
  }

  public ngAfterViewInit(): void {

  }

  public savePack() {
    if (this.packForm.invalid) {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.WARNING,
        explanation: 'Please fill required fields',
        message: ''
      }, () => {}, () => {});
      return;
    }
    if (!this.data.pack) {
      this.loading = true;
      this.data.createPack(this.packPreparation(), (pack) => {
        this.updatePackHandler(pack);
      }, () => {
        this.loading = false;
      })
    } else {
      this.loading = true;
      this.data.editPack(this.packPreparation(), (pack) => {
        this.updatePackHandler(pack);
      }, () => {
        this.loading = false;
      })
    }
  }

  private removePackMembers(pack) {
    if (this.potentialRemovedUsers.removedActiveUsers &&
        this.potentialRemovedUsers.removedActiveUsers.length > 0) {
      this.data.removeFromPack(
        {
          packId: pack.id,
          ids: this.potentialRemovedUsers.removedActiveUsers.map(t => t.id)
        },
        (pack) => {
          this.invitationSend(pack);
        }, () => {
          this.loading = false;
        })
    } else {
      this.invitationSend(pack);
    }
  }

  private invitationSend(pack) {
    if ((this.potentialActiveUsers.emails
      && this.potentialActiveUsers.emails.length > 0) ||
      (this.potentialActiveUsers.uniqueIds
        && this.potentialActiveUsers.uniqueIds.length > 0)) {
      this.data.inviteUsers(
        {
          packId: pack.id,
          emails: this.potentialActiveUsers.emails,
          uniqueIds: this.potentialActiveUsers.uniqueIds
        },
        (pack) => {
          this.loading = false;
          this.dialogRef.close({pack, isView: this.isView});
        }, () => {
          this.loading = false;
        })
    } else {
      this.loading = false;
      this.dialogRef.close({pack, isView: this.isView});
    }
  }

  private updatePackHandler(pack) {
    if (pack &&
      this.uploadedImage) {

      const file = this.uploadedImage;
      const reader  = new FileReader();
      reader.onload = () => {
        const fileResult = base64ToFile(reader.result,
          new Date().getTime() + file.name.substring(file.name.lastIndexOf('.')));
        resizeImage(fileResult, 800, 800).then(blob => {
          this.imageCompress.compressFile(window.URL.createObjectURL(blob),
            0, 80, 90).then(
            result => {

              this.data.savePackImage(
                { file: base64ToFile(result,
                    new Date().getTime() +
                    file.name.substring(file.name.lastIndexOf('.'))), packId: pack.id },
                (pack) => {
                  this.removePackMembers(pack);
                }, () => {
                  this.loading = false;
                })

            })
        });
      };
      reader.readAsDataURL(file);



    } else {
      this.removePackMembers(pack);
    }
  }

  private packPreparation() {
    const packObj = this.packForm.getRawValue();
    packObj.id = this.data.pack ? this.data.pack.id : null;
    return packObj;
  }

  public cancel() {
    this.dialogRef.close();
  }

  public fileChangeEvent(event: any): void {
    if (!this.isView && event.target.files[0]) {
      this.displayDialog(ImageDisplayComponent,
        {
          image: event.target.files[0]
        },
        (result) => {
          if (result && result.image) {
            this.uploadedImage = result.image;
            this.currentUpdatedImage = result.base64;
          }
        },
      () => {});
    }
  }

  public displayMembers() {
    this.displayDialog(DialogPackMembersComponent,
      {
        activeUsers: this.activeUsers,
        user: this.user
      },
      (result) => {
        if (result) {
          this.potentialActiveUsers.uniqueIds =
            this.potentialActiveUsers.uniqueIds.concat(result.uniqueIds);
          this.potentialActiveUsers.emails =
            this.potentialActiveUsers.emails.concat(result.emails);
          const activeUsers = this.activeUsers
            .concat(result.newActiveUsers);
          this.activeUsers = [];
          this.activeUsers = activeUsers;
          if (this.table) {
            this.table.refresh();
          }
        }
      },
    () => {});
  }

  public changeView() {
    if (this.isEditor) {
      this.isView = false;
    }
  }

  public removeUserFromRun(item) {
    if (!item.isNewAddedItem) {
      this.potentialRemovedUsers.removedActiveUsers.push(item);
    }
    this.activeUsers.splice(this.activeUsers.indexOf(item), 1);
    const index =
      this.potentialActiveUsers.newActiveUsers.find(t => t.id === item.id);
    this.potentialActiveUsers.newActiveUsers.splice(index, 1);
    const indexForEmail = this.potentialActiveUsers
      .emails.findIndex(y => y === item.username);
    if (indexForEmail !== -1) {
      this.potentialActiveUsers.emails
        .splice(indexForEmail, 1);
    }
    const indexUnique = this.potentialActiveUsers.uniqueIds
      .findIndex(y => y.uniqueId + '' ==
        item.uniqueId + '')
    if (indexUnique !== -1) {
      this.potentialActiveUsers.uniqueIds
        .splice(indexUnique, 1);
    }
    this.table.refresh();
  }

}
