import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import {Injectable} from "@angular/core";

@Injectable()
export default class ApiService {

  private apiURL: string = environment.apiUrl;

  protected defaultOptions: any = {
    headers: null,
    status: -1,
    statusText: null,
    type: null,
    url: null,
  }

  /**
   *
   * @param httpClient Angular http client
   */
  constructor(private httpClient: HttpClient) { }

  /**
   * Make request with http GET method.
   *
   * @param url      An url for http request.
   * @param options  A request options.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public get(url: string, options?: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}${url}`, options);
  }

  /**
   * Performs a request with `post` http method.
   *
   * @param url      An url which is used in a http request.
   * @param data     Data (in JavaScript format) which is used in a http request.
   * @param options  A request options arguments.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    return this.httpClient.post(`${this.apiURL}${url}`, body, options);
  }

  /**
   * Performs a request with `put` http method.
   *
   * @param url      An url which is used in a http request.
   * @param data     Data (in JavaScript format) which is used in a http request.
   * @param options  A request options arguments.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    return this.httpClient.put(`${this.apiURL}${url}`, body, options);
  }

  /**
   * Performs a request with `post` http method.
   *
   * @param url      An url which is used in a http request.
   * @param data     Data (in JavaScript format) which is used in a http request.
   * @param options  A request options arguments.
   * @returns        It returns a cold Observable which emits one value (in JavaScript format) from the request.
   */
  public delete(url: string, data: Object): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}${url}`, data);
  }

}
