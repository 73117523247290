import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class PacksService {


  constructor(private apiService: ApiService) {}

  public savePack(data) {
    return this.apiService.post('pack/add', {
      data
    });
  }

  public savePackImage(image, packId) {
    const formData: FormData = new FormData();
    formData.append('file', image);
    formData.append('packId', packId);
    return this.apiService.post('pack/edit/pack-image', formData);
  }

  public editPack(data) {
    return this.apiService.post('pack/edit', {
      data
    });
  }

  public getPack(data) {
    return this.apiService.post('pack/get', {
      data
    });
  }

  public getPacks(page?, pageSize?, sorts?) {
    return this.apiService.post('pack/get/filtered/for/user', {
      data: {
        filter: {
          status: 1,
          name: null
        }
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sorts || [{field: 'creationTime', desc: true}]
    });
  }

  public deletePack(id) {
    return this.apiService.post('pack/delete', {
      data: {
        id
      }
    });
  }

  public getFriends() {
    return this.apiService.post('users/friends', {
      data: {}
    });
  }

  public getUserByUniqueId(uniqueId) {
    return this.apiService.post('user/get/by/unique/id', {
      data: {
        uniqueId
      }
    });
  }

  public inviteUsers(packId, emails, uniqueIds) {
    return this.apiService.post('pack/user/invite', {
      data: {
        pack: {
          id: packId
        },
        emails,
        uniqueIds
      }
    });
  }

  public removeMembersFromPack(packId, ids) {
    return this.apiService.post('pack/user/remove', {
      data: {
        pack: {
          id: packId
        },
        ids
      }
    });
  }

}
