<div class="sign-in-container">
  <div class="sign-in-container-header">
    {{ currentStep?.message }}
  </div>
  <div class="sign-in-container-card">
    <mat-card class="sign-in-card sign-in-container-card">
      <mat-card-title>
        <div class="sign-in-img-logo">
          <img src="assets/images/WP_BLUE_R_Large_Horiz%20300.png" class="main-logo"/>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div class="sign-in-card-content"  [ngClass]="{'sign-in-card-text-content': (currentStep?.step === 1 || currentStep?.step === 3)}">
          <div class="sign-in-container-block">
              <div class="sign-in-title">{{ currentStep.title }}</div>
          </div>
          <div class="sign-in-content">
            <div class="sign-in-description">
              <ng-container *ngIf="currentStep?.step === 1">
                <div>
                  WolfPack no longer supports login using Facebook. Your account and all data remain in place, but you will need to set up a new username and password to use for your account in the future.
                </div>
                <div>
                  To verify your existing WolfPack account, login with the button below. You will then be asked to set a new username and password.
                </div>
              </ng-container>
              <ng-container *ngIf="currentStep?.step === 3">
                <div>
                  Thank you for setting new credentials. You may use the new usename and password to access your account either through the web or WolfPack mobile apps.
                </div>
              </ng-container>
              <ng-container *ngIf="currentStep?.step === 2">
                <div class="sign-in-inputs">
                  <form [formGroup]="loginForm">
                    <mat-form-field>
                      <mat-label>User Name</mat-label>
                      <input matInput formControlName="email" [regexReplace]="'email'">
                    </mat-form-field>
                    <div *ngIf="!email?.valid && email.touched && email.errors.required" class="error-message">
                      Required
                    </div>
                    <div *ngIf="!email?.valid && email.touched && email.errors.email" class="error-message">
                      Invalid email
                    </div>
                    <mat-form-field>
                      <mat-label>Password</mat-label>
                      <input matInput formControlName="password" type="password"
                             [regexReplace]="'password'">
                    </mat-form-field>
                    <div *ngIf="!password?.valid && password.touched && password.errors.required" class="error-message">
                    Required
                  </div>
                  </form>
                </div>
              </ng-container>
            </div>
            <div class="sign-in-items-external" *ngIf="currentStep?.step === 0">
              <button mat-raised-button class="btn-item btn-item-general"
                      type="button" [disabled]="loading"
                      (click)="goToNextStep()">Try again</button>
            </div>
            <div class="sign-in-items-external" *ngIf="currentStep?.step === 1">
              <button mat-raised-button class="btn-item btn-item-facebook blue-primary"
                      type="button" [disabled]="loading"
                      (click)="facebookLogin()">
                <img src="assets/images/Facebook_Logo_Secondary-ai.svg" class="btn-fc-icon"/>
                <span>Continue with Facebook</span>
              </button>
            </div>
            <div class="sign-in-items-external" *ngIf="currentStep?.step === 3">
              <button mat-raised-button class="btn-item btn-item-general"
                      type="button" (click)="backToSignIn()">Return to Login</button>
            </div>
            <div class="sign-in-items-external" *ngIf="currentStep?.step === 2">
              <button mat-raised-button class="btn-item btn-item-general"
                      type="button" (click)="save()" [disabled]="loginForm.invalid || loading">Save</button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
