import { Injectable } from '@angular/core';
import ApiService from '../../core/services/api.service';

@Injectable()
export class WhatsNewService {

  constructor(private apiService: ApiService) {}

  public getWhatsNew(page?, pageSize?) {
    return this.apiService.post(
      'news/get/filtered',
      {
        page: page || 1,
        pageSize: pageSize || 10,
        data: {
          filter: {
            status: 1,
            visibleOnWeb:true
          }
        },
        sort: [
          {
            field: "creationTime",
            desc: true
          }
        ]
    });
  }

}

