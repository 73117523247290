import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PaymentResultComponent } from './payment-result.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {PaymentResultService} from './payment-result.service';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    PaymentResultComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule
  ],
  providers: [
    PaymentResultService
  ],
  bootstrap: [PaymentResultComponent]
})
export class PaymentResultModule { }
