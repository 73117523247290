<div>
  <mat-progress-bar *ngIf="loading.run || loading.user || loading.join" mode="query"></mat-progress-bar>
</div>
<app-main-page-title [title]="'Runs'"></app-main-page-title>
<div class="table-description-item"></div>
<app-table #runTable
           [dataSource]="comingUpRuns"
           [expand]="true"
           [columns]="columns"
           [totalCount]="totalCount"
           [sorted]="true"
           [pageSize]="parameterTable.pageSize"
           [options]="options"
           [user]="userPremInformation"
           (join)="joinRun($event)"
           (currentPage)="nextPageList($event)"
           (sortChange)="sortList($event)"
           (duplication)="copyItem($event)"
           [runType]="currentType"
           (view)="displayRunInfo($event)"
           *ngIf="comingUpRuns && comingUpRuns.length > 0"
></app-table>
