import { Injectable } from '@angular/core';
import ApiService from '../../core/services/api.service';

@Injectable()
export class VerifyService {

  constructor(private apiService: ApiService) {}

  public confirm(data) {
    return this.apiService.post('user/confirm', {
      data: {
        id: data.id
      }
    });
  }

}
