<div class="sign-in-container">
<div class="sign-in-container-header">
  Start planning routes on WolfPack Web
</div>
<div class="sign-in-container-card">
  <mat-card class="sign-in-card sign-in-container-card">
    <mat-card-title>
      <div class="sign-in-img-logo">
        <img src="assets/images/WP_BLUE_R_Large_Horiz%20300.png" class="main-logo"/>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div class="sign-in-container-block">
        <div>
          <div class="sign-in-card-main-header">
            Start planning route and more on WolfPack Web
          </div>
          <div>
            <ol>
              <li>
                Open WolfPack on the phone
              </li>
              <li>
                Tap Main Menu and select Linked Devices
              </li>
              <li>
                Tap on the Link a Device button
              </li>
              <li>
                Point your phone to this screen to capture the code
              </li>
            </ol>
          </div>
        </div>
        <div class="qr-image-form-container">
          <div class="image-container">
            <ng-container *ngIf="!isQRLoading">
              <img [src]="qrImageUrl" *ngIf="qrImageUrl"
                   onerror="this.src='assets/images/placeholder-img.jpg'"
                   class="sign-in-container-logo"/>
              <span *ngIf="qrImageUrl" class="qr-image-small">
                <img src="assets/images/wolf-logo.png"/>
              </span>
            </ng-container>
            <ng-container *ngIf="isQRLoading">
              <div>
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
              </div>
            </ng-container>
          </div>
          <div *ngIf="isRestartConnection" class="refresh-container" (click)="restartConnection()">
            <div class="refresh-container-bg">
              <div class="refresh-container-wrapper">
                <div>
                  <mat-icon>refresh</mat-icon>
                </div>
                <div class="refresh-container-wrapper-text">Click to reload QR code</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button mat-raised-button class="btn-item btn-item-general"
                type="button" (click)="backToSignIn()">Back</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
</div>
