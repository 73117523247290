import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {SignInService} from './../sign-in.service';
import {AppStorageService} from '../../../core/services/app-storage.service';
import {environment} from '../../../../environments/environment';
import {LoginQrConnectTypeEnum} from '../../../enums/login-qr-connect-type.enum';
import {CommonService} from '../../../core/services/common.service';

@Component({
  selector: 'app-sign-in-qr',
  templateUrl: './sign-in-qr.component.html',
  styleUrls: ['./sign-in-qr.component.scss']
})
export class SignInQrComponent implements OnInit, OnDestroy {

  public qrImageUrl;
  public ttl;
  public loginListener;
  public loginReconnectAttempt = 0;
  public loginReconnectAttemptMax = 10;
  public loginReconnectTime = 1000;
  public loginTimeout;
  public loginIsConnecting;
  public isLoading = false;
  public isConnected = false;
  public currentUserToken;
  public isRestartConnection = false;
  public connectionTime: Date;
  public isQRLoading = true;

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private ngZone: NgZone,
                     private commonService: CommonService,
                     private signInService: SignInService,
                     private storageService: AppStorageService) {}

  ngOnInit(): void {
    this.connect();
  }

  backToSignIn() {
    this.router.navigate(['login']);
  }

  private processMessageResponse(response) {
    switch (response.code) {
      case 200: {
        if (response.action === LoginQrConnectTypeEnum.CONN) {
          // Getting data
          this.isQRLoading = true;
          this.signInService.loginQR({
            token: response.token,
            webToken: this.currentUserToken,
          }).subscribe((res) => {
            this.isQRLoading = false;
            this.router.navigate(['user/runs']);
          }, () => {
            this.isQRLoading = false;
          });
        } else {
          this.qrImageUrl = response.ref;
          this.ttl = response.ttl;
          this.initRefreshing();
        }
        break;
      }
      default: {
        // Process errors
      }
    }
  }

  public restartConnection() {
    this.isRestartConnection = false;
    this.connect();
  }

  private initRefreshing() {
    const timeout = setTimeout(() => {

      const currentTimeAsMs = this.connectionTime.getTime();
      const adjustedTimeAsMs = currentTimeAsMs + (environment.wssLoginDisconnectTimeout);

      if (new Date(adjustedTimeAsMs).getTime() > new Date().getTime()) {
        this.loginListener.send(JSON.stringify({
          action: LoginQrConnectTypeEnum.REF,
        }));
      } else {
        this.isRestartConnection = true;
        this.qrImageUrl = undefined;
        this.loginListener.close();
      }
      clearTimeout(timeout);
    }, this.ttl);
  }

  private connect() {
    if (this.isRestartConnection) {
      return;
    }
    this.loginListener = new WebSocket(environment.wssLoginUrl);
    this.loginListener.onmessage = message => {
      const messageObject = JSON.parse(message.data);
      this.processMessageResponse(messageObject);
      this.isQRLoading = false;
    };

    this.loginListener.onopen = () => {
      this.loginReconnectAttempt = 1;
      this.connectionTime = new Date();
      this.isQRLoading = true;
      this.initSession();
    };

    this.loginListener.onerror = () => {
      this.isQRLoading = false;
      this.connectHandler();
    };

    this.loginListener.onclose = () => {
      this.isQRLoading = false;
      this.connectHandler();
    };
  }

  private initSession() {
    this.currentUserToken = this.commonService.generateUniqueString();
    this.loginListener.send(JSON.stringify({
        action: LoginQrConnectTypeEnum.INIT,
        token: this.currentUserToken
      })
    );
  }

  connectHandler() {
    if (this.loginReconnectAttempt > this.loginReconnectAttemptMax) {
      return;
    }
    clearTimeout(this.loginTimeout);
    this.ngZone.run(() => {
      this.loginIsConnecting = true;
    });
    const time = this.loginReconnectTime * this.loginReconnectAttempt;
    this.loginTimeout = setTimeout(() => {
      this.connect();
      this.loginReconnectAttempt++;
    }, time);
  }

  ngOnDestroy(): void {
    try {
      if (this.loginListener) {
        this.loginListener.close();
      }
    } catch (e) {
      console.log(e);
    }
  }

}
