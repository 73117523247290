import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RestoreComponent } from './restore.component';
import { ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {RestoreService} from './restore.service';
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    RestoreComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        CoreModule
    ],
  providers: [
    RestoreService
  ],
  bootstrap: [RestoreComponent]
})
export class RestoreModule { }
