<div>
  <div class="header">
    Waypoint Details
  </div>
  <div class="waypoint-details-container">
    <div class="details-container">
      <div>
        <mat-form-field>
          <mat-label> Name </mat-label>
          <input matInput [(ngModel)]="waypointDetails.name"
                 [regexReplace]="'name'" titleCase [disabled]="readonlyMode">
        </mat-form-field>
      </div>

      <div>
        <mat-label> Category </mat-label>
        <mat-form-field>
          <mat-select [(ngModel)]="waypointDetails.pointCategoryId"
                      disableOptionCentering
          >
            <mat-option *ngFor="let rType of pointCategories" [value]="rType.id">
              {{rType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label> Description </mat-label>
          <input matInput [(ngModel)]="waypointDetails.description" [regexReplace]="'emoji'" [disabled]="readonlyMode">
        </mat-form-field>
      </div>
      <div class="error-invalid-container">
        <mat-form-field class="placeholder-move">
          <mat-label class="url-input"> Url </mat-label>
          <!--          <span class="prefix">HTTP(S):// &nbsp;</span>-->
          <input matInput [(ngModel)]="waypointDetails.url" [regexReplace]="'url'" [disabled]="readonlyMode" class="prefix-url-input">
        </mat-form-field>
        <span class="error-invalid-url" *ngIf="waypointDetails.url &&
          (!isValidUrl(waypointDetails.url))">Invalid url</span>
        <!-- ||
              isStartedWithHttp(waypointDetails.url)-->
      </div>
    </div>
    <div class="img-container">
      <div class="image-upload-container" [ngClass]="{'ignore-item': readonlyMode}" >
        <input type="file"
               (change)="fileChangeEvent($event)" [disabled]="readonlyMode"
               #imageSelection
               accept="image/x-png,image/jpeg" />
        <div class="image-upload-remove-container" (click)="removePictureUrl()" *ngIf="(currentUpdatedImage || waypointDetails.pictureUrl) && !readonlyMode">
          <mat-icon class="upload-image-remove" matTooltip="Remove image">delete</mat-icon>
        </div>
        <img [src]="currentUpdatedImage || waypointDetails.pictureUrl"
             onerror="this.src='assets/images/placeholder-img.jpg';"
        />
        <div class="image-upload-box" (click)="readonlyMode ? false : imageSelection.click()" matTooltip="Upload image">
          <mat-icon class="upload-image" >add_photo_alternate</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="actions-table">
  <div class="actions-table-wrapper">
    <button mat-raised-button color="primary" type="submit"
            (click)="saveWaypointDetails()" [disabled]="loading || readonlyMode">
      <span class="btn-action-wrapper-general"
        [id]="'WAYPOINT_DETAILS_DIALOG_SAVE_WAYPOINT' | gtmTag" >
        Save
      </span>
    </button>
    <button mat-button type="button" (click)="cancel()" class="cancel-btn">Cancel</button>
  </div>
  <div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  </div>
</div>
