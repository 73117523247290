export default class StatisticsModel {
  public currentPackMembersCount: number;
  public friendCount: number;
  public longestRun: number;
  public packsCount: number;
  public startedRunsCount: number;
  public tags: string[] = [];
  public timeTraveled: number;
  public totalDistanceCovered: number;
  public totalUniqueFellowRidersCount: number;
  public userTags: TagStatisticsModel[] = [];
}

export class TagStatisticsModel {
  public achievementDate: string;
  public tag: TagModel;
}

export class TagModel {
  public id: string;
  public name: string;
  public pictureUrl: string;
  public description: string;
}
