import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyService } from './verify.service';
import { MessageHandlerService } from '../../core/services/message-handler.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  public loading = false;
  private token;
  public resultSuccess;

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private verifyService: VerifyService,
                     private successMessageService: MessageHandlerService,
                     ) {
    this.token = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.verify();
  }

  private verify() {
    this.loading = true;
    this.verifyService.confirm({
      id: this.token
    }).subscribe((t) => {
      this.loading = false;
      if (!t.errors ||
        t.errors.length === 0) {
        this.resultSuccess = true;
      }
    }, () => {
      this.loading = false;
    });
  }

}
