import { Component, Inject } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent {

  public constructor(public dialogRef: MatDialogRef<DialogConfirmationComponent>,
                     @Inject(MAT_DIALOG_DATA) public data: any) {}

  public result(answer) {
    this.dialogRef.close(answer);
  }

}
