import { NgModule } from '@angular/core';
import { PremiumAccountComponent } from './premium-account.component';
import { MaterialModule } from '../../external-modules/material.module';
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {PremiumAccountService} from "./premium-account.service";
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    PremiumAccountComponent
  ],
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule,
    CoreModule
  ],
  providers: [
    PremiumAccountService
  ],
  bootstrap: [PremiumAccountComponent]
})
export class PremiumAccountModule { }

