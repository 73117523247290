import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RunLibraryComponent } from './run-library.component';
import { SharedModule } from '../../../../shared/shared.module';
import { RunLibraryService } from './run-library.service';
import { FormsModule } from '@angular/forms';
import { RunLibraryDetailsModule } from './run-library-details/run-library-details.module';
import {CoreModule} from "../../../../core/core.module";

@NgModule({
  declarations: [
    RunLibraryComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        FormsModule,
        RunLibraryDetailsModule,
        CoreModule
    ],
  providers: [
    RunLibraryService
  ],
  bootstrap: [RunLibraryComponent]
})
export class RunLibraryModule { }
