import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageHandlerService} from '../../../core/services/message-handler.service';
import {MessageTypeEnum} from '../../../enums/message-type.enum';
import {ResetService} from './reset.service';
import {comparePasswordValidator} from '../../../shared/validators/compare-password.validor';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  public restoreForm;
  public loading = false;
  private token;
  public isResetFinished = false;

  public get password() {
    return this.restoreForm.controls.password;
  }

  public get confirmPassword() {
    return this.restoreForm.controls.confirmPassword;
  }

  private initForm() {
    this.restoreForm = this.builder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, {validator: comparePasswordValidator('password', 'confirmPassword')});
  }

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private restoreService: ResetService,
                     private successMessageService: MessageHandlerService,
                     ) {
    this.token = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.initForm();
  }

  public goToSignInPage() {
    this.router.navigate(['login']);
  }

  public restore(formDirective) {
    if (!this.restoreForm.invalid) {
      this.loading = true;
      this.restoreService.restore({
        token: this.token,
        password: this.password.value,
        confirmPassword: this.confirmPassword.value
      }).subscribe(t => {
        this.loading = false;
        if (t && t.result && t.result.data &&
          t.result.data[0]) {
          this.successMessageService.openDialog({
            type: MessageTypeEnum.SUCCESS,
            message: 'Password was updated. You can login now',
            explanation: ''
          });
          formDirective.resetForm();
          this.restoreForm.reset();
          this.isResetFinished = true;
        }
      }, () => {
        this.loading = false;
      });
    }
  }

}
