import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LogbookComponent } from './logbook.component';
import { SharedModule } from '../../../../shared/shared.module';
import {CoreModule} from "../../../../core/core.module";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    LogbookComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    FormsModule
  ],
  providers: [],
  exports: [
    LogbookComponent
  ],
  bootstrap: [LogbookComponent]
})
export class LogbookModule { }
