<div>
  <div class="header">
    Trip
  </div>
  <form [formGroup]="runForm" (ngSubmit)="saveRun()" class="run-form-group">
    <div class="run-container">
      <div class="run-description">
        <div>
          <mat-form-field>
            <mat-label><span class="required">*</span> Trip Name </mat-label>
            <input matInput formControlName="name" [regexReplace]="'emoji'" titleCase>
          </mat-form-field>
          <mat-form-field >
            <mat-label><span class="required" >*</span>Pack Name</mat-label>
            <mat-select [required]="true" (valueChange)="packUpdateHandler($event)" formControlName="packId">
              <mat-option *ngFor="let pack of packs" [value]="pack.id">
                {{pack.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="protected-item" *ngIf="!runForm.controls.isPublic.value">
            <mat-checkbox formControlName="isProtected">Protected</mat-checkbox>
          </div>
        </div>
        <div>
          <div class="run-item-container">
            <mat-form-field class="point-item">
              <mat-label><span class="required">*</span>Starting Point <span class="point-icon" #startPointIcon>A</span></mat-label>
              <input matInput formControlName="startingPointName" #startingPointInput >
            </mat-form-field>
            <button mat-icon-button (click)="detailsPoint(PointTypeEnum.START)" type="button"
                    class="details-btn"
                    [disabled]="loading"
                    matTooltip="Click here to add details about this Point. This will be visible to all trip participants in the WolfPack App">
              <mat-icon class="wp-details" [ngClass]="{'wp-details-filled': isPointInfoExist(PointTypeEnum.START)}">info</mat-icon>
            </button>
          </div>
          <div *ngIf="isPremium || (!isPremium && data.run)">
            <div *ngIf="waypointsListArray && waypointsListArray.length > 0" class="waypointes-header-container">
              <span class="waypoint-header">Waypoints</span>
            </div>
            <div class="waypoint-item-list waypoint-list" cdkDropList
              (cdkDropListDropped)="dropWaypointList($event)" *ngIf="waypointsListArray.length > 0"
              [ngClass]="{'waypoint-list-filled': endingPointInput.value}">
              <ng-container formArrayName="waypoints" >
                <div class="waypoint-item" *ngFor="let w of waypointsListArray.controls; let i = index" cdkDrag>
                  <div class="waypoint-box">
                    <button mat-icon-button (click)="detailsWaypoint(i)" type="button"
                            *ngIf="!isWPExistsAndBasicEditMode()"
                            class="details-btn"
                            [disabled]="loading || (waypoints[i] && !waypoints[i].location)"
                            matTooltip="Click here to add details about this Waypoint. This will be visible to all trip participants in the WolfPack App">
                      <mat-icon class="wp-details" [ngClass]="{'wp-details-filled': isWpInfoExist(i)}">info</mat-icon>
                    </button>
                    <button mat-icon-button (click)="detailsWaypoint(i)" type="button"
                            *ngIf="isWPExistsAndBasicEditMode()"
                            class="details-btn"
                            [disabled]="loading || (waypoints[i] && !waypoints[i].location)"
                            matTooltip="Click here to see details about this Waypoint.
                            This will be visible to all trip participants in the WolfPack App">
                      <mat-icon class="wp-details" [ngClass]="{'wp-details-filled': isWpInfoExist(i)}">info</mat-icon>
                    </button>
                    <ng-container>
                      <mat-form-field class="waypoint-item waypoint-item-in-list">
                        <mat-label>Waypoint <span>{{LETTERS[i + 1]}}</span></mat-label>
                        <input matInput id="wp-{{i}}"  [formControlName]="i"

                               #wpoint (focus)="checkWaypointStatus(w, i)">
                      </mat-form-field>
                    </ng-container>
                    <button mat-icon-button (click)="removeWaypoint(i)" type="button"
                            [disabled]="loading || isWPExistsAndBasicEditMode()">
                      <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                    <button mat-icon-button cdkDragHandle class="move-btn" type="button"
                          [disabled]="loading || isWPExistsAndBasicEditMode()"
                          [cdkDragHandleDisabled]="loading || isWPExistsAndBasicEditMode() || isNotWaypointSelected()">
                      <mat-icon>drag_indicator</mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="run-item-container">
            <mat-form-field class="point-item">
              <mat-label><span class="required"
              >*</span>Ending Point <span class="point-icon" #endPointIcon>B</span></mat-label>
              <input matInput formControlName="endingPointName" #endingPointInput
              >
            </mat-form-field>
            <button mat-icon-button (click)="detailsPoint(PointTypeEnum.DESTINATION)" type="button"
                    class="details-btn"
                    [disabled]="loading"
                    matTooltip="Click here to add details about this Point. This will be visible to all trip participants in the WolfPack App">
              <mat-icon class="wp-details" [ngClass]="{'wp-details-filled': isPointInfoExist(PointTypeEnum.DESTINATION)}">info</mat-icon>
            </button>
          </div>

        </div>
        <div class="waypoint-add-btn-container waypoint-item-btn-actions "
           [ngClass]="{'waypoint-item-btn-actions-full': endingPointInput.value}">
          <button mat-raised-button type="button" color="primary" (click)="addWaypoint()">
            <span class="btn-action-wrapper-general"
              [id]="'RUN_DIALOG_ADD_WAYPOINT' | gtmTag">Add Waypoint</span>
          </button> <!-- [disabled]="!isPremium"-->
        </div>
        <div class="date-range">
          <mat-form-field>
            <mat-label><span class="required" *ngIf="!runForm.controls.isSolo.value">*</span>Start Date {{runForm.controls.endDate.required}}</mat-label>
            <input
              (click)="displayDatePicker($event, strtPoint)"
              matInput
                   readonly
                   [min]="minDate"
                   [ngxMatDatetimePicker]="pickerStartDate"
                   placeholder="Choose a date" formControlName="startDate" (ngModelChange)="checkCurrentDateTime($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerStartDate" #strtPoint></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerStartDate
               [stepHour]="stepHour" [stepMinute]="stepMinute"
               [showSeconds]="showSeconds"
               [color]="color">
            </ngx-mat-datetime-picker>
            <mat-error>
<!--              <span *ngIf="runForm.controls.startDate.errors?.minDate">-->
<!--                Date cannot be less then today-->
<!--              </span>-->
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label><span class="required"  *ngIf="!runForm.controls.isSolo.value">*</span>End Date</mat-label>
            <input
               matInput readonly
               [min]="minDate"
               (click)="pickerEndDate.open(); displayDatePicker($event, endPoint)"
               [ngxMatDatetimePicker]="pickerEndDate"
               formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="pickerEndDate" #endPoint></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #pickerEndDate
                               [showSeconds]="showSeconds"
                               [stepHour]="stepHour" [stepMinute]="stepMinute"
               [color]="color">
            </ngx-mat-datetime-picker>
            <mat-error>
<!--              <span *ngIf="runForm.controls.endDate.errors?.minDate">-->
<!--                Date cannot be less then today-->
<!--              </span>-->
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="details-item">
            <mat-label>Details</mat-label>
            <textarea matInput formControlName="note" titleCase maxlength="{{tripDescriptionMaxLength}}" ></textarea>
            <span class="character-counter"
                  style="float: right; font-size: 12px;">
                {{runForm.get('note').value  ? runForm.get('note').value.length : 0}}/{{tripDescriptionMaxLength}}
            </span>
          </mat-form-field>
        </div>
        <div class="run-display-options">
          <mat-checkbox formControlName="avoidHighways"
                        (change)="changedMapSettings('avoidHighways')">Avoid Highways</mat-checkbox>
          <mat-checkbox formControlName="avoidTolls"
                        (change)="changedMapSettings('avoidTolls')">Avoid Tolls</mat-checkbox>
          <mat-checkbox formControlName="avoidFerries"
                        (change)="changedMapSettings('avoidFerries')">Avoid Ferries</mat-checkbox>
        </div>
      </div>
      <div class="run-map-id-container">
        <div id="run-map-id" class="map-display" #runMapId
             [ngClass]="{'hidden': ((isCopy || !data.run || data.run.id) && initMapDraw === 0)}"></div>
        <div>
          <mat-progress-spinner diameter="20" mode="indeterminate"
            *ngIf="initMapDraw === 0"></mat-progress-spinner>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="actions-table">
  <div class="actions-table-wrapper">
    <button mat-raised-button color="primary" type="submit"
            [disabled]="loading || isWPExistsAndBasicEditMode() || !isPathValid || isPackNotFilled()"
            (click)="saveRun()" >
      <span class="btn-action-wrapper-general" [id]="'RUN_DIALOG_SAVE_RUN' | gtmTag">
        Save
      </span>
    </button>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
<!--    <div class="error-display-information">-->
<!--      {{errorNotification}}-->
<!--    </div>-->
  </div>
  <div class="progress-bar-loading">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
</div>

