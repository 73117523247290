import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/*
* function body that test if storage is available
* returns true if localStorage is available and false if it not
*/
function lsTest(){
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
}

function cookieStorage(item, name) {
  const cookieValue = document.cookie;
  if (cookieValue) {
    document.cookie = item+"="+name;
  }
}

@Injectable()
export class AppStorageService {

  constructor() {}

  setCookie(name, value, options:any = {}) {
    options = {
      path: '/',
      expires: new Date(),
      ...options
    };
    if (options.expires && options.expires.toUTCString) {
      options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }

  setSimpleCookie(name, value) {
    document.cookie = name + "=" + encodeURIComponent(JSON.stringify(value));
  }

  deleteCookie(cname) {
    this.setCookie(cname, '', {
      'max-age': -1
    });
  }


  public getCookie(cname) {
    const name = cname + '=';
    let cookie =
      decodeURIComponent(document.cookie);
    cookie = cookie;
    const ca = cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length).replace(/\+/gi, ' ');
      }
    }
    return '';
  }

  public setItemInLocalStorage(key, value, local = true) {
    if (lsTest()) {
      const storage = local ? localStorage : sessionStorage;
      storage.setItem(key, value);
    }
  }

  public getItemFromLocalStorage(key, local = true) {
    if (lsTest()) {
      const storage = local ? localStorage : sessionStorage;
      return storage.getItem(key);
    }
  }

  public removeItemInStorage(key, local = true) {
    if (lsTest()) {
      const storage = local ? localStorage : sessionStorage;
      storage.removeItem(key);
    }
  }

}
