import { NgModule } from '@angular/core';
import {MaterialModule} from './external-modules/material.module';
import {ComponentsModule} from './components/components.module';
import {RunTableComponent} from './components/run-table/run-table.component';

@NgModule({
    declarations: [],
    imports: [
        MaterialModule,
        ComponentsModule
    ],
    exports: [
        MaterialModule,
        ComponentsModule
    ],
    providers: []
})
export class SharedModule { }
