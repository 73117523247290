import TableColumnModel from '../../models/tables/table-column.model';

export const packTableColumn: TableColumnModel[] = [
  {
    name: 'Pack Name',
    label: 'name',
    field: 'name',
    isSticky: true,
    initial: true,
    displayImage: true,
    displayedImgLogo: (item) => {
      {
        return item.pictureUrl;
      }
    }
  },
  {
    name: 'Date Created',
    label: 'creationTime',
    field: 'creationTime',
    pipe: 'date'
  },
  {
    name: 'Pack Creator',
    label: 'alpha',
    field: '',
    formatter: (t) => {
      return t.alpha ? (t.isMyPack ? 'Me' : t.alpha.firstName + ' ' + t.alpha.lastName) : '';
    }
  },
  {
    name: 'Pack ID',
    label: 'publicId',
    field: 'publicId',
    pipe: 'packID'
  },
  {
    name: '',
    label: 'action',
    field: '',
    isAction: true
  },
];
