import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PublicRunDetailsComponent } from './public-run-details.component';
import { SharedModule } from '../../../../shared/shared.module';
import { PublicRunsService } from './../public-runs.service';
import {FormsModule} from "@angular/forms";
import {CoreModule} from "../../../../core/core.module";

@NgModule({
  declarations: [
    PublicRunDetailsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    CoreModule,
  ],
  providers: [
    PublicRunsService
  ],
  bootstrap: [PublicRunDetailsComponent]
})
export class PublicRunDetailsModule { }
