<app-main-page-title [title]="'Packs'"></app-main-page-title>
<div class="button-actions">
  <button mat-raised-button class="red-primary"
    (click)="createPack()">
    <span class="btn-action-wrapper-general" [id]="'MYPACK_CREATE_PACK' | gtmTag">
      <mat-icon class="btn-title-icon" [id]="'MYPACK_CREATE_PACK' | gtmTag">add</mat-icon>
      <span class="btn-title-content" [id]="'MYPACK_CREATE_PACK' | gtmTag">Create Pack</span>
    </span>
  </button>
</div>
<div>
  <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
</div>
<app-table #runTable
       [dataSource]="packs"
       [expand]="false"
       [columns]="packColumns"
       [totalCount]="totalCount"
       [user]="userPremInformation"
       [onRowClickSupport]="true"
       (delete)="deletePack($event)"
       (edit)="viewPack($event, false)"
       (view)="viewPack($event, true)"
       (details)="viewPack($event, true)"
       (currentPage)="nextPageList($event)"
       (sortChange)="sortList($event)" [runType]="TableTypeEnum.PACK"
               *ngIf="packs && packs.length > 0"
      ></app-table>
