import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StatsComponent } from './stats.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { StatsService } from './stats.service';
import { SharedModule } from '../../../shared/shared.module';
import { CoreModule } from '../../../core/core.module';
import { TrophieItemModule } from './trophie-item/trophie-item.module';
import {NgxEchartsModule} from "ngx-echarts";
import * as echarts from 'echarts';

@NgModule({
  declarations: [
    StatsComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    TrophieItemModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    FormsModule,
  ],
  providers: [
    StatsService
  ],
  bootstrap: [StatsComponent]
})
export class StatsModule { }
