import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {SignInService} from './sign-in.service';
import {LoginTypeEnum} from '../../enums/login-type.enum';
import {AppStorageService} from '../../core/services/app-storage.service';
import {StorageEnum} from '../../enums/storage.enum';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public loginForm;
  public loading = false;
  public LoginTypeEnum = LoginTypeEnum;

  public get email() {
    return this.loginForm.controls.email;
  }

  public get password() {
    return this.loginForm.controls.password;
  }

  private initForm() {
    this.loginForm = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }


  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private signInService: SignInService,
                     private storageService: AppStorageService) {}

  ngOnInit(): void {
    this.initForm();
    this.initApple();
  }

  private initApple() {
    // @ts-ignore
    AppleID.auth.init({
      clientId: environment.APPLE_CLIENT_ID,
      scope: '',
      redirectURI: environment.APPLE_REDIRECT_URI,
      state: new Date().getTime().toString(),
      usePopup: true
    });
    document.addEventListener('AppleIDSignInOnSuccess', (data: any) => {
      const authorization = data.detail.authorization;
      const parsedToken = JSON.parse(atob(authorization.id_token.split('.')[1]));
      this.appleLogin({
        authCode: authorization.code,
        userAppleId: parsedToken.sub
      });
    });
    document.addEventListener('AppleIDSignInOnFailure', (error: any) => {
      if (error.detail.error !== 'popup_closed_by_user') {
        console.log(error);
      }
    });
  }

  public login(type) {
    switch (type) {
      case LoginTypeEnum.FACEBOOK: {
        this.router.navigate(['login/facebook']);
        break;
      }
      case LoginTypeEnum.APPLE: {
        break;
      }
      case LoginTypeEnum.QR: {
        break;
      }
      default: {
        return this.tokenLogin();
      }
    }
  }

  public redirectOnPage() {
    this.router.navigate(['login/qr']);
  }

  private appleLogin(data) {
    this.loading = true;
    this.signInService.loginApple(data).subscribe(t => {
      this.loading = false;
      this.router.navigate(['user/runs']);
      this.storageService.setItemInLocalStorage(StorageEnum.LOGIN, LoginTypeEnum.APPLE);
      window.location.reload();
    }, () => {
      this.loading = false;
    });
  }

  private tokenLogin() {
    if (!this.loginForm.invalid) {
      this.loading = true;
      this.signInService
        .login({email: this.email.value, password: this.password.value}).subscribe(t => {
          this.loading = false;
          this.router.navigate(['user/runs']);
        }, () => {
          this.loading = false;
        });
    }
  }

  public goToRestorePage() {
    this.router.navigate(['restore']);
  }

  public goToLPPage() {
    window.location.href = environment.landingPage;
  }

  public navigateUrl(url) {
    window.location.href = url;
  }

}
