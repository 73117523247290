import { Injectable } from '@angular/core';
import ApiService from '../../core/services/api.service';
import {AppStorageService} from '../../core/services/app-storage.service';
import {map} from 'rxjs/operators';
import {StorageEnum} from '../../enums/storage.enum';

@Injectable()
export class SignInService {

  constructor(private apiService: ApiService,
              private appStorage: AppStorageService) {}

  public login(data) {
    return this.apiService.post('user/auth', {
      data: {
        username: data.email,
        password: data.password,
        params: {
          details: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language,
          appName: this.fnBrowserDetect()
        }
      }
    }).pipe(
      map(res => {
        this.saveToken(res);
        return res;
      })
    );
  }

  private fnBrowserDetect() {
    const userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)){
      browserName = 'chrome';
    }else if (userAgent.match(/firefox|fxios/i)){
      browserName = 'firefox';
    }  else if (userAgent.match(/safari/i)){
      browserName = 'safari';
    }else if (userAgent.match(/opr\//i)){
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)){
      browserName = 'edge';
    }else{
      browserName = 'No browser detection';
    }
    return browserName;
  }

  public loginQR(data) {
    return this.apiService.post('user/auth/qr', {
      data: {
        token: data.token,
        webToken: data.webToken,
        details: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        appName: this.fnBrowserDetect()
      }
    }).pipe(
      map(res => {
        this.saveToken(res);
        return res;
      })
    );
  }

  public migrateFB(data) {
    data = Object.assign({}, data, {
      params: {
        details: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        appName: this.fnBrowserDetect()
      }
    });
    return this.apiService.put('user/auth/facebook/migration', {
      data
    });
  }

  public migrateFBAvailabilityStatus(data) {
    return this.apiService.post('user/auth/facebook/migration/availability', {
      data
    });
  }

  public loginFB(data) {
    data = Object.assign({}, data, {
      params: {
        details: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        appName: this.fnBrowserDetect()
      }
    });
    return this.apiService.post('user/auth/facebook', {
      data
    }).pipe(
      map(res => {
        this.saveToken(res);
        return res;
      })
    );
  }

  public loginApple(data) {
    data.web = true;
    data = Object.assign({}, data, {
      params: {
        details: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        appName: this.fnBrowserDetect()
      }
    });
    return this.apiService.post('user/auth/apple', {
      data
    }).pipe(
      map(res => {
        this.saveToken(res);
        return res;
      })
    );
  }

  private appleLogin() {}

  private saveToken(res) {
    this.appStorage.setItemInLocalStorage(StorageEnum.TOKEN, res.result.data[0].token);
    this.appStorage.setSimpleCookie(StorageEnum.TOKEN, res.result.data[0].token);
    this.appStorage.setSimpleCookie(StorageEnum.PSC_USER, res.result.data[0]);
    this.appStorage.setItemInLocalStorage(StorageEnum.FIRST_NAME, res.result.data[0].pscUser.firstName);
    this.appStorage.setItemInLocalStorage(StorageEnum.LAST_NAME, res.result.data[0].pscUser.lastName);
  }

}
