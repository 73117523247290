import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PacksComponent } from './packs.component';
import { SharedModule } from '../../../shared/shared.module';
import { PacksService } from './packs.service';
import { DialogPackModule } from './dialog-pack/dialog-pack.module';
import { DialogPackMembersModule } from './dialog-pack-members/dialog-pack-members.module';
import {CoreModule} from '../../../core/core.module';

@NgModule({
  declarations: [
    PacksComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        DialogPackModule,
        DialogPackMembersModule,
        CoreModule
    ],
  providers: [
    PacksService
  ],
  bootstrap: [PacksComponent]
})
export class PacksModule { }
