import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {UserModel} from '../../../../models/user.model';
import {MapEnum} from '../../../../enums/map.enum';
import {CommonService} from '../../../../core/services/common.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {BaseComponent} from '../../../../shared/classes/base.component';
import RunModel from '../../../../models/run.model';
import {TableTypeEnum} from '../../../../enums/table-type.enum';
import {DialogRunComponent} from '../dialog-run/dialog-run.component';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationRunsService} from './location-runs.service';
import {AuthService} from '../../../../core/services/auth.service';
import {PublicRunDetailsComponent} from '../../public-runs/public-run-details/public-run-details.component';
import TableOptionsModel, {TableOptionsDisableModel} from '../../../../models/tables/table-options.model';
import {publicRunTableColumns} from '../../../../shared/common-structures/public-run-table.list';
import {RsvpResponseEnum} from '../../../../enums/rsvp.response.enum';
import {LocationTypeEnum} from '../../../../enums/location-type.enum';
import {runLibraryTableColumns} from '../../../../shared/common-structures/run-library.list';
import {RunLibraryDetailsComponent} from '../run-library/run-library-details/run-library-details.component';
import {DialogConfirmationComponent} from "../../../../shared/components/dialog-confirmation/dialog-confirmation.component";
import {DialogMessageComponent} from "../../../../shared/components/dialog-message/dialog-message.component";
import {MessageTypeEnum} from "../../../../enums/message-type.enum";

@Component({
  selector: 'app-user-runs-location-run',
  templateUrl: './location-runs.component.html',
  styleUrls: ['./location-runs.component.scss']
})
export class LocationRunsComponent extends BaseComponent implements OnInit {

  public TableTypeEnum = TableTypeEnum;
  public loading = {
    run: false,
    user: false,
    join: false,
  };
  public currentType = TableTypeEnum.PUBLIC_RUN;

  public comingUpRuns: RunModel[] = [];
  public columns = [];
  public preferences: any[] = [];
  public distance;
  public totalCount = 0;

  public parameterTable = {
    currentPage: 1,
    pageSize: 10,
    sorts: [{field: 'name', desc: true}]
  };

  public coordinates = {
    lat: 0,
    lng: 0
  };

  @Input()
  public user: UserModel;

  @Input()
  public isPremium: boolean = false;

  @ViewChild('runTable')
  public table;

  public runType;
  public userPremInformation;

  public myJoinedPublicRuns = {};
  public options: TableOptionsModel;

  constructor(private runsService: LocationRunsService,
              public dialog: MatDialog,
              private commonService: CommonService,
              public snackBar: MatSnackBar,
              public activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private locationRunService: LocationRunsService,
              private router: Router) {
    super(dialog, snackBar);
  }

  public nextPageList($event) {
    this.parameterTable.currentPage = $event.pageIndex;
    this.parameterTable.pageSize = $event.pageSize;
    this.userPreferences();
    this.displayList();
  }

  public sortList($event) {
    if ($event.direction) {
      const t: any =
        {field: $event.active, desc: $event.direction === 'desc'};
      this.parameterTable.sorts = [t];
      const columninfo = this.columns.find(t => t.field === $event.active);
      if (columninfo && columninfo.extraSort) {
        columninfo.extraSort.forEach(t => {
          t.desc = $event.direction === 'desc';
          this.parameterTable.sorts.push(t);
        });
      }
    } else {
      this.parameterTable.sorts = [{field: 'name', desc: true}];
    }
    this.displayList();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.coordinates.lat = params['lat'];
      this.coordinates.lng = params['lng'];
      if (!this.coordinates.lat || !this.coordinates.lng) {
        this.router.navigate(['/']);
      } else {
        this.runType = this.activatedRoute.snapshot.paramMap.get('type');
        this.createTableOptions();
        this.displayList();
        this.userPreferences();
        this.userInfo();
      }
    });
  }


  private getPointCategoryHandler(pointCategories, callback, errorcallback) {
    this.runsService.getPointCategories()
      .subscribe(t => {
        if (callback) {
          callback(t);
        }
      }, () => {
        errorcallback([]);
      });
  }

  public copyItem(item, callback?, ignoreLoading?) {
    this.displayDialog(DialogRunComponent, {
      run: item,
      isPremium: this.isPremium,
      preferences: this.preferences,
      isCopy: true,
      createRun: (run, callback, errorcallback) => {this.createRunHandler(run, callback, errorcallback);},
      getPacks: (packs, callback, errorcallback) => {this.getPackHandler(packs, callback, errorcallback);},
      getPointCategories: (pointCategories, callback, errorcallback) => {this.getPointCategoryHandler(pointCategories, callback, errorcallback);}
    }, (run) => {
      this.displayDialog(DialogMessageComponent, {
        type: MessageTypeEnum.SUCCESS,
        explanation: 'Run was created',
        message: ''
      }, () => {}, () => {});

    }, () => {});
  }

  private userPreferences() {
    this.runsService.getUserPreferences().subscribe((preferences) => {
      if (preferences) {
        this.preferences = preferences.result.data;
        const distance = this.preferences.find(t => {
          return t.keyword === MapEnum.DISTANCE_FORMAT;
        });
        if (distance) {
          this.distance = this.commonService.distanceMapper(distance.value);
        }
      }
    })
  }

  private getJoinedPublicRunsList() {
    this.loading.join = true;
    this.runsService.getJoinedPublicRuns().subscribe(t => {
      this.loading.join = false;
      if (t && t.result && t.result.data) {
        const data = t.result.data || [];
        data.forEach(y => {
          this.myJoinedPublicRuns[y.id] = y;
        });
      }
    }, () => {
      this.loading.join = false;
    });
  }

  public displayList() {
    if (this.runType === LocationTypeEnum.PUBLIC) {
      this.loading.run = true;
      this.runsService.getPublicMultiRuns(this.coordinates.lat, this.coordinates.lng, this.parameterTable.currentPage,
        this.parameterTable.pageSize,
        this.parameterTable.sorts).subscribe(t => {
        if (t || t.result || t.result.data) {
          this.comingUpRuns = t.result.data || [];
          this.totalCount = t.result.totalCount;
          if (this.table) {
            this.table.refresh();
          }
        }
        this.loading.run = false;
      }, () => {
        this.loading.run = false;
      });
      this.getJoinedPublicRunsList();
    } else if (this.runType === LocationTypeEnum.LIBRARY) {
      this.loading.run = true;
      this.runsService.getLibraryMultiRuns(this.coordinates.lat, this.coordinates.lng, this.parameterTable.currentPage,
        this.parameterTable.pageSize,
        this.parameterTable.sorts).subscribe(t => {
        if (t || t.result || t.result.data) {
          this.comingUpRuns = t.result.data || [];
          this.totalCount = t.result.totalCount;
          this.comingUpRuns.forEach(y => {
            this.myJoinedPublicRuns[y.id] = y;
          });
          if (this.table) {
            this.table.refresh();
          }
        }
        this.loading.run = false;
      }, () => {
        this.loading.run = false;
      });
    }
  }

  private getPackHandler(packs, callback, errorcallback) {
    this.runsService.getPacks()
      .subscribe(t => {
      if (callback) {
        callback(t);
      }
    }, () => {
      errorcallback([]);
    });
  }

  private createRunHandler(run, callback, errorcallback) {
    this.runsService.addRun(run).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }

    }, () => {
      errorcallback();
    });
  }

  public displayRunInfo(run) {
    if (this.runType === LocationTypeEnum.LIBRARY) {
      this.displayDialog(RunLibraryDetailsComponent, {
        run,
        isCopy: true,
        isRunCopied: !this.isPremium &&
          run.waypoints && run.waypoints.length > 0,
        distance: this.distance,
        joinRun: (run, callback) => {
          this.copyItem(run, callback, true);
        }
      }, (result) => {
        if (result && result.planRun) {
          this.copyItem(run);
        }
      }, () => {});
    } else {
      this.displayDialog(PublicRunDetailsComponent, {
        run,
        isJoined: this.myJoinedPublicRuns[run.id],
        distance: this.distance,
        joinRun: (run, callback) => {
          this.joinRun(run, callback, true);
        }
      }, () => {}, () => {});
    }
  }

  public joinRun(item, callback?, ignoreLoading?) {

    this.displayDialog(DialogConfirmationComponent, {
      type: 'Join Public Run',
      message: '',
      okResponse: 'Join'
    }, () => {
      this.loading.user = !ignoreLoading;
      this.locationRunService
        .sendRsvpForPublicRun(RsvpResponseEnum.YES, item.id)
        .subscribe(t => {
          this.loading.user = false;
          if (t && t.result && t.result.data && t.result.data[0]) {
            if (callback) {
              callback();
            }
            this.myJoinedPublicRuns[t.result.data[0].runId] = t.result.data[0];

            this.displayDialog(DialogMessageComponent, {
              type: MessageTypeEnum.SUCCESS,
              explanation: 'You were joined',
              message: ''
            }, () => {}, () => {});

          }
        }, () => {
          this.loading.user = false;
        });
    }, () => {});
  }

  private createTableOptions() {
    this.options = new TableOptionsModel();
    this.options.disableOptions = new TableOptionsDisableModel();
    this.options.disableOptions.disableActivation = (t) => {
      return this.runType === LocationTypeEnum.PUBLIC ? !!this.myJoinedPublicRuns[t.id] : !this.isPremium &&
        t.waypoints && t.waypoints.length > 0;
    }
    if (this.runType === LocationTypeEnum.LIBRARY) {
      this.currentType = TableTypeEnum.RUN_LIBRARY;
      this.columns = runLibraryTableColumns;
    } else if (this.runType === LocationTypeEnum.PUBLIC) {
      this.currentType = TableTypeEnum.PUBLIC_RUN;
      this.columns = publicRunTableColumns;
    }
  }

  private userInfo() {
    this.authService.isUserPremium().subscribe(t => {
      this.loading.user = false;
      if (t.result && t.result.data) {
        this.userPremInformation = t.result.data[0];
        const info = t.serverTime;
        this.isPremium = this.userPremInformation.paidPeriodEndDateBuffer &&
          this.userPremInformation.paidPeriodEndDateBuffer >= info;
      }
    }, () => {
      this.loading.user = false;
    });
  }

}
