import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {RsvpComponent} from '../../pages/user/runs/rsvp-dialog/rsvp.component';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

export class BaseComponent {

  constructor(
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {

  }

  public displayDialog(Component, data, callback, errorcallback) {
    const dialogRef = this.dialog.open(Component, {
      data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (callback) {
          callback(result);
        }
      } else {
        if (errorcallback) {
          errorcallback(result);
        }
      }
    });
    return dialogRef;
  }

  protected openNotification(item, data?, isComponent?) {
    const config: any = {
      duration: 1500,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data
    };
    if (isComponent) {
      this.snackBar.openFromComponent(item, config);
    } else {
      this.snackBar.open(item, 'Error', config);
    }
  }

  public resetForm(form) {
    Object.keys(form.controls).forEach(key =>{
      form.controls[key].setErrors(null)
    });
  }

}
