import { Injectable } from '@angular/core';
import ApiService from '../../../../core/services/api.service';
import {shareReplay} from "rxjs/operators";

@Injectable()
export class RunLibraryService {

  constructor(private apiService: ApiService) {}

  public getCountries() {
    return this.apiService.post('util/get/all/countries', {
      data: {},
      pageSize: 1000,
      sort:[{field:"name", desc: false}],
    });
  }

  public getStates() {
    return this.apiService.post('util/get/all/us/states', {
      data: {},
      pageSize: 1000,
      sort:[{field:"name", desc: false}],
    });
  }

  public getRunLibraries(startCountry, startState, page?, pageSize?, sort?, minDistance?, maxDistance?) {
    if (minDistance) {
      minDistance = minDistance;
    }
    if (maxDistance) {
      maxDistance = maxDistance;
    }
    return this.apiService.post('curation/run/search/runLibrary', {
      data: {
        maxDistance: maxDistance || null,
        minDistance: minDistance || null,
        country: startCountry !== 'ALL' ? startCountry : null,
        state: startState || null,
        pointDirection: "start"
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sort || [{field:"name", desc: false}],
    });
  }

  public getPublicMultiRuns(startCountry, startState,
                       lat, lng, page?, pageSize?, sort?) {
    return this.apiService.post('curation/run/search/runLibrary', {
      data: {
        lat: lat + '',
        lon: lng + '',
        pointDirection: 'start'
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sort || [{field:"name", desc: false}],
    });
  }

  public getUserPreferences() {
    return this.apiService.post('user/preferences/get', {
      data: {},
    }).pipe(shareReplay(1));
  }


  public addNewRun() {

  }


}
