import { Component, OnInit} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WhatsNewService } from './whats-new.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {

  public loading = false;
  public news = [];
  public totalCount = 0;

  public currentPage = 1;

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private activatedRoute: ActivatedRoute,
                     private faqService: WhatsNewService,
                     private authService: AuthService) {}

  ngOnInit(): void {
    this.getPublicNews();
  }

  private getPublicNews() {
    this.loading = true;
    this.faqService.getWhatsNew(this.currentPage).subscribe((t) => {
      this.loading = false;
      this.news = this.news.concat(t.result.data || []);
      this.currentPage++;
      this.totalCount = t.result.totalCount || 0;
    }, () => {
      this.loading = false;
    });
  }

  public scrollAction($event) {
    let pos = ($event.target.scrollTop || $event.target.scrollTop) + $event.target.offsetHeight;
    let max = $event.target.scrollHeight;
    if (pos == max)   {
      this.checkLoadingNeed();
    }
  }

  private checkLoadingNeed() {
    if (this.news.length < this.totalCount) {
      this.getPublicNews();
    }
  }

}
