import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kmDistance'
})
export class KmDistancePipe implements PipeTransform {

  transform(value: number, format = 'km'): string {
    if (format === 'km') {
      return `${Number(value).toFixed(2)} km`;
    } else {
      return `${(Number(value) * 0.62137).toFixed(2)} mi`;
    }
  }

}
