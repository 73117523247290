import { Component, Inject, OnInit } from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-status-snack',
  templateUrl: './status-snack.component.html',
  styleUrls: ['./status-snack.component.scss']
})
export class StatusSnackComponent implements OnInit {

  public constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
  }

}
