import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-page-title',
  templateUrl: './main-page-title.component.html',
  styleUrls: ['./main-page-title.component.scss']
})
export class MainPageTitleComponent implements OnInit {

  @Input()
  public title: string = '';

  public constructor() {}

  ngOnInit(): void {
  }

}
