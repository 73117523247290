import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RunsService} from '../runs.service';
import RunModel from '../../../../models/run.model';
import { runTableColumns } from 'src/app/shared/common-structures/run-table.list';
import {TableTypeEnum} from '../../../../enums/table-type.enum';
import {BaseComponent} from '../../../../shared/classes/base.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {RsvpComponent} from '../rsvp-dialog/rsvp.component';
import {DialogConfirmationComponent} from '../../../../shared/components/dialog-confirmation/dialog-confirmation.component';
import {DialogRunComponent} from '../dialog-run/dialog-run.component';
import {UserModel} from "../../../../models/user.model";
import {MapEnum} from "../../../../enums/map.enum";
import {CommonService} from "../../../../core/services/common.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-user-runs-coming-up',
  templateUrl: './coming-up.component.html',
  styleUrls: ['./coming-up.component.scss']
})
export class ComingUpComponent extends BaseComponent implements OnInit {

  public loading = false;
  public comingUpRuns: RunModel[] = [];
  public runTableColumns = runTableColumns;
  public preferences: any[] = [];
  public distance;
  public totalCount = 0;
  public RunTypeEnum = TableTypeEnum;
  public parameterTable = {
    currentPage: 1,
    pageSize: 10,
    sorts: [{field: 'creationDate', desc: true}]
  };

  @Input()
  public user: UserModel;

  @Input()
  public isPremium: boolean = false;

  @ViewChild('runTable')
  public table;

  constructor(private runsService: RunsService,
              public dialog: MatDialog,
              private commonService: CommonService,
              public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  public nextPageList($event) {
    this.parameterTable.currentPage = $event.pageIndex;
    this.parameterTable.pageSize = $event.pageSize;
    this.userPreferences();
    this.displayList();
  }

  public sortList($event) {
    if ($event.direction) {
      const t: any =
        {field: $event.active, desc: $event.direction === 'desc'};
      this.parameterTable.sorts = [t];
      const columninfo = this.runTableColumns.find(t => t.field === $event.active);
      if (columninfo && columninfo.extraSort) {
        columninfo.extraSort.forEach(t => {
          t.desc = $event.direction === 'desc';
          this.parameterTable.sorts.push(t);
        });
      }
    } else {
      this.parameterTable.sorts = [{field: 'creationDate', desc: true}];
    }
    this.displayList();
  }

  ngOnInit(): void {
    this.displayList();
    this.userPreferences();
  }

  private userPreferences() {
    this.runsService.getUserPreferences().subscribe((preferences) => {
      if (preferences) {
          this.preferences = preferences.result.data;
          const distance = this.preferences.find(t => {
            return t.keyword === MapEnum.DISTANCE_FORMAT;
          });
          if (distance) {
            this.distance = this.commonService.distanceMapper(distance.value);
          }
      }
    })
  }

  public displayList() {
    this.loading = true;
    this.runsService.getComingupRuns(this.parameterTable.currentPage,
      this.parameterTable.sorts, this.parameterTable.pageSize).subscribe(t => {
      if (t || t.result || t.result.data) {
        this.comingUpRuns = t.result.data || []; // [(t.result.data || [])[0]];
        this.totalCount = t.result.totalCount;
        if (this.table) {
          this.table.refresh();
        }
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  public rsvp($event) {
    this.displayDialog(RsvpComponent, {
      item: $event,
      type: TableTypeEnum.COMING_UP,
      saveRsvp: (item, callback, errorcallback) => {
        this.saveRsvp(item, callback, errorcallback);
      },
      rsvpList: (runId, callback, errorcallback) => {
        this.rsvpList(runId, callback, errorcallback);
      }
    }, () => {}, () => {});
  }

  private saveRsvp(item, callback, errorcallback) {
    this.runsService.rsvpStatus(item.guestsCount,
      item.rsvpResponseId,
      item.runId, item.userToRunId).subscribe(t => {
        if (t && t.result && t.result.data && t.result.data[0]) {
          const itemResponse = t.result.data[0];
          const index = this.comingUpRuns.findIndex(it => +it.id === +itemResponse.runId);
          if (this.comingUpRuns[index]) {
            this.comingUpRuns[index].response = itemResponse.response;
            this.comingUpRuns[index].guestsCount = itemResponse.guestsCount;
          }
          this.comingUpRuns[index] = itemResponse;
          if (callback) {
            callback();
          }
        } else {
          if (errorcallback) {
            errorcallback();
          }
        }
    }, () => {
        if (errorcallback) {
          errorcallback();
        }
    });
  }

  private rsvpList(runId, callback, errorcallback) {
    this.runsService.rsvpList(runId).subscribe(t => {
      if (t && t.result && t.result.data) {
        if (callback) {
          callback(t.result.data);
        }
      } else {
        if (errorcallback) {
          errorcallback();
        }
      }
    }, () => {
      if (errorcallback) {
        errorcallback();
      }
    });
  }

  public editRun(element) {
    this.displayDialog(DialogRunComponent, {
      run: element,
      isPremium: this.isPremium,
      preferences: this.preferences,
      user: this.user,
      editRun: (run, callback, errorcallback) => {this.updateRunHandler(run, callback, errorcallback);},
      getPacks: (packs, callback, errorcallback) => {this.getPackHandler(packs, callback, errorcallback);},
      getPointCategories: (pointCategories, callback, errorcallback) => {this.getPointCategoryHandler(pointCategories, callback, errorcallback);}
    }, (run) => {
      this.displayList();
    }, () => {});

  }

  public deleteRun(element) {
    this.displayDialog(DialogConfirmationComponent,
      {message: 'Are you sure you want to delete run: ' + element.name + '?', type: 'Confirmation'}, (result) => {
      if (result) {
        this.loading = true;
        this.runsService.deleteRun(element.id).subscribe(t => {
          this.comingUpRuns.splice(this.comingUpRuns
            .findIndex(t1 => t1.id === element.id), 1);
          this.totalCount--;
          if (this.table) {
            this.table.refresh();
          }
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }
    }, (response) => {});
  }

  public createRun() {
    this.displayDialog(DialogRunComponent, {
      preferences: this.preferences,
      isPremium: this.isPremium,
      user: this.user,
      createRun: (run, callback, errorcallback) => {this.createRunHandler(run, callback, errorcallback);},
      getPacks: (packs, callback, errorcallback) => {this.getPackHandler(packs, callback, errorcallback);},
      getPointCategories: (pointCategories, callback, errorcallback) => {this.getPointCategoryHandler(pointCategories, callback, errorcallback);}
    }, (run) => {
      this.displayList();
    }, () => {});
  }

  private getAlphaInfo(run) {

  }

  private getPointCategoryHandler(pointCategories, callback, errorcallback) {
    this.runsService.getPointCategories()
      .subscribe(t => {
        if (callback) {
          callback(t);
        }
      }, () => {
        errorcallback([]);
      });
  }

  private getPackHandler(packs, callback, errorcallback) {
    this.runsService.getPacks()
      .subscribe(t => {
      if (callback) {
        callback(t);
      }
    }, () => {
      errorcallback([]);
    });
  }

  private createRunHandler(run, callback, errorcallback) {
    this.runsService.addRun(run).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }

    }, () => {
      errorcallback();
    });
  }

  private updateRunHandler(run, callback, errorcallback) {
    this.runsService.editRun(run).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      }
    }, () => {
      errorcallback();
    });
  }

}
