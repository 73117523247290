import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {RestoreService} from './restore.service';
import {MessageHandlerService} from '../../../core/services/message-handler.service';
import {MessageTypeEnum} from '../../../enums/message-type.enum';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {

  public restoreForm;
  public loading = false;

  public get email() {
    return this.restoreForm.controls.email;
  }

  private initForm() {
    this.restoreForm = this.builder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public constructor(private builder: UntypedFormBuilder,
                     private router: Router,
                     private restoreService: RestoreService,
                     private successMessageService: MessageHandlerService) {}

  ngOnInit(): void {
    this.initForm();
  }

  public goToSignInPage() {
    this.router.navigate(['login']);
  }

  public restore(formDirective) {
    if (!this.restoreForm.invalid) {
      this.loading = true;
      this.restoreService.restore({
        username: this.email.value
      }).subscribe(t => {
        this.loading = false;
        if (t && t.result && t.result.data &&
          t.result.data[0]) {
          this.successMessageService.openDialog({
            type: MessageTypeEnum.SUCCESS,
            message: 'Link for password reset has been sent to your email',
            explanation: ''
          });
          formDirective.resetForm();
          this.restoreForm.reset();
        }
      }, () => {
        this.loading = false;
      });
    }
  }

}
