import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageEnum } from "../../enums/storage.enum";
import { AppStorageService } from "../services/app-storage.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(private router: Router,
              private appStorageService: AppStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const guidUser = this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN);
    if (!guidUser) {
      this.appStorageService.removeItemInStorage(StorageEnum.TOKEN);
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}
