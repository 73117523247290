import { NgModule } from '@angular/core';
import {RunTableModule} from './run-table/run-table.module';
import {DialogMessageModule} from './dialog-message/dialog-message.module';
import {MapViewModule} from './map-view/map-view.module';
import {DialogConfirmationModule} from './dialog-confirmation/dialog-confirmation.module';
import {PremiumAccountModule} from './premium-account/premium-account.module';
import {ImageDisplayModule} from './image-cropper/image-display.module';
import {StatusSnackModule} from './status-snack/status-snack.module';
import {MainPageTitleModule} from './main-page-title/main-page-title.module';

@NgModule({
    declarations: [],
    imports: [
        DialogMessageModule,
        DialogConfirmationModule,
        RunTableModule,
        MapViewModule,
        PremiumAccountModule,
        ImageDisplayModule,
        StatusSnackModule,
        MainPageTitleModule
    ],
    exports: [
        DialogMessageModule,
        DialogConfirmationModule,
        RunTableModule,
        MapViewModule,
        PremiumAccountModule,
        ImageDisplayModule,
        StatusSnackModule,
        MainPageTitleModule
    ],
    providers: []
})
export class ComponentsModule { }
