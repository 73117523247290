import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TrophieItemComponent } from './trophie-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from "../../../../shared/shared.module";
import { CoreModule } from "../../../../core/core.module";

@NgModule({
  declarations: [
    TrophieItemComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    SharedModule
  ],
  providers: [

  ],
  bootstrap: [TrophieItemComponent]
})
export class TrophieItemModule { }
