import {Component, OnInit, ViewChild} from '@angular/core';
import { TableTypeEnum } from 'src/app/enums/table-type.enum';
import {AuthService} from "../../../core/services/auth.service";
import {packTableColumn} from "../../../shared/common-structures/pack-table.list";
import {BaseComponent} from "../../../shared/classes/base.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {DialogPackComponent} from "./dialog-pack/dialog-pack.component";
import {PacksService} from "./packs.service";
import {DialogConfirmationComponent} from "../../../shared/components/dialog-confirmation/dialog-confirmation.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-user-packs',
  templateUrl: './packs.component.html',
  styleUrls: ['./packs.component.scss']
})
export class PacksComponent extends BaseComponent implements OnInit {

  public TableTypeEnum = TableTypeEnum;
  public packs = [];

  public userPremInformation;
  public isPremium = false;
  public totalCount = 0;
  public currentPage = 0;
  public loading: boolean = false;
  public packColumns = packTableColumn;

  public parameterTable = {
    currentPage: 1,
    pageSize: 10,
    sorts: [{field: 'creationTime', desc: true}]
  };

  @ViewChild('runTable')
  public table;

  constructor(private authService: AuthService,
              private packService: PacksService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {
    super(dialog, snackBar);
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.displayList();
  }

  private getUserInfo() {
    this.authService.isUserPremium().subscribe(t => {
      if (t.result && t.result.data) {
        this.userPremInformation = t.result.data[0];
        const info = t.serverTime;
        this.isPremium = this.userPremInformation.paidPeriodEndDateBuffer &&
          this.userPremInformation.paidPeriodEndDateBuffer >= info;
      }
    });
  }

  public selectPage($event) {
    this.currentPage = $event;
  }

  public deletePack(element) {
    this.displayDialog(DialogConfirmationComponent,
      {message: 'Are you sure you want to delete pack: ' +
          element.name + '?', type: 'Confirmation'}, (result) => {
        if (result) {
          this.loading = true;
          this.packService.deletePack(element.id).subscribe(t => {
            this.loading = false;
            this.displayList();
          }, () => {
            this.loading = false;
          });
        }
      }, (response) => {});
  }

  public nextPageList($event) {
    this.parameterTable.currentPage = $event.pageIndex;
    this.parameterTable.pageSize = $event.pageSize;
    this.displayList();
  }

  public sortList($event) {
    if ($event.direction) {
      const t: any =
        {field: $event.active, desc: $event.direction === 'desc'};
      this.parameterTable.sorts = [t];
      const columninfo = this.packColumns.find(t => t.field === $event.active);
      if (columninfo && columninfo.extraSort) {
        columninfo.extraSort.forEach(t => {
          t.desc = $event.direction === 'desc';
          this.parameterTable.sorts.push(t);
        });
      }
    } else {
      this.parameterTable.sorts = [{field: 'creationTime', desc: true}];
    }
    this.displayList();
  }

  public createPack() {
    this.displayDialog(DialogPackComponent, {
      user: this.userPremInformation,
      createPack: (pack, callback, errorcallback) =>
        {this.createPackHandler(pack, callback, errorcallback);},
      savePackImage: (pack, callback, errorcallback) =>
      {this.packImageHandler(pack, callback, errorcallback);},
      inviteUsers: (pack, callback, errorcallback) =>
      {this.packInvitationUsersHandler(pack, callback, errorcallback);},
    }, (run) => {
      this.displayList();
    }, () => {});
  }

  private displayList() {
    this.loading = true;
    this.packService.getPacks(this.parameterTable.currentPage,
      this.parameterTable.pageSize,this.parameterTable.sorts).subscribe(response => {
      if (response.result && response.result.data) {
        this.packs = response.result.data || [];
        this.isMeOwner();
      }
      this.totalCount = response.result.totalCount;
      if (this.table) {
        this.table.refresh();
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  private isMeOwner() {
    this.packs.forEach(t => {
      if (t.alpha && this.userPremInformation && t.alpha.id === this.userPremInformation.id) {
        t.isMyPack = true;
      }
    })
  }

  private packRemoveFromHandler(pack, callback, errorcallback) {
    this.packService.removeMembersFromPack(pack.packId, pack.ids)
      .subscribe(t => {
        if (t.result && t.result.data) {
          if (callback) {
            callback(t.result.data[0]);
          }
        } else {
          errorcallback();
        }
      }, () => {
        errorcallback();
      });
  }

  private packImageHandler(pack, callback, errorcallback) {
    this.packService.savePackImage(pack.file, pack.packId).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }
    }, () => {
      errorcallback();
    });
  }

  private createPackHandler(pack, callback, errorcallback) {
    this.packService.savePack(pack).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }
    }, () => {
      errorcallback();
    });
  }

  private editPackHandler(pack, callback, errorcallback) {
    this.packService.editPack(pack).subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }
    }, () => {
      errorcallback();
    });
  }

  private packInvitationUsersHandler(pack, callback, errorcallback) {
    this.packService.inviteUsers(pack.packId, pack.emails, pack.uniqueIds)
      .subscribe(t => {
      if (t.result && t.result.data) {
        if (callback) {
          callback(t.result.data[0]);
        }
      } else {
        errorcallback();
      }
    }, () => {
      errorcallback();
    });
  }

  public viewPack(item, isView) {
    const isEditor = item.alpha && item.alpha.id === this.userPremInformation.id;
    this.displayDialog(DialogPackComponent, {
      pack: item,
      isView,
      user: this.userPremInformation,
      isEditor,
      editPack: (pack, callback, errorcallback) =>
      {this.editPackHandler(pack, callback, errorcallback);},
      viewPack: (pack, callback, errorcallback) =>
      { return this.viewPackHandler(pack, callback, errorcallback);},
      savePackImage: (pack, callback, errorcallback) =>
      {this.packImageHandler(pack, callback, errorcallback);},
      inviteUsers: (pack, callback, errorcallback) =>
      {this.packInvitationUsersHandler(pack, callback, errorcallback);},
      removeFromPack: (pack, callback, errorcallback) =>
      {this.packRemoveFromHandler(pack, callback, errorcallback);},
    }, (run) => {
      if (run && !run.isView) {
        this.displayList();
      }
    }, () => {});
  }

  public viewPackHandler(pack, callback, errorcallback) {
    return this.packService.getPack(pack);
  }

}
