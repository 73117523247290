import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ComingUpComponent } from './coming-up.component';
import { SharedModule } from '../../../../shared/shared.module';
import {CoreModule} from "../../../../core/core.module";

@NgModule({
  declarations: [
    ComingUpComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        CoreModule
    ],
  providers: [],
  exports: [
    ComingUpComponent
  ],
  bootstrap: [ComingUpComponent]
})
export class ComingUpModule { }
