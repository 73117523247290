import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../core/services/auth.service";

@Component({
  selector: 'app-user-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.scss']
})
export class RunsComponent implements OnInit {

  public userPremInformation;
  public isPremium = false;

  constructor(private authService: AuthService) {
  }

  public currentPage = 0;

  ngOnInit(): void {
    this.authService.isUserPremium().subscribe(t => {
      if (t.result && t.result.data) {
        this.userPremInformation = t.result.data[0];
        const info = t.serverTime;
        this.isPremium = this.userPremInformation.paidPeriodEndDateBuffer &&
          this.userPremInformation.paidPeriodEndDateBuffer >= info;
      }
    });
  }

  public selectPage($event) {
    this.currentPage = $event;
  }

}
