import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import ApiService from '../../../../core/services/api.service';
import {shareReplay} from "rxjs/operators";

@Injectable()
export class LocationRunsService {

  private pref$: Observable<any>;
  private preferences: any[];

  constructor(private apiService: ApiService) {}

  public getPointCategories() {
    return this.apiService.get('run/point/categories');
  }

  public getRsvp(id) {
    return this.apiService.post('run/search/run/users', {
      data: {
        runId: id
      }
    });
  }

  public getLogbookRuns(page?, sorts?, pageSize?) {
    return this.apiService.post('run/search/own', {
      data: {
        hideDeniedPublicRuns: true,
        participated: true,
        runStatuses: [5]
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sorts || [{field: 'creationDate', desc: true}]
    });
  }

  public rsvpList(runId) {
    return this.apiService.post('run/search/run/users', {
      data: {
        runId
      },
    });
  }

  public rsvp(guestsCount, rsvpResponseId, runId) {
    return this.apiService.post('run/public/rsvp', {
      data: {
        guestsCount,
        rsvpResponseId,
        runId
      },
    });
  }

  public deleteRun(runId) {
    return this.apiService.post('run/remove', {
      data: {
        id: runId
      },
    });
  }

  public addRun(run) {
    return this.apiService.post('run/add', {
      data: run,
    });
  }

  public getPublicMultiRuns(lat, lng, page?, pageSize?, sort?) {
    return this.apiService.post('run/get/public', {
      data: {
        radius: 1,
        startPointLatitude: lat,
        startPointLongitude: lng,
        startCountry: null,
        startState: null
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sort || [{field:"name", desc: false}],
    });
  }

  public sendRsvpForPublicRun(rsvpResponseId, runId) {
    return this.apiService.post('run/public/rsvp', {
      data: {
        rsvpResponseId,
        runId
      }
    });
  }

  public getLibraryMultiRuns(lat, lng, page?, pageSize?, sort?) {
    return this.apiService.post('curation/run/search/runLibrary', {
      data: {
        lat: lat + '',
        lon: lng + '',
        pointDirection: 'start'
      },
      page: page || 1,
      pageSize: pageSize || 10,
      sort: sort || [{field:"name", desc: false}],
    });
  }

  public getPacks() {
    return this.apiService.post('pack/user/get', {
      data: {},
    });
  }

  public getUserPreferences() {
    this.pref$ = this.apiService.post('user/preferences/get', {
      data: {},
      }).pipe(shareReplay(1));
    return this.pref$;
  }

  public getJoinedPublicRuns() {
    return this.apiService.post('run/search/own', {
      data: {
        hideDeniedPublicRuns: true,
        participated: null,
        runStatuses: [1, 4],
        isPublic: 1
      },
      page: 1,
      pageSize: 1000,
      sort: [{field: 'name', desc: true}]
    });
  }

}
