import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {NavigationEnd, Router, Scroll} from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import {AppService} from './core/services/app.service';
import {StorageEnum} from './enums/storage.enum';
import {AppStorageService} from './core/services/app-storage.service';
import {environment} from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import {TranslationService} from './core/services/translation.service';

const URL_WITHOUT_NAV_BAR = [
  'login',
  'verify',
  'restore',
  'reset',
  'payment-result',
  'user/public-faq',
  'traveller_invitation',
  'whats-new'
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isExpanded;
  public isDisplayedNavBar = false;
  public mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  @ViewChild('snav')
  public snav;
  public currentUrl = '';

  constructor(private authService: AuthService,
              private router: Router,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private appService: AppService,
              private appStorageService: AppStorageService,
              @Inject(DOCUMENT) private document: Document,
              private translateService: TranslationService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  public getUserName() {
    return this.authService.getStaticInfo();
  }

  public getUser() {
    return this.authService.getUser();
  }

  public ngOnInit(): void {
    this.routeControl();
    this.initPaymentInfo();
    this.initUserInfo();
  }

  private routeControl() {
    this.router.events.subscribe((val) => {
      if ((val instanceof NavigationEnd) || (val instanceof Scroll)) {
        const url = val instanceof NavigationEnd ? val.url : val.routerEvent.url;
        this.isDisplayedNavBar = !URL_WITHOUT_NAV_BAR.some(y => {
          return url.indexOf(y) === 1;
        });
      }
    });
  }

  public logout() {
    return this.authService.logout(() => {
      this.router.navigate(['login']);
    });
  }

  public navigateToExternal(event) {
    event.preventDefault();
    window.open(environment.faqPath, '_blank');
  }

  public navigateTo(url) {
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  private initPaymentInfo() {
    this.appService.getPaymentTypes().subscribe(t => {});
  }

  private initUserInfo() {
    if (this.appStorageService.getItemFromLocalStorage(StorageEnum.TOKEN)) {
      this.authService.isUserPremium().subscribe(t => {});
    }
  }

  setLang(lang: string) {
    this.translateService.use(lang);
  }

}
