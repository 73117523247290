<div>
  <div>
    <div class="header {{data.type}}"></div>
    <div class="message">
      {{data.message || data.explanation}}
    </div>
  </div>
</div>
<div class="btn-submit-container">
  <button mat-button class="btn-submit {{data.type}}" mat-dialog-close="true">Ok</button>
</div>

