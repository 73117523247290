export const MODE_LIST = [
  {
    id: 'BICYCLING',
    name: 'Bicycling'
  },
  {
    id: 'DRIVING',
    name: 'Driving'
  },
  {
    id: 'WALKING',
    name: 'Walking'
  }
];

export const MODE = {
  BICYCLING: 'Bicycling',
  DRIVING: 'Driving',
  WALKING: 'Walking'
}
