import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FaqComponent } from './faq.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FaqService } from './faq.service';
import { SharedModule } from '../../../shared/shared.module';
import {CoreModule} from "../../../core/core.module";

@NgModule({
  declarations: [
    FaqComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        CoreModule
    ],
  providers: [
    FaqService
  ],
  bootstrap: [FaqComponent]
})
export class FaqModule { }
