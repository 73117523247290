import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';
import {shareReplay} from "rxjs/operators";

@Injectable()
export class StatsService {

  constructor(private apiService: ApiService) {}

  public getStats() {
    return this.apiService.post('stats/user/combined', {
      data: {}
    });
  }

  public getUserPreferences() {
    return this.apiService.post('user/preferences/get', {
      data: {},
    }).pipe(shareReplay(1));
  }

  public getRuns(userId) {
    return this.apiService.post('run/endpoints/search', {
      data: {
        userId: userId
      }
    });
  }

  public getHistogramRuns(userId, year?) {
    return this.apiService.post('stats/user/runs', {
      data: {
        userId: userId,
        year,
        fullList: !year
      }
    });
  }

}
