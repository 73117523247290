import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MultiplePublicRunsComponent } from './multiple-public-runs.component';
import { SharedModule } from '../../../../shared/shared.module';
import { PublicRunsService } from './../public-runs.service';
import {FormsModule} from "@angular/forms";
import {CoreModule} from "../../../../core/core.module";

@NgModule({
  declarations: [
    MultiplePublicRunsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    CoreModule,
  ],
  providers: [
    PublicRunsService
  ],
  bootstrap: [MultiplePublicRunsComponent]
})
export class MultiplePublicRunsModule { }
