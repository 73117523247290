import { Injectable } from '@angular/core';
import ApiService from '../../../core/services/api.service';

@Injectable()
export class FaqService {

  constructor(private apiService: ApiService) {}

  public getFaq() {
    return this.apiService.post('faq/category/get/full', {});
  }

  public getPublicFaq() {
    return this.apiService.post('faq/category/get/public', {});
  }

}
