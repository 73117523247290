<mat-progress-bar *ngIf="isInProgress()" mode="query"></mat-progress-bar>
<app-main-page-title [title]="'Settings'"></app-main-page-title>
<mat-card>
  <div class="profile-container-full">
    <div>
      <div class="tab-emulation-ripple-container"
           matRipple [matRippleCentered]="true" (click)="updateTab(0); currentPage = 0"
           [ngClass]="{'selected-option': currentPage === 0, 'selected': currentPage === 0}">
        Profile
      </div>
      <div class="tab-emulation-ripple-container"
           matRipple [matRippleCentered]="true" (click)="updateTab(1); currentPage = 1"
           [ngClass]="{'selected-option': currentPage === 1, 'selected': currentPage === 1}">
        Settings
      </div>
    </div>
    <mat-tab-group [(selectedIndex)]="currentPage" class="tab-custom">
      <mat-tab class="tab-item" [ngClass]="{'selected-option': currentPage === 0}">
        <div class="profile-container">
          <div class="common-account-info">
            <form [formGroup]="profileForm"
                  class="profile-input-item profile-form-container">
              <div class="form-item-grp">
                <div class="profile-header">
                  General
                </div>
                <mat-form-field class="point-item">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName"
                         [regexReplace]="'name'" titleCase>
                </mat-form-field>
                <mat-form-field class="point-item">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName"
                  [regexReplace]="'name'">
                </mat-form-field>
                <mat-form-field class="point-item">
                  <mat-label>NickName</mat-label>
                  <input matInput [regexReplace]="'emoji'" formControlName="nickname">
                </mat-form-field>
                <mat-form-field >
                  <mat-label>Gender</mat-label>
                  <mat-select formControlName="gender">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let gender of genderList"
                                [value]="gender.value">
                      {{gender.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="point-item">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email"
                         [regexReplace]="'email'">
                </mat-form-field>
                <div class="phone-code-container">
                  <span class="phone-plus-sign">+</span>
                  <mat-form-field class="point-item phone-code">
                    <mat-label>Code</mat-label>
                    <input matInput
                           [regexReplace]="'number'"
                           formControlName="phoneCountryCode"
                    >
                  </mat-form-field>
                  <mat-form-field class="point-item">
                    <mat-label>Phone</mat-label>
                    <input matInput
                           [regexReplace]="'number'"
                           formControlName="phone">
                  </mat-form-field>
                </div>
                <mat-form-field class="point-item">
                  <mat-label>Charity</mat-label>
                  <mat-select formControlName="charity"
                  >
                    <mat-option></mat-option>
                    <mat-option *ngFor="let charity of charities"
                                [value]="charity.id">
                      {{charity.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-slide-toggle formControlName="profilePrivate">
                  Hide my contact details from other users
                </mat-slide-toggle>
              </div>
              <div class="form-item-grp">
                <div class="account-type-container">
                  <div class="profile-header">
                    <span>Account Type: </span>
                    <ng-container *ngIf="isPremium">
                      <span class="payment-title">Premium</span>
                    </ng-container>
                    <ng-container *ngIf="!isPremium">
                      <span class="payment-title">Basic</span>
                    </ng-container>
                  </div>
                  <div class="account-type">
                    <ng-container *ngIf="isPremium">
                      <span class="next-date-payment-title">Next payment date: </span>
                      <span>{{nextPaymentDate}}</span>
                    </ng-container>
                    <ng-container *ngIf="!isPremium">
                      <button  class="upgrade-btn" mat-raised-button color="primary"
                               type="button" (click)="moveToPremium()" [id]="'ACCOUNT_UPGRADE_TO_PREMIUM' | gtmTag">
                        Upgrade to premium
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="form-item-grp">
                <div class="profile-header">
                  Change Location
                </div>
                <div>
                  <mat-form-field class="point-item">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" (selectionChange)="countryChanged()">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let country of countries"
                                  [value]="country.code">
                        {{country.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="point-item">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state"
                                [disableControl]="!!user && !!this.user.locationDetails &&
                              user.locationDetails.countryCode !== 'US'">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let state of states"
                                  [value]="state.code">
                        {{state.name}} {{state.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-item-grp">
                <div class="profile-header">
                  Emergency info
                </div>
                <mat-form-field class="point-item">
                  <mat-label>Name</mat-label>
                  <input matInput
                         [regexReplace]="'name'"
                         titleCase
                         formControlName="ecName">
                </mat-form-field>
                <mat-form-field class="point-item">
                  <mat-label>Email</mat-label>
                  <input matInput
                         [regexReplace]="'email'"
                         formControlName="ecEmail" >
                </mat-form-field>
                <div class="phone-code-container">
                  <span class="phone-plus-sign">+</span>
                  <mat-form-field class="point-item phone-code">
                    <mat-label>Code</mat-label>
                    <input matInput
                           [regexReplace]="'code'"
                           formControlName="ecPhoneCountryCode" >
                  </mat-form-field>
                  <mat-form-field class="point-item">
                    <mat-label>Phone</mat-label>
                    <input matInput
                           [regexReplace]="'number'"
                           formControlName="ecPhone" >
                  </mat-form-field>
                </div>
              </div>

              <div class="save-options-container">
                <button mat-raised-button class="red-primary"
                        (click)="updateUser()"  [disabled]="isInProgress()" >
                  <span [id]="'PROFILE_GENERAL_SAVE' | gtmTag" class="btn-action-wrapper-general">
                    <mat-icon class="btn-title-icon" [id]="'PROFILE_GENERAL_SAVE' | gtmTag">save</mat-icon>
                    <span class="btn-title-content" [id]="'PROFILE_GENERAL_SAVE' | gtmTag">Save</span>
                  </span>
                </button>
                <button mat-raised-button (click)="refreshUser()" class="refresh-btn" type="button">
                  Cancel
                </button>
              </div>

            </form>
            <div class="upload-image-item">
              <div class="image-upload-container">
                <input type="file"
                       (change)="fileChangeEvent($event)"
                       #imageSelection
                       accept="image/x-png,image/jpeg" />
                <img [src]="user?.pictureUrl"
                     onerror="this.src='assets/images/default-user.png';"
                     class="profile-img"/>
                <div class="image-upload-box" (click)="imageSelection.click()" matTooltip="Upload image">
                  <mat-icon class="upload-image" >add_photo_alternate</mat-icon>
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="changePasswordForm"
                class="profile-form-container">
            <div class="profile-header">
              Change password
            </div>
            <mat-form-field class="point-item">
              <mat-label>Password</mat-label>
              <input matInput formControlName="newPassword"  autocomplete="off" readonly
                     onfocus="this.removeAttribute('readonly');"
                     [regexReplace]="'password'"
                     type="password">
            </mat-form-field>
            <div class="error-message-handler">
              <div *ngIf="!password?.valid && password.touched && password.errors?.required"
                   class="error-message">
                Required
              </div>
              <div *ngIf="!password?.valid && password.touched && password.errors?.minlength"
                   class="error-message">
                Min length is {{password.errors.minlength.requiredLength}} symbols
              </div>
              <div *ngIf="!password?.valid && password.touched && password.errors?.mustMatch"
                   class="error-message">
                Passwords are not the same
              </div>
            </div>
            <mat-form-field class="point-item">
              <mat-label>Confirm Password</mat-label>
              <input matInput formControlName="confirmPassword"
                     autocomplete="off" readonly
                     [regexReplace]="'password'"
                     onfocus="this.removeAttribute('readonly');"
                     type="password">
            </mat-form-field>
            <div class="error-message-handler">
              <div *ngIf="!confirmPassword?.valid && confirmPassword.touched &&
              confirmPassword.errors?.required" class="error-message">
                Required
              </div>
              <div *ngIf="!confirmPassword?.valid && confirmPassword.touched &&
              confirmPassword.errors?.minlength" class="error-message">
                Min length is {{confirmPassword.errors.minlength.requiredLength}} symbols
              </div>
              <div *ngIf="!confirmPassword?.valid && confirmPassword.touched &&
              confirmPassword.errors?.mustMatch" class="error-message">
                Passwords are not the same
              </div>
            </div>
            <div class="profile-btn">
              <button mat-raised-button class="red-primary"
                      (click)="passwordUpdate()" [disabled]="isInProgress()">
<!--                <mat-icon class="btn-title-icon">save</mat-icon>-->
                <span class="btn-title-content">
                  <span class="btn-action-wrapper-general" [id]="'PROFILE_UPDATE_PASSWORD' | gtmTag">
                    Update password
                  </span>
                </span>
              </button>
              <button mat-raised-button (click)="refreshPasswordPart()" class="refresh-btn" type="button">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab class="tab-item" [ngClass]="{'selected-option': currentPage === 0}" >
        <div class="profile-container">
          <div class="profile-form-container form-item-grp">
            <div class="profile-header">Default units</div>
            <div>
              <mat-form-field>
                <mat-select [(ngModel)]="preferencesObj.distance">
                  <mat-option [value]="0"></mat-option>
                  <mat-option *ngFor="let distance of distances"
                              [value]="+distance.id">
                    {{distance.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="profile-form-container form-item-grp">
            <div class="profile-header">Route default options</div>
            <div>
              <mat-checkbox [(ngModel)]="preferencesObj.isAvoidHighways">Avoid Highways</mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]="preferencesObj.isAvoidTools">Avoid Tolls</mat-checkbox>
            </div>
            <div>
              <mat-checkbox [(ngModel)]="preferencesObj.isAvoidFerries">Avoid Ferries</mat-checkbox>
            </div>
          </div>
          <div class="profile-form-container form-item-grp">
            <div class="profile-header">On Trip communications</div>
            <div *ngFor="let message of preferencesObj.onRunMessages; let i = index">
              <mat-form-field class="point-item">
                <mat-label>Message {{i + 1}}</mat-label>
                <input matInput [(ngModel)]="message.value"
                       [regexReplace]="'emoji'"
                >
              </mat-form-field>
            </div>
          </div>

          <div class="profile-form-container form-item-grp">
            <div class="profile-header">Email Preferences</div>
            <div *ngFor="let message of preferencesObj.onEmailPreferences; let i = index">
              <span class="message-title">
                {{message?.name}}
              </span>
              <mat-slide-toggle [(ngModel)]="message.value"></mat-slide-toggle>
            </div>
          </div>

          <div class="save-options-container">
            <button mat-raised-button class="red-primary"
                    (click)="updatePreferences()" [disabled]="isInProgress()">
              <span class="btn-action-wrapper-general" [id]="'PROFILE_GENERAL_SAVE' | gtmTag">
                <mat-icon class="btn-title-icon" [id]="'PROFILE_GENERAL_SAVE' | gtmTag">save</mat-icon>
                <span class="btn-title-content" [id]="'PROFILE_GENERAL_SAVE' | gtmTag">Save</span>
              </span>
            </button>
            <button mat-raised-button (click)="refreshPreferences()" class="refresh-btn" type="button">
              Cancel
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>
