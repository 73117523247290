import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RunsComponent } from './runs.component';
import { SharedModule } from '../../../shared/shared.module';
import { ComingUpModule } from './coming-up/coming-up.module';
import { RunsService } from './runs.service';
import {LogbookModule} from './logbook/logbook.module';
// import {AgmCoreModule} from '@agm/core';
import {RsvpModule} from './rsvp-dialog/rsvp.module';
import {DialogRunModule} from './dialog-run/dialog-run.module';
import {CoreModule} from '../../../core/core.module';

@NgModule({
  declarations: [
    RunsComponent
  ],
    imports: [
        BrowserModule,
        SharedModule,
        ComingUpModule,
        LogbookModule,
        // AgmCoreModule,
        RsvpModule,
        DialogRunModule,
        CoreModule
    ],
  providers: [
    RunsService
  ],
  bootstrap: [RunsComponent]
})
export class RunsModule { }
