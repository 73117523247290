import TableColumnModel from '../../models/tables/table-column.model';

export const runLibraryTableColumns: TableColumnModel[] = [

  {
    name: 'Name',
    label: 'name',
    field: 'name',
    isSticky: true,
    initial: true
  },
  {
    name: 'Distance',
    label: 'distance',
    field: 'distance',
    pipe: 'kmDistance'
  },
  {
    name: 'Start Point Address',
    label: 'startingPointName',
    field: 'startingPointName',
  },
  {
    name: 'End Point Address',
    label: 'endingPointName',
    field: 'endingPointName',
  },
  {
    name: 'Copies #',
    label: 'copyCount',
    field: 'copyCount',
  },
  {
    name: '',
    label: 'action',
    field: '',
    isAction: true
  },
];
