import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SignInComponent } from './sign-in.component';
import { SharedModule } from "../../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { SignInService } from "./sign-in.service";
import {CoreModule} from "../../core/core.module";

@NgModule({
  declarations: [
    SignInComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        CoreModule
    ],
  providers: [
    SignInService
  ],
  bootstrap: [SignInComponent]
})
export class SignInModule { }
